@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Whisper&display=swap);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*:after,
*:before{
    box-sizing: border-box;
}
@font-face {
    font-family: 'cobuy';
    src: url(/static/media/cobuy.5dfa4ca5.eot);
    src: url(/static/media/cobuy.5dfa4ca5.eot#iefix) format('embedded-opentype'),
         url(/static/media/cobuy.cc7367e9.woff2) format('woff2'),
         url(/static/media/cobuy.8ba43cac.woff) format('woff'),
         url(/static/media/cobuy.e06011a3.ttf) format('truetype'),
         url(/static/media/cobuy.3514ad88.svg#cobuy) format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'whisper';
    src: url(/static/media/whisper-Regular.406be247.ttf);
    font-weight: normal;
    font-style: normal;
  }

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "cobuy";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-back:before { content: '\e800'; } 
.icon-check:before { content: '\e801'; } 
.icon-check-circled:before { content: '\e802'; } 
.icon-cross:before { content: '\e803'; } 
.icon-cross-circled:before { content: '\e804'; } 
.icon-email:before { content: '\e805'; } 
.icon-email-circled:before { content: '\e806'; } 
.icon-info:before { content: '\e807'; } 
.icon-phone:before { content: '\e808'; } 
.icon-phone-circled:before { content: '\e809'; } 
.icon-users:before { content: '\e80a'; } 
.icon-users-circled:before { content: '\e80b'; } 
.icon-camera:before { content: '\e80c'; } 
.icon-camera-circled:before { content: '\e80d'; } 
.icon-card:before { content: '\e80e'; } 
.icon-card-circled:before { content: '\e80f'; } 
.icon-ok-circle:before { content: '\e810'; } 
.icon-plus:before { content: '\e811'; } 
.icon-arrow-down:before { content: '\e812'; } 
.icon-arrow-right:before { content: '\e813'; } 
.icon-policies:before { content: '\e814'; } 
.icon-joint:before { content: '\e815'; } 
.icon-send:before { content: '\e816'; } 
.icon-log-out:before { content: '\e817'; } 
.icon-menu:before { content: '\e818'; } 
.icon-coowners:before { content: '\e819'; } 
.icon-delete:before { content: '\e81a'; } 
.icon-alert:before { content: '\e81b'; } 
.icon-edit:before { content: '\e81c'; } 
.icon-chat:before { content: '\e81d'; } 
.icon-exit:before { content: '\e81e'; } 
.icon-payments:before { content: '\e81f'; } 
.icon-financial:before { content: '\e820'; } 
.icon-ownership:before { content: '\e821'; } 
.icon-basics:before { content: '\e822'; } 
.icon-agreement:before { content: '\e823'; } 
.icon-check-circled-bold:before { content: '\e824'; } 
.icon-bell:before { content: '\e825'; } 
.icon-view:before { content: '\e826'; } 
.icon-alert-bold:before { content: '\e827'; } 
.icon-document:before { content: '\e828'; } 
.icon-user:before { content: '\e829'; } 
.icon-grid:before { content: '\e82a'; } 
.icon-document-bold:before { content: '\e82b'; } 
.icon-dots:before { content: '\e82c'; } 
.icon-chat-circled:before { content: '\e82d'; } 
.icon-upload-version:before { content: '\e82f'; } 
.icon-share:before { content: '\e830'; } 
.icon-history:before { content: '\e831'; } 
.icon-download:before { content: '\e832'; } 
.icon-blockchain:before { content: '\e833'; } 
.icon-cobuy:before { content: '\e834'; } 
.icon-sent:before { content: '\e835'; } 
.icon-read:before { content: '\e836'; } 
.icon-trash:before { content: '\e837'; }
.icon-payment:before { content: '\e838'; }
.icon-cross-toast:before { content: '\e839'; }
.icon-empty-icon:before { content: '\e84d'; }
.icon-signal:before {
    font-family: 'Material Icons', serif; content: '\e202'; }
/* Primary button */
.button-center button { display: block; width: 100%; height: 56px; border: none; font-family: "Inter", sans-serif; border-radius: 5px; background: #37465A; color: #FFF; font-size: 1em; cursor: pointer; transition: all .2s; margin-top: 32px; } 
    .button-center button[disabled] { background: #EEECEC; color: #7E7E7E; pointer-events: none; } 

/* Secondary button */
.button-center .secondary-button { background: #FFF; border: 1px solid #2E405C; color: #2E405C; margin-top: 13px; font-weight: 500; } 

/* Tertiary button */
.button-center .tertiary-button { background: transparent; color: #37465A; font-weight: 600; margin-top: 10px } 
    .button-center button[disabled].tertiary-button { background: #FFF!important; } 

/* Inline button */
.inline-button { pointer-events: all; padding:0; font-weight: 600; border: none; margin-top: 0; font-family: "Inter", sans-serif; font-size: 1em; cursor: pointer; transition: all .2s; background: transparent; color: #37465A; } 

/* Google button */
.login-wrapper .google-login-button { display: block; width: 100%; border: 1px solid #D6D6D6; font-family: "Inter", sans-serif; padding: 18px; border-radius: 5px; background: transparent; color: #000; font-size: 1em; cursor: pointer; font-weight: 500; } 
    .login-wrapper button.google-login-button:focus { border: 1px solid #37465A; } 
.login-wrapper .google-login-button img { position: absolute; margin-left: -35px; margin-top: -2px; } 

/* Icon-text button */
.icon-text-button { display: block; width: auto; border: none; font-family: "Inter", sans-serif; padding: 15px 0; border-radius: 5px; background: transparent; color: #37465A; font-size: 1.15em; cursor: pointer; transition: all .3s; text-align: left; font-weight: 500; margin-bottom: 20px; white-space: nowrap; } 
.icon-text-button i { color: #37465A!important; } 
    .icon-text-button i.disabled { color:#7E7E7E!important; pointer-events: none; cursor: auto; } 
.icon-text-button span.disabled { color:#7E7E7E; pointer-events: none; cursor: auto; } 

/* Icon button */
.icon-button { background: transparent; border: none; padding: 0; width: auto; transition: all .3s; cursor: pointer; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; } 
    .icon-button:disabled,
    .icon-button[disabled] { cursor: auto; } 
.icon-button i { position: relative; z-index: 2; color: #333333 } 
    .icon-button i.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 

/* Loading button */
.loading-button { position: relative; overflow: hidden; color: #37465A !important; pointer-events: none; } 
.loading-button:before { content: ""; display: block; border: 2px solid transparent; border-radius: 100%; border-top: 2px solid #FFF; width: 30px; height: 30px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; position: absolute; top: 50%; left: 50%; margin-top: -15px; margin-left: -15px; } 
.loading-button.tertiary-button:before { content: ""; display: block; border: 2px solid transparent; border-radius: 100%; border-top: 2px solid #37465A; width: 30px; height: 30px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; position: absolute; top: 50%; left: 50%; margin-top: -15px; margin-left: -15px; } 
.loading-button.tertiary-button { position: relative; overflow: hidden; color: #FFF !important; pointer-events: none; } 

.simple-button { background: none; border: none; color: #37465A; font-size: 1em; font-weight: 500; padding: 0; cursor: pointer; } 



@-webkit-keyframes spin { 
 0% { -webkit-transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); } 
 }

@keyframes spin { 
 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
 }

body { font-family: "Inter", sans-serif; font-size: 14px; color: #333; line-height: 1.2; } 
#root { font-size: 14px; scrollbar-width: thin; } 
b { font-weight: bold; } 
em { font-style: italic; } 
strong { font-weight: bold; } 

*:focus { outline: none; } 

input, 
select, 
textarea { width: 100%; font-size: 1em; display: block; border: 1px solid #7E7E7E; border-radius: 8px; padding: 0 15px; height: 50px; font-family: "Inter", sans-serif; color: #333333; } 
input:active, 
select:active, 
textarea:active,
input:focus, 
select:focus, 
textarea:focus { border: 1px solid #37465A; } 
input:read-only, 
select:read-only, 
textarea:read-only { border: 1px solid #7E7E7E; cursor: default; } 
input::-webkit-input-placeholder, select::-webkit-input-placeholder, textarea::-webkit-input-placeholder { color: #acacac; } 
input::placeholder, 
select::placeholder, 
textarea::placeholder { color: #acacac; } 
input:disabled, 
select:disabled, 
textarea:disabled { -webkit-text-fill-color: #8b8b8b; opacity: 1; }
input:disabled::-webkit-input-placeholder, select:disabled::-webkit-input-placeholder, textarea:disabled::-webkit-input-placeholder { -webkit-text-fill-color: #acacac; opacity: 1; }
input:disabled::placeholder, 
select:disabled::placeholder, 
textarea:disabled::placeholder { -webkit-text-fill-color: #acacac; opacity: 1; }

textarea { resize: none; height: 100px; padding: 15px 15px 10px } 
button { font-family: "Inter", sans-serif; } 
.disabled label { opacity: 0.6; } 

a { color: #37465A; } 
 a:hover,
 a:focus { color: #3665AB } 

h1 { font-size: 2.4em; letter-spacing: -1px; } 
h2 { font-size: 2.2em; letter-spacing: -1px; } 
h3 { font-size: 2em; letter-spacing: -1px; } 
h4 { font-size: 1.7em; letter-spacing: -1px; } 
h5 { font-size: 1.6em; letter-spacing: -1px; } 
h6 { font-size: 1.4em; letter-spacing: -1px; } 

/* Loading Spinner */
.full-page-loading { display: flex; justify-content: center; position: fixed; width: 100%; height: 100%; z-index: 99999999; background: rgba(255,255,255,0.8); top: 0; left: 0; max-height: 0; visibility: hidden; opacity: 0; transition: opacity .3s; } 
.full-page-loading.show { opacity: 1; visibility: visible; max-height: 100%; } 
.full-page-loading .full-page-loading-content { display: flex; flex-direction: column; align-items: center; justify-content: center; } 
.full-page-loading .full-page-loading-content p { font-size: 1em; color: #37465A; margin-top: 30px; font-weight: 500; } 

.spinner-container { height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; } 
.spinner { border: 5px solid transparent; border-radius: 100%; border-top: 5px solid #37465A; width: 60px; height: 60px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; } 

@-webkit-keyframes spin { 
 0% { -webkit-transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); } 
 }

@keyframes spin { 
 0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); } 
 }

/* Scrollbar */
::-webkit-scrollbar { z-index: 9999999; width: 8px; height: 8px; background: #eeeeee; } 
::-webkit-scrollbar-track { border-radius: 3px; } 
::-webkit-scrollbar-thumb { background: #c9c9c9; border-radius: 3px; -webkit-transform: all .2s; transform: all .2s; } 
::-webkit-scrollbar-thumb:hover { background: #aeaeae; } 

/* General Container */
.general-container { width: 100vw; height: 100%; background-color: #FFFFFF; display: flex; flex-direction: column; }
.top-level-fail-wrapper { height: 100%; width: 100%; display: flex; justify-content: center; } 
.general-container-bottom { width: 100%; position: relative; height: 100%; display: flex; align-items: center; flex-direction: column; box-sizing: border-box; overflow: hidden; } 

.content { height:100%; width: 100%; margin: 0 auto; position: relative; display: flex; align-items: center; flex-direction: column; overflow: scroll; overflow-x: hidden; }
 .content.header-full { padding-top: 95px; } 
 .content.desktop-no-padding { padding: 0; } 
.content-wrapper { min-height: 500px; width: 100%; max-width: 410px; display: flex; padding: 0 20px; flex-direction: column; justify-content: center; align-items: center; } 
.content form { font-size: 1em; width: 100%; } 

/* Content wrappers */
.content .nice-work-wrapper,
.content .finalize-agreement-wrapper,
.content .ownership-group-wrapper { width: 100%; display: flex; justify-content: center; } 
.content .agreement-status-wrapper { background: #F9F9F9; width: 100%; display: flex; justify-content: center; }
.content .nice-work-wrapper, .content .finalize-agreement-wrapper { height: calc(100% - 80px); } 

.bottom-fixed { position: fixed; bottom: 0; width: calc(100% - 16px); height: auto; left: 8px; padding: 20px 12px; background: #FFF; z-index: 9; display: flex; justify-content: center; flex-direction: column; align-items: center; } 
.bottom-fixed .button-center { top: auto; -webkit-transform: none; transform: none; padding: 0; width: 100%; max-width: 375px; margin: 0 auto; } 
.bottom-fixed.have-comment { text-align: center; } 
.bottom-fixed.have-comment p { font-size: .9em; margin: 20px; } 
 .bottom-fixed.have-comment p.upper-text { text-align: left; font-size: 1em; font-size: .9em; top: auto; -webkit-transform: none; transform: none; padding: 0; width: 100%; max-width: 375px; margin: 0 auto; } 

.bottom-fixed .fail-footer-message { margin-bottom: 20px; } 
.bottom-fixed .fail-footer-message p { text-align: center; margin-bottom: 5px; } 
.bottom-fixed .fail-footer-message p a { color:#37465A; text-decoration: none; font-weight: 700; } 


.vertical-centered { padding: 0 0 80px; height: calc(100vh - 66px); display: flex; justify-content: center; align-items: center; flex-direction: column; } 

.centered-box { font-size: 1em; } 
.centered-box h3 { margin-bottom: 20px; text-align: center; } 
.centered-box h3 strong { background-image: linear-gradient(180deg, rgba(236, 217, 198, 0) 64%, #FCEBCA 0); } 
.centered-box p { color: #737373; } 
.centered-box i.icon-nice-work { background-image: url(/static/media/icon-nice-work.0aaee282.svg); width: 100%; height: 100px; background-size: contain; background-position: center; display: block; background-repeat: no-repeat; margin-bottom: 20px; } 
.centered-box .text-align-center { margin-top: 20px; text-align: center; color: #333333; } 
.centered-box .text-align-left { margin-top: 20px; } 
.vertical-centered .progress-wrapper { position: relative; margin: 50px 0 -50px; display: flex; justify-content: center; align-items: center; flex-direction: column; } 
.vertical-centered .progress-wrapper p { margin-top: 20px; color: #7E7E7E; font-size: 1.2em; font-weight: 600; } 
.vertical-centered .progress-wrapper i { position: absolute; top: 0; font-size: 50px; line-height: 70px; color:#37465A; } 
.vertical-centered .progress-wrapper .spinner { width: 70px; height: 70px; } 

.text-center { text-align: center; margin-bottom: 30px; } 
.text-center h3 { font-weight: 600; margin-bottom: 10px; } 
.text-center p { color: #7E7E7E; } 

.scroller { padding: 60px 20px 20px; overflow-y: auto; max-height: 100vh; padding-bottom: 100px; } 

.link-bold a { font-weight: bold; } 

/* Tooltip */
.icon-info { color: #7E7E7E; cursor: pointer; } 
.tooltip { pointer-events: all; } 

/* Checkboxes */
.label-check { width:auto; position: relative; cursor: pointer; margin: 20px 0 25px; } 
.label-check input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 100%; height: 100%; } 
.label-check span { background: #FFF; border: 2px solid #333333af; display:inline-block; width: 18px; height: 18px; position: absolute; border-radius: 5px; transition: all .3s; } 
.label-check input[type="checkbox"]:checked + span:after { content:''; display:block; position:absolute; left: -2px; top: -1px; right:0; bottom:0; margin:auto; width: 18px; height: 18px; background: #37465A; border-radius: 5px; transition: all .3s; } 
.label-check input[type="checkbox"]:checked + span:before { content: "\e801"; font-family: "cobuy"; font-size: 11px!important; display: block; color: #FFF; line-height: 1; position: absolute; top: 1px; z-index: 100; left: 2px; } 
.label-check label { padding-left: 35px; color: #717171; font-size: 1em; line-height: 16px; position: relative; top: 1px; } 
.label-check.disabled input[type="checkbox"]:checked + span:after { background: #7E7E7E; } 
 .label-check.disabled label { color: #7E7E7E; opacity: 1 } 

/* Radio */
.label-radio { position: relative; cursor: pointer; height: 100%; width: 100%; display: flex; align-items: center; } 
.label-radio input[type="radio"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1; cursor: pointer; width: 100%; height: 100%; } 
.label-radio span { background: #FFF; border: 2px solid #717171; display:inline-block; width: 20px; height:20px; position: absolute; top: 0; border-radius: 100%; transition: all .3s; } 
.label-radio input[type="radio"]:checked + span { border-color: #37465A; } 
.label-radio input[type="radio"]:checked + span:after { content: ""; display: block; color: #FFF; position:absolute; left: 0; top: 0; right:0; bottom:0; margin:auto; width: 12px; height: 12px; background: #37465A; border-radius: 100%; font-size: 1em; transition: all .3s; } 
 .disabled .label-radio input[type="radio"]:checked + span { border-color: #7E7E7E; } 
 .disabled .label-radio input[type="radio"]:checked + span:after { background: #7E7E7E; } 
.label-radio label { padding-left: 35px; color: #717171; } 

/* Progress Bar */
.progress-bar-container { width: 100%; height: 5px; background: #D6D6D6; z-index: 2; position: absolute; margin-top: -2px; } 
.progress-bar-container .progress-bar { height: 5px; background: #69884C; border-radius: 0 5px 5px 0; transition: all .3s; } 

/* Checkbox / Radio */
.disabled {
 /*pointer-events: none!important; */
}
.radio-box,
.checkbox-button { border: 1px solid #7E7E7E; border-radius: 10px; margin-bottom: 15px; transition: all .3s; font-size: 0.85em; height: 43px; display: flex; align-items: center; } 
 .radio-box.checked,
 .checkbox-button.checked { border-color: #37465A; background: #EBECEF; } 
 .radio-box.checked.disabled label,
 .checkbox-button.checked.disabled label { color: #333333; font-weight: 600; } 
 .radio-box .label-check.disabled label,
 .checkbox-button .label-check.disabled label { color: #333333; opacity: .6; } 
 .radio-box.checked label,
 .checkbox-button.checked label { color: #37465A; } 
 .radio-box.checked .label-radio span,
 .checkbox-button.checked span { border-color: #37465A; } 
 .radio-box.disabled,
 .checkbox-button.disabled { background: #F9F9F9; border: 1px solid #E8E8E8; } 
 .radio-box.checked.disabled,
 .checkbox-button.checked.disabled { opacity: 1; background: #F5F5F5; border: 1px solid #7E7E7E; } 

 .label-check input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 100%; height: 100%; } 
 .label-check input[type="checkbox"]:checked + span:after { content:''; display:block; position:absolute; left: -2px; top: -1px; right:0; bottom:0; margin:auto; width: 18px; height: 18px; background: #37465A; border-radius: 5px; transition: all .3s; } 
 .label-check input[type="checkbox"]:checked + span:before { content: "\e801"; font-family: "cobuy"; font-size: 11px!important; display: block; color: #FFF; line-height: 1; position: absolute; top: 2px; z-index: 100; left: 2px; } 
 
.radio-box label { display: block; padding: 6px 15px 6px 50px; z-index: 10; position: relative; pointer-events: none; } 
.radio-box .label-radio span,
.checkbox-button .label-check span { top: 50%; left: 15px; -webkit-transform: translateY(-50%); transform: translateY(-50%); } 
.radio-box.disabled:not(.checked) .label-radio span,
.checkbox-button.disabled:not(.checked) .label-check span { opacity: .6 } 

/* Radio Button Group */
.radio-button-group { margin-bottom: 25px } 

/* Checkbox Group Container */
.checkbox-button-group .checkbox-button .label-check { width: 100%; height: 100%; position: relative; cursor: pointer; margin: 0; display: flex; align-items: center; } 
.checkbox-button-group .checkbox-button .label-check label { display: block; padding: 6px 15px 6px 50px; z-index: 10; position: relative; top: 1px; } 

/* Bullet List */
.bullet-list { font-weight: bold; list-style-type: disc; margin-left: 20px; } 
.bullet-list li { margin-bottom: 5px; } 

/* Disclaimer Text */
.disclaimer-text { padding: 20px 0; font-size: .85em; line-height: 1.4; } 
.disclaimer-text strong { font-weight: bold; } 

/* Checkbox Box */
.agreement-checkbox-box { border: 1px solid #C1C1C1; padding: 15px; border-radius: 4px; background-color: #F9F9F9; box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08); } 
.agreement-checkbox-box .label-check { display: flex; align-items: center; margin: 0; } 
.agreement-checkbox-box .label-check span { width: 24px; height: 24px; border: 2px solid #C1C1C1; } 
.agreement-checkbox-box .label-check input[type="checkbox"]:checked + span:after { width: 24px; height: 24px; } 
.agreement-checkbox-box .label-check input[type="checkbox"]:checked + span:before { top: 3px; left: 3px; font-size: 14px!important; } 
.agreement-checkbox-box label { line-height: 1.5; padding-left: 45px; } 

/* Select Input */
.select-input { position: relative; z-index: 20; margin-bottom: 25px; } 
.select-input label { position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.select-input .select-label { cursor: pointer; width: 100%; border: 1px solid #7E7E7E; border-radius: 8px; padding: 13px 15px; position: relative; padding-right: 40px; height: 50px; display: flex; align-items: center; color: #333333; background: #FFF; } 
.select-input span:focus { border: 1px solid #37465A; } 
.select-input span:active { border: 1px solid #37465A; } 
 .select-input.disabled .select-label { border: 1px solid #e8e8e8; color: #8b8b8b; background-color: #F9F9F9; } 
.select-input .select-label:before { content: '\e812'; font-family: "cobuy"; display: block; position: absolute; right: 15px; top: 17px; font-size: 12px; transition: all .3s; width: 12px; height: 12px; } 
.select-input ul.options-list { opacity: 0; max-height: 0; visibility: hidden; width: 100%; padding: 0; border-radius: 8px; transition: all .3s; box-shadow: 0 4px 10px rgb(0 0 0 / 15%); position: relative; background: #FFF; overflow: hidden; margin-top: 0; } 
.select-input ul.options-list li { width: 100%; height: 56px; position: relative; padding: 0 20px; cursor: pointer; transition: all .2s; border-radius: 4px; display: flex; align-items: center; justify-content: space-between; } 
.select-input ul.options-list li:focus,
.select-input ul.options-list li:hover { background: #EBECEF; } 
.select-input ul.options-list li .label-radio { width: 20px; height: 20px; } 
.select-input ul.options-list li p { margin: 0; color: #000; font-size: 1em; font-weight: normal; } 
.modal-overlay .modal .select-input span { text-align:left; } 

.select-input.open ul { max-height: 180px; visibility: visible; opacity: 1; overflow-y: auto; padding: 10px; margin-top: 10px; } 
.select-input.open .select-label:before { -webkit-transform: rotate(180deg); transform: rotate(180deg); } 

/* Input file uploader */
.modal-overlay .modal .file-uploader, 
.file-uploader { margin-top: 32px } 
.modal-overlay .modal .file-uploader button, 
.file-uploader button { width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; min-height: 50px; background: #FFFFFF; border: 1px solid #D6D6D6; border-radius: 8px; padding: 10px 16px; cursor: pointer; } 
.modal-overlay .modal .file-uploader button.error, 
.file-uploader button.error { border-color: #D84242 !important; } 
.modal-overlay .modal .file-uploader button.with-file, 
.file-uploader button.with-file { align-items: flex-start; background: rgba(105, 136, 76, 0.1); border: 1px solid #688949; cursor: auto; pointer-events: none; } 
.modal-overlay .modal .file-uploader p, 
.file-uploader p { max-width: calc(100% - 30px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0px; font-weight: 400; font-size: 1em; line-height: 17px; color: #7E7E7E } 
.modal-overlay .modal .file-uploader i, 
.file-uploader i { margin-right: 5px; } 
.modal-overlay .modal .file-uploader button.with-file i, 
.file-uploader button.with-file i { margin:0; position: absolute; right: 35px; color: #333333; pointer-events: all; cursor: pointer; } 
.modal-overlay .modal .file-uploader button.with-file p, 
.file-uploader button.with-file p { color: #333333; padding-bottom: 4px; text-align: left; } 
.modal-overlay .modal .file-uploader button.with-file p.file-size, 
.file-uploader button.with-file p.file-size { font-size: 0.8em; line-height: 15px; color: #7E7E7E; } 
.modal-overlay .modal .file-uploader input , 
.file-uploader input { display: none } 
.modal-overlay .modal .file-uploader button.disabled, 
.file-uploader button.disabled { border: 1px solid #e8e8e8; background-color: #F9F9F9; } 
.modal-overlay .modal .file-uploader button.disabled .icon-cross, 
.file-uploader button.disabled .icon-cross { opacity: .5 } 
.modal-overlay .modal .file-uploader button.disabled p, 
.file-uploader button.disabled p { color: #8b8b8b; } 

/* Modal Toast */
.modal-toast-overlay { position: fixed; display: flex; justify-content: center; width: 100%; height: 100%; z-index: 99999999; background: rgba(255,255,255,0.8); top: 0; left: 0; } 
.modal-toast-overlay .modal-toast { color: #333; max-width: 500px; padding: 15px 10px; border-radius: 10px; display: flex; width: 90%; position: absolute; top: 100px; align-items: center; } 
.modal-toast-overlay .success { background: #EAF8EA; border: 1px solid #4E8B43; } 
.modal-toast-overlay .error { background: #FEDEDF; border: 1px solid #D84242; } 
.modal-toast-overlay .info { background: #def2fe; border: 1px solid #2879af; } 
.modal-toast-overlay .modal-toast i { font-size: 1.8em; margin-right: 10px; } 
.modal-toast-overlay .modal-toast.success i { color:#4E8B43 } 
.modal-toast-overlay .modal-toast.error i { color: #D84242;} 
.modal-toast-overlay .modal-toast.info i { color: #2879af;} 

/* Modals */
.modal-overlay { position: fixed; display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; z-index: 9999999; background: rgba(0,0,0,0.5); top: 0; left: 0; } 
.modal-overlay .modal { background: #FFF; border-radius: 10px; width: 90%; max-width: 370px; max-height: 90%; overflow-y: auto; padding: 20px; text-align: center; position: relative; } 
.modal-overlay .modal .step-modal { display: flex; flex-direction: column; }
/*.modal-overlay .modal .modal-content { padding: 0 15px 30px 0; max-height: 330px; margin-top: 30px; position: relative; }*/
.modal-overlay .modal .modal-content { padding: 0 15px 30px 0; margin-top: 30px; position: relative; }
.modal-overlay .modal .modal-content::-webkit-scrollbar { width: 5px; background: rgb(241, 241, 241); }
.modal-overlay .modal .modal-content::-webkit-scrollbar-track { border-radius: 5px; }
.modal-overlay .modal .modal-content::-webkit-scrollbar-thumb { background: rgb(207, 207, 207); border-radius: 10px; }
.modal-overlay .modal .modal-content::-webkit-scrollbar-thumb:hover { background: #cacaca !important; }
 .modal-overlay .modal .modal-content.modal-step-small { height: 230px; padding-bottom: 20px; } 
 .modal-overlay .modal .modal-content.modal-step-big { height: 320px; padding-bottom: 20px; } 
.modal-overlay .modal .modal-title { padding: 0; line-height: 1.4; font-weight: 600; display: block; font-size: 1.45em; margin-bottom:30px } 
.modal-overlay .modal ul { list-style-type: disc; padding-left: 10px; } 
.modal-overlay .modal ol { list-style-type: decimal; padding-left: 20px; } 
.modal-overlay .modal p { font-size: 1em; font-weight: 400; line-height: 1.5; color: #5C5C5C; text-align: left; margin-bottom: 20px; } 
.modal-overlay .modal .margin-bottom-p { font-size: 0.85em; font-weight: 500; line-height: 1.5; color: #5C5C5C; text-align: left; margin-bottom: 20px; } 
.modal-overlay .modal .dots { display: flex; justify-content: center; padding: 10px 0 30px 0; } 
.modal-overlay .modal .dots span { height: 10px; width: 10px; border-radius: 50%; background-color: #D6D6D6; margin: 0 5px; } 
 .modal-overlay .modal .dots span.selected { background-color: #688949; } 
.modal-overlay .modal .close-modal { position: absolute; top: 20px; right: 15px; cursor: pointer; } 
.modal-overlay .modal .close-modal.disabled * { opacity: 0.5; } 
.modal-overlay .modal .button-center button + button { margin-bottom: 0; margin-top: 10px; } 

.modal-overlay .modal .add-user-modal h4 { margin-top: 20px; margin-bottom: 16px; font-weight: 600; } 
.modal-overlay .modal .add-user-modal .header p { padding: 0; font-size: 1em; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .add-user-modal form label { text-align: left; font-size: 0.9em; } 

.modal-overlay .modal .agreement-modal h4 { margin-top: 20px; margin-bottom: 16px; font-weight: 600; } 
.modal-overlay .modal .agreement-modal p { padding: 0; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .agreement-modal .loading-text { margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; text-align: center; color: #37465A; } 
.modal-overlay .modal .agreement-modal form label { text-align: left; } 

.modal-overlay .modal .information-modal p { font-size: 1em; color: #7E7E7E; padding: 0; text-align: left; } 
.modal-overlay .modal .information-modal p + p { margin-top: 0; } 
.modal-overlay .modal .information-modal { padding: 25px 0 0; font-size: 1em; color: #7E7E7E; text-align: left; } 
.modal-overlay .modal .information-modal i { color: #333333; } 
 
.modal-overlay .modal .confirmation-modal { padding: 25px 0 0; } 
.modal-overlay .modal .confirmation-modal .icon-alert { color: #F5BD4F; font-size: 30px; margin-bottom: 10px; display: block; } 
.modal-overlay .modal .confirmation-modal .modal-title { margin-bottom: 24px } 

.modal-overlay .modal .add-document-modal h4 { font-size: 1.1em; margin-bottom: 16px; font-weight: 600; text-align: left; letter-spacing: 0; } 
.modal-overlay .modal .add-document-modal .header p { padding: 0; font-size: 1em; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .add-document-modal form label { text-align: left; font-size: 0.9em; }
.modal-overlay .modal .add-document-modal .loading-text { margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; text-align: center; color: #37465A; } 

/* Address Bar */
.address-bar { position: relative; } 
.address-bar .address-options { position: absolute; z-index: 99; background: #FFF; padding: 0; box-shadow: 0 5px 10px rgba(0,0,0,0.3); border-radius: 10px; border: 1px solid #DBDBDB; width: 100%; margin-top: 5px; } 
.address-bar .address-options li { padding: 10px 15px; cursor: pointer } 
.address-bar .address-options li:focus,
.address-bar .address-options li:hover { background: #EBECEF; } 

/* Email Us Alert */
.email-us-message { text-align: center !important; padding-bottom: 15px; } 
.email-us-message a { color:#37465A; text-decoration: none; font-weight: 700; } 

/* reCAPTCHA */
.recaptcha { display: flex; margin-bottom: 0px; align-items: center; justify-content: center; }
.recaptcha iframe { margin: 10px auto 30px; display: block; max-height: 78px; } 

/* Note */
.note { color: #7E7E7E !important; margin: 10px 0px 0px 0px; text-align: left!important; } 

/* Invalid Date */
.invalid-input { color: #D84242 !important; margin: 10px 0px 0px 0px; text-align: left!important; } 
input.error { border-color: #D84242 !important; } 
.error input { border-color: #D84242 !important; } 

/* Code */
.react-code-input { display: flex !important; justify-content: center; } 
.react-code-input input { padding: 0 } 

/* Drawer */
.menu-drawer { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 9999; pointer-events: none; font-size: 12px; } 
 .menu-drawer.open { pointer-events: auto; } 
 .menu-drawer.open .menu-drawer-background { opacity: 1; visibility: visible; } 
 .menu-drawer.open .menu-drawer-content { left: 0; } 
.menu-drawer .menu-drawer-background { background: #00000047; width: 100%; height: 100%; position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; transition: all .4s; } 
.menu-drawer .menu-drawer-content { width: 215px; height: 100%; background: #fff; display: flex; flex-direction: column; padding-bottom: 10px; position: absolute; transition: all .4s; top: 0; left: -215px; } 
.menu-drawer .menu-drawer-content .menu-header { font-size: 1.2em; padding: 20px; border-bottom: 1px solid #dedede; } 
.menu-drawer .menu-drawer-content .menu-header .address { font-weight: 700; } 
.menu-drawer .menu-drawer-content .menu-header .city { font-size: 0.9em; line-height: 1.4; } 
.menu-drawer .menu-drawer-content .menu-options { height: inherit; display: flex; flex-direction: column; } 
.menu-drawer .menu-drawer-content .menu-options .menu-options-bottom { margin-top: auto; } 
.menu-drawer .menu-drawer-content .menu-options li { position: relative; display: flex; align-items: center; } 
.menu-drawer .menu-drawer-content .menu-options li button { background: transparent; border: none; width: 100%; height: 40px; display: flex; align-items: center; padding-left: 20px; text-align: left; color: #7E7E7E; font-size: 1em; transition: all .2s; cursor: pointer; } 
 .menu-drawer .menu-drawer-content .menu-options li.active button { background: #3665AB2E; color: #333333; font-weight: 700; } 
 .menu-drawer .menu-drawer-content .menu-options li.active::before { content: ""; display: block; height: 28px; width: 4px; background: #37465A; position: absolute; left: 0; border-radius: 0 4px 4px 0; } 
 .menu-drawer .menu-drawer-content .menu-options li button:focus,
 .menu-drawer .menu-drawer-content .menu-options li button:hover { background: #3665AB2E; color: #333333; font-weight: 700; } 
.menu-drawer .menu-drawer-content .menu-options li button i .notification-badge { top: 10px; left: 35px; } 
.menu-drawer .menu-drawer-content .menu-options li button i { font-size: 1.5em; margin-right: 5px; } 

/* Right Drawer */
.right-drawer { background: #fff; width: 100%; height: 100%; position: fixed; z-index: 999; top: 0; left: 100%; transition: all .4s; font-size: 1em; } 
 .right-drawer.open { left: 0; } 
.right-drawer .right-drawer-wrapper { width: 100%; margin: auto; height: 100%; position: relative; display: flex; flex-direction: column; } 
.right-drawer-wrapper .fixed-content .header { height: 50px; display: flex; position: relative; justify-content: center; align-items: center; border-bottom: 1px #DEE1E6 solid; } 
.right-drawer-wrapper .fixed-content .header .icon-cross { position: absolute; right: 20px; cursor: pointer; } 
.right-drawer-wrapper .fixed-content .header .title { font-weight: 600; width: 100%; text-align: center; } 

/* Comments Drawer */
.right-drawer-wrapper .fixed-content .comment-input { display: flex; justify-content: space-between; align-items: flex-start; padding: 25px 20px; } 
.right-drawer-wrapper .fixed-content .comment-input .input-text { width: calc(100% - 50px); } 
.right-drawer-wrapper .fixed-content .avatar { margin: 5px 0 0 0; width: 50px; } 
.right-drawer-wrapper .comments-scroll-content { height: 100%; overflow-y: auto; position: relative; padding: 0 0 30px; transition: all .2s; } 
.right-drawer-wrapper .comments-scroll-content > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -80px; } 
.right-drawer-wrapper .comments-scroll-content .comments-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert { width: 100%; text-align: center; position: relative; padding: 15px 20px; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert p { background: #fff; display: inline; padding: 0 15px; font-weight: 500; color: #818181; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert::before { content: ""; width: calc(100% - 40px); border-top: 1px solid #D6D6D6; position: absolute; left: 20px; top: 50%; z-index: -1; } 
.right-drawer-wrapper .comments-scroll-content .comment { display: flex; padding: 15px 20px; position: relative; } 
.right-drawer-wrapper .comments-scroll-content .comment .avatar { margin: 0; width: 50px; } 
.right-drawer-wrapper .comments-scroll-content .comment .message { width: calc(100% - 50px); } 
.right-drawer-wrapper .comments-scroll-content .comment .message p.name { color: #333333; font-weight: 600; line-height: 32px; width: 100%; display: block; padding-right: 70px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; } 
.right-drawer-wrapper .comments-scroll-content .comment .message p.text { color: #707070; font-size: 1em; margin-top: 5px; line-height: 1.4; word-wrap: break-word; } 
.right-drawer-wrapper .comments-scroll-content .comment .timestamp { font-size: 0.8em; color: #7E7E7E; text-align: right; white-space: nowrap; line-height: 30px; position: absolute; right: 20px; } 
.right-drawer-wrapper .comments-scroll-content .empty-state { padding-bottom: 100px; } 

/* Avatar */
.avatar { margin: 0 5px; } 
.avatar .circle { color: #fff; background: #3665AB; height: 32px; width: 32px; line-height: 2.2; border-radius: 50%; border: 1px solid #3665AB; display: flex; align-items: center; justify-content: center; font-size: 0.75em; position: relative; } 
.avatar .circle.checked { height: 24px; width: 24px; background: #C5E7CA; border-color: #2AB930; color: #000; } 
.avatar .circle.checked::after { content: '\e801'; font-family: "cobuy"; color: #fff; font-style: normal; font-weight: normal; background: #2AB930; width: 10px; height: 10px; bottom: -2px; right: -3px; display: flex; align-items: center; justify-content: center; border-radius: 50%; position: absolute; font-size: 5px; padding-top: 1px; } 
.avatar .circle.unchecked { height: 24px; width: 24px; color: #7E7E7E; background:#fff; border-color: #7E7E7E; } 

/* Banner */
.banner { display: flex; background: #E6EAF1; border-radius: 16px; font-size: 1em; padding: 16px; line-height: 1.5; align-items: flex-start; margin-bottom: 10px; margin-top:10px } 
.banner.warning { background: #FEF4E4 } 
.banner.success { background: #EAF8EA } 
.banner img { width: 35px; margin-right: 10px; } 
.banner p { width: 100%; padding-left: 35px; position: relative; margin-left: 0; word-wrap: break-word; } 
.banner img + p { padding-left: 0; width: calc(100% - 45px); } 
.banner p.icon:before { position: absolute; left: 0; font-size: 25px; color: #37465A; margin: -1px 0 0 0; } 
.banner.success p.icon:before { color: #2AB930; } 
.banner.warning p.icon:before { color: #F5BD4F; } 

/* Button Group Container */
.button-group-container { display: flex; justify-content: center; align-items: center; font-size: 12px; padding: 0 0 20px; } 
.button-group-container.disabled button { background: #7E7E7E; pointer-events: none; } 
.button-group-container.disabled button.empty-button-group { color: #7E7E7E; border: 1px #7E7E7E solid; } 
.button-group-container button { display: block; width: 100px; height: 33px; border: none; font-family: "Inter", sans-serif; border-radius: 5px; background: #37465A; color: #FFF; font-size: 1.2em; cursor: pointer; margin: 5px; transition: all .3s; } 
.button-group-container button.empty-button-group { background: transparent; color: #7E7E7E; border: 1px #7E7E7E solid; } 

/* Text Area Container */
/* Text Input Container */
.text-area-container,
.text-input-container,
.number-input-container,
.date-input-container { margin-bottom: 25px; } 
 .text-area-container.disabled textarea,
 .text-input-container.disabled input,
 .number-input-container.disabled input,
 .date-input-container.disabled input { border: 1px solid #e8e8e8; background-color: #F9F9F9; color: #8b8b8b; } 
 .text-area-container.disabled textarea::-webkit-input-placeholder, .text-input-container.disabled input::-webkit-input-placeholder, .number-input-container.disabled input::-webkit-input-placeholder, .date-input-container.disabled input::-webkit-input-placeholder { opacity: .5; } 
 .text-area-container.disabled textarea::placeholder,
 .text-input-container.disabled input::placeholder,
 .number-input-container.disabled input::placeholder,
 .date-input-container.disabled input::placeholder { opacity: .5; } 

.text-area-container label,
.text-input-container label,
.number-input-container label,
.date-input-container label { position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.error textarea { border-color: #D84242 !important; } 

/* Chat Input Container */
.chat-input-container { margin: 0; position: relative; } 
.chat-input-container .icon-send { color: #D6D6D6; font-size: 20px; display: flex; justify-content: center; align-items: center; border-left: 1px solid #D6D6D6; position: absolute; bottom: 10px; right: 0; width: 40px; height: calc(100% - 20px); padding: 0 3px 0 0; cursor: pointer; } 
.chat-input-container .icon-send:before { position: absolute; bottom: 4px; } 
.chat-input-container textarea { overflow: auto; min-height: 50px; max-height: 180px; border-color: #D6D6D6; padding: 15px 50px 15px 15px; resize: none; } 
.chat-input-container textarea::-webkit-scrollbar { width: 0; height: 0; } 
.chat-input-container textarea::-webkit-scrollbar-track { background-color: transparent; } 
.chat-input-container textarea::-webkit-scrollbar-thumb { background-color: transparent; } 
.chat-input-container textarea::-webkit-scrollbar-thumb:hover { background-color: transparent; } 
.chat-input-container textarea:focus { border-color: #000000; } 
.chat-input-container textarea:focus + .icon-send { color: #000000; border-color: #000000; } 
.chat-input-container textarea:focus + .icon-send.disabled { color: #D6D6D6; } 

/* Switch */
.switch { margin: 0 0 25px } 
 .switch.disabled .switch-options input[type="checkbox"]:checked + .switch-span .left-option,
 .switch.disabled .switch-options input[type="checkbox"] + .switch-span .right-option { background-color: #7E7E7E; color: #fff; z-index: 1; } 
 .switch.disabled .switch-options input[type="checkbox"]:checked + .switch-span .right-option,
 .switch.disabled .switch-options input[type="checkbox"] + .switch-span .left-option { background-color: #EEECEC; color: #6b6b6b; } 
 .switch.disabled .switch-label { opacity: 0.6; } 
.switch .switch-label { float: none; cursor: auto; position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.switch .switch-options { position: relative; background: #EEECEC; width: 100%; height: 35px; border-radius: 8px; } 
.switch .switch-options .switch-span .left-option,
.switch .switch-options .switch-span .right-option { pointer-events: none; position: absolute; top: 0; right: 0; height: 100%; width: 50%; transition: all .3s; border-radius: 8px; display: flex; justify-content: center; align-items: center; } 
.switch .switch-options .switch-span .left-option { left: 0; background-color: #EEECEC; color: #333333; } 
.switch .switch-options .switch-span .right-option { background-color: #37465A; color: #fff; } 
.switch .switch-options input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 50%; height: 100%; } 
.switch .switch-options input[type="checkbox"]:checked { left: 50%; } 
.switch .switch-options input[type="checkbox"]:checked + .switch-span .left-option { background-color: #37465A; color: #fff; z-index: 1; } 
.switch .switch-options input[type="checkbox"]:checked + .switch-span .right-option { background-color: #EEECEC; color: #333333; } 

/* Switch Simple */
.simple-switch { margin: 20px 0; } 
 .simple-switch.disabled { opacity: 0.7; } 
.simple-switch-options { position: relative; width: 100%; height: 35px; border-radius: 8px; } 
.simple-switch-options::before { content: "/"; position: absolute; left: calc(50% - 5px); top: 9px; } 
.simple-switch-options .simple-switch-span .left-option,
.simple-switch-options .simple-switch-span .right-option { pointer-events: none; position: absolute; top: 0; right: 0; height: 100%; width: 50%; transition: all .3s; border-radius: 8px; display: flex; justify-content: center; align-items: center; } 
.simple-switch-options .simple-switch-span .left-option { left: 0; color: #9C9C9C; } 
.simple-switch-options .simple-switch-span .right-option { color: #333333; font-weight: 600; } 
.simple-switch-options input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 50%; height: 100%; } 
.simple-switch-options input[type="checkbox"]:checked { left: 50%; } 
.simple-switch-options input[type="checkbox"]:checked + .simple-switch-span .left-option { color: #333333; z-index: 1; font-weight: 600; } 
.simple-switch-options input[type="checkbox"]:checked + .simple-switch-span .right-option { color: #9C9C9C; } 

/* Slider */
.slider { margin: 50px 0 25px 0; position: relative; } 
.slider .slider-value { font-size: 2em; width: 100%; text-align: center; font-weight: 600; padding-bottom: 20px; border: none } 
.slider.disabled .slider-value { opacity: 0.7; } 
input[type="range"] { padding: 20px 0 0 0; } 
.progress { height: 10px; width: 50px; background: #3665AB; display: block; position: absolute; bottom: 0; border-radius: 10px; pointer-events: none; padding: 0 5px; } 
.progress::after { content: ""; width: 20px; height: 20px; border-radius: 12px; background: #fff; border: #7E7E7E 1px solid; position: absolute; z-index: 99; right: -10px; top: -5px; pointer-events: none; } 
.disabled .progress { background: #D6D6D6; } 

/* Range */
input[type="range"] { -webkit-appearance: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); width: 100%; height: 10px; margin: 0; border: none; padding: 1px 2px; border-radius: 14px; background: #EEECEC; outline: none; z-index: -1; } 
input[type="range"]::-webkit-slider-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 
input[type="range"]::-moz-range-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 
input[type="range"]::-ms-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 

/* Badge */ 
.badge { position: absolute; right: 0; top: 15px; background: #F9DDA6; border-radius: 20px; padding: 4px 8px; color: #333; font-size: .9em; } 
.badge.success { background: #C3EBBC; } 
.badge.fail { background: #FFA8A8; } 
.badge.warning { background: #FADEA7; } 
.badge.error { background: #FFA8A8; } 

/* Notification badge */ 
.notification-badge { height: 8px; width: 8px; border-radius: 50%; background: #D84242; position: absolute; top: 2px; left: 18px; border: 1px solid #fff; z-index: 3; } 
.notification-badge.small-icon { left: 16px; } 

/* Empty state */ 
.empty-state { min-height: 250px; color: #7E7E7E; text-align: center; position: relative; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; background: #fff; } 
.empty-state span { font-size: 1.2em; font-weight: 600; } 
.empty-state p { font-size: 1em; margin-top: 15px; max-width: 230px; } 
.empty-state i { font-size: 48px; margin-bottom: 20px; } 

/* Desktop nav */
.desktop-nav { display: flex; justify-content: space-between; align-items: center; } 
.desktop-nav h2 { font-size: 1.2em; letter-spacing: 0px; padding: 10px 10px 10px 0; cursor: pointer; font-weight: 500; } 
.desktop-nav h2.icon-back::before { margin-right: 12px; } 
.desktop-nav .icon-text-button { padding: 10px; margin: 0 } 

/* Login */
.login-wrapper { height: 100%; padding: 20px; min-height: unset; } 
.login-wrapper .logo { text-align: center; margin: 30px auto; width: 70px; } 
.login-wrapper .text-center { margin-bottom: 50px; } 
.login-wrapper .or-separator { text-align: center; margin: 25px 0; color: #ABABAB; position: relative; width: 100%; } 
.login-wrapper .or-separator:before { content: ""; display: block; height: 1px; position: absolute; width: 100%; background: #E6E6E6; top: 9px; } 
.login-wrapper .or-separator span { display: inline-block; background: #FFF; z-index: 10; position: relative; padding: 0 8px; } 
.login-wrapper form label { display: block; margin-bottom: 10px; } 
.login-wrapper .dont-have-account { text-align: center; font-size: .9em; padding: 30px 0; } 
.login-wrapper .dont-have-account a { font-weight: bold; } 
.login-wrapper .legal-footer { font-size: .9em; color: #7E7E7E; text-align: center; padding: 10px 0 30px; line-height: 1.4; } 
.login-wrapper .legal-footer.login a { color: #7E7E7E; padding: 0px 10px; } 
.login-wrapper .legal-footer a { color: #7E7E7E } 
 .login-wrapper .legal-footer a:hover,
 .login-wrapper .legal-footer a:focus { color: #37465A } 
.login-wrapper .email-input { margin-bottom: 50px; } 
.login-wrapper .button-center button.hide { opacity: 0; visibility: hidden; } 
.login-wrapper .text-input-container { margin-bottom: 0px; } 
.login-wrapper .text-input-container label { padding-bottom: 0px; } 

/* Steps Questions */
.step-question-wrapper { display: flex; justify-content: center; width: 100%; } 
.step-question { padding: 0 20px 20px; width: 100%; max-width: 410px; } 
.step-question h4 { font-weight: 600; text-align: center; padding: 30px 0 0 0; } 
.step-question h4 i { font-size: .70em; color: #7E7E7E; margin-left: 5px; position: relative; top: -1px; } 
.step-question h4.icon-back.disabled { opacity: 0.6; } 
.step-question p { color: #7E7E7E; margin-top: 20px; text-align: left; } 
.step-question p.loading-text { color: #37465A; text-align: center; margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; } 
.step-question p.address-warning { margin: 30px 0 25px 0; color: #333333; } 
.step-question form { margin-top: 40px; } 
.step-question form p { margin-bottom: 20px; color: #333333; } 
.step-question form label { color: #333333; } 
.step-question .checkbox-button-group.last-field .checkbox-button { margin-bottom: 25px; } 
.step-question .invalid-form { color: #D84242 !important; margin: 0px 0px 15px 0px; text-align: left!important; } 
.step-question .icon-text-button { margin-top: -15px } 

/* Take ID Photo */
.id-photo .bottom-fixed button { margin-bottom: 10px; } 

/* Invite Co-Owners step */
.step-question.invite-co-owners { margin-top: 10px; } 

/* Invite Co-Owners */
.invite-co-owners .co-owner { text-align: left; margin-top: 40px; } 
.invite-co-owners .co-owner:first-child { margin-top: 0; } 
.invite-co-owners .co-owner h5 { font-size: 1em; font-weight: 600; margin-bottom: 20px; } 
.invite-co-owners .co-owner .header { display: flex; justify-content: space-between; } 
.invite-co-owners .co-owner .header .simple-button { line-height: 1; height: 18px; } 

/* One Last Thing Page */
.confirmation-check { margin-top: 40px; } 

/* Fail Step */
.fail-step { text-align: center; height: 100%; width: 100%; max-width: 400px; padding: 80px 20px 20px; } 
.fail-step h3 { font-weight: 600; padding: 5px 0 20px; } 
.fail-step p { text-align: left; color: #7E7E7E; } 
.fail-step .icon-cross-circled { font-size: 3em; color: #D84242; padding-bottom: 10px; display: block; } 

/* Success Step */
.success-step { text-align: center; } 
.success-step.centered-box { font-size: 1em; } 
.success-step h3 { font-weight: 600; padding: 5px 0 20px; } 
.success-step p { text-align: left; color: #7E7E7E; } 
 .success-step p.center { text-align: center; } 
.success-step i { font-size: 3em; color: #37465A; } 
.success-step i.icon-cross-circled { color: #D84242; } 

/* ID Photo */
.id-photo { text-align: center; height: 100vh; } 
.id-photo h3 { font-weight: 600; padding: 5px 0 20px; } 
.id-photo p { text-align: center; color: #7E7E7E; } 
.id-photo i { font-size: 3em; color: #000; } 

/* Take Photo */
.take-photo { text-align: center; height: 100vh; } 
.take-photo h3 { font-weight: 600; padding: 5px 0 20px; } 
.take-photo p { text-align: center; color: #7E7E7E; } 
.take-photo i { font-size: 3em; color: #000; } 

/* Check Email */
.check-email { text-align: center; height: 100%; } 
.check-email h3 { font-weight: 600; padding: 5px 0 20px; } 
.check-email p { text-align: left; color: #7E7E7E; margin-bottom: 15px; line-height: 1.4; } 
.check-email .icon-email-circled,
.check-email .icon-phone-circled { font-size: 3em; color: #37465A; } 
.check-email p strong { font-weight: 600; color: #000; } 
.check-email .tertiary-button { font-weight: 500; } 
.check-email .incorrect-code { opacity: 0; color: #D84242; font-weight: 400; text-align: center; margin-bottom: 0; margin-top: 16px; position: static; width: calc(100% - 50px); } 
.check-email .incorrect-code.show { opacity: 1; } 
.resend-button { padding-top: 30px; width: 100% } 

/* Ownership Group */
.ownership-group { height: auto; padding: 40px 20px 20px; display: block; min-height: unset; } 
.ownership-group .icon-users-circled { color: #37465A; } 
.ownership-group .small-title { font-size: 1.2em; display: block; text-align: left; font-weight: 600; padding: 20px 0; } 
.ownership-group .member-item { position: relative; padding: 20px 0; border-bottom: 1px solid #E6E6E6; } 
.ownership-group .member-item .header-container { display: flex; position: relative; justify-content: space-between; align-items: baseline; position: relative; } 
.ownership-group .member-item .header-container .name { color: #333; margin-bottom: 10px; word-break: break-word; padding-right: 10px } 
.ownership-group .member-item .header-container .name span { color: #7E7E7E; } 
.ownership-group .member-item .header-container .badge { position: static; white-space: nowrap; margin: 0 0 0 auto; } 
.ownership-group .member-item .email { color: #7E7E7E; word-break: break-word; } 
.ownership-group .member-item .actions { display: flex; justify-content: space-between; margin-top: 20px; } 
.ownership-group .member-item .actions button:only-child { margin-left: auto; } 
.ownership-group .bottom-fixed { padding: 20px 0; } 
.ownership-group .icon-text-button { margin-top: 10px } 

/* Iframe Container */
.terms-and-conditions { width: 100%; height: calc(100vh - 45px); padding: 0; } 
.terms-and-conditions .top-content { height: 50px; position: relative; } 
.terms-and-conditions .top-content .logo { position: relative; width: 70px; left: 50%; margin-left: -35px; top: 5px; } 
.terms-and-conditions .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; z-index: 99; } 
.terms-and-conditions iframe { width: 100%; height: calc(100% - 5px); } 
.terms-and-conditions iframe html div { background: #FFF; } 

/* Sections Index */
.sections-index-wrapper { display: flex; justify-content: center; width: 100%; } 
.sections-index { margin-bottom: 60px; min-height: 450px; padding: 20px 25px; position: relative; width: 100%; max-width: 370px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; height: calc(100vh - 60px); } 
.sections-index .section { padding-left: 30px; position: relative; min-height: 56px; } 
 .sections-index .section.active { margin-top: 5px; } 
 .sections-index .section.active .sections-index-title i { color: #37465A; font-size: 1.7em; } 
 .sections-index .section.active .sections-index-title .title { color: #37465A; margin-top: -5px; font-weight: 600; font-size: 1.4em; position: relative; top: -3px; } 
 .sections-index .section.active p { display: block; color: #7E7E7E; font-weight: normal; font-size: 0.85em; padding: 5px 0 25px 0; } 
 .sections-index .section.active .check { border-color: #37465A; } 
 .sections-index .section.active .check::after { content: ""; display: block; color: #FFF; position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; width: 12px; height: 12px; background: #37465A; border-radius: 100%; font-size: 1em; transition: all .3s; } 

.sections-index .section::before { content: ""; height: calc(100% - 30px); border-left: #d2d2d2 solid 2px; position: absolute; top: 25px; left: 9px; } 
.sections-index .section:nth-child(7)::before { display: none; } 
.sections-index .section .check { background: #FFF; margin-right: 5px; border: 2px solid #717171; display: inline-block; width: 20px; height: 20px; position: absolute; left: 0; border-radius: 100%; transition: all .3s; } 
.sections-index .section .sections-index-title { position: relative; padding-left: 40px; } 
.sections-index .section .sections-index-title i { font-size: 1.7em; color: #7E7E7E; margin-right: 3px; position: absolute; top: -3px; left: 0; } 
.sections-index .section .sections-index-title .title { font-size: 1.2em; line-height: 1.4; color: #7E7E7E; font-weight: 400; top: 0; } 
.sections-index .section p { display: none; } 

/* Main Header */
.main-header { width: 100%; z-index: 999; position: relative; padding: 0 20px; } 
 .main-header.dark { background: #2E405C; color: #fff !important; } 
 .main-header.dark i::before { color: #fff !important; } 
 .main-header.dark .exit-button { color: #fff !important; } 
 .main-header.hide-line::after { display: none; } 
.main-header::after { content: ""; width: calc(100% + 40px); border-bottom: 2px solid #e5e5e5; display: block; position: relative; z-index: 0; left: -20px; } 
.main-header.dark::after { border-bottom: 1px solid #FFFFFF; opacity: 0.1; } 
.main-header .header-information { width: calc(100% + 40px); left: -20px; position: relative; } 
.main-header .header-top { height: 50px; position: relative; } 
.main-header .header-bottom { height: 50px; position: relative; } 
.main-header .header-top .header-top-left,
.main-header .header-bottom .header-bottom-left { position: absolute; left: 0px; height: 100%; } 
.main-header .header-bottom .exit-button { font-size: 1.1em; border: none; background: transparent; color: #37465A; font-weight: 500; padding: 0; position: relative; z-index: 99; cursor: pointer; } 
.main-header .header-bottom .exit-button.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-top .header-top-left.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-top .header-top-center { display: flex; justify-content: center; height: 100%; } 
.main-header .header-top .header-top-center h1 { font-size: 1em; font-weight: 500; letter-spacing: 0px; text-align: center; position: absolute; left: 0; width: 100%; line-height: 50px; } 
.main-header .logo { height: 100%; width: 100%; display: flex; justify-content: center; align-items:center; } 
.main-header .logo i { font-size: 38px; color: #2E405C; } 
.main-header .header-bottom .header-bottom-left { position: absolute; left: 0; } 
.main-header .header-bottom .header-bottom-center h1 { font-size: 1em; font-weight: 500; letter-spacing: 0px; text-align: center; position: absolute; left: 0; width: 100%; line-height: 50px; } 
.main-header .header-bottom .header-bottom-right,
.main-header .header-top .header-top-right { position: absolute; right: 0; top: 0; display: flex; align-items: center; height: 100%; } 
.main-header button { font-size: 1.4em; background: transparent; border: none; padding: 0; transition: all .3s; cursor: pointer; width: 30px; height: 100%; display: flex; justify-content: center; align-items: center; } 
.main-header .back-button { font-size: 1.1em; } 
.main-header .header-bottom .header-bottom-right .avatars-container { display: flex; border-right: 1px solid #D6D6D6; padding-right: 10px; margin-right: 5px; } 
.main-header .header-bottom .header-bottom-right button { width: 40px; } 
.main-header .header-bottom .header-bottom-right button:last-child { width: 30px; margin-left: 5px; } 
.main-header .header-bottom .vertical-divider { height: 22px; border-right: 1px solid #00000036; margin: 0 15px 0 10px; } 
.main-header .header-bottom .popover-guide { position: relative; margin: 0 0px; } 
.main-header .header-bottom .popover-guide p { font-size: .85em; } 
 .main-header .header-bottom .popover-guide.open { z-index: 9999; pointer-events: auto; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-background { width: 100vw; height: 100vh; background-color: #00000061; position: fixed; top: 0; left: 0; z-index: 0; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-content { opacity: 1; pointer-events: auto; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-content::before { content: ""; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #fff; position: absolute; bottom: calc(100% - 2px); right: 20px; } 
 .main-header .header-bottom .popover-guide.open .icon-button { pointer-events: none; } 
 .main-header .header-bottom .popover-guide .icon-button::before { transition: all .2s; background: transparent; display: block; } 
 .main-header .header-bottom .popover-guide.open .icon-button::before { content: ""; width: 40px; height: 40px; top: -5px; left: -5px; background: #fff; border-radius: 50%; z-index: 1; display: block; position: absolute; margin: 0; } 

.main-header .header-bottom .popover-guide .popover-guide-content { pointer-events: none; opacity: 0; transition: all .3s; background: #fff; position: absolute; right: -15px; top: calc(100% + 25px); z-index: 1; width: 288px; padding: 20px; border-radius: 8px; } 
.main-header .header-bottom .popover-guide .button-center button { font-size: 1em; background-color: #37465A; height: 40px; padding: 0; display: flex; justify-content: center; align-items: center; width: 100%; margin-top: 15px; } 
.main-header .header-bottom .popover-guide .button-center button:hover { background: #3a547a; } 
.main-header .header-bottom .popover-guide .icon-button { background: transparent; border: none; padding: 0; width: auto; transition: all .3s; cursor: pointer; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; } 
.main-header .header-bottom .popover-guide .icon-button:disabled,
.main-header .header-bottom .popover-guide .icon-button[disabled] { cursor: auto; } 
.main-header .header-bottom .popover-guide .icon-button i { position: relative; z-index: 2; color: #333333; } 
 .main-header .header-bottom .popover-guide .icon-button i.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-bottom .icon-button.comments-button { color: #333333; height: 30px; width: 30px; display: flex; justify-content: center; align-items: center; } 
.main-header .header-bottom .icon-button.edit-button { color: #D6D6D6; } 
.main-header .header-bottom .icon-button.add-document-button { font-size: 1.2em; } 
 .main-header .header-bottom .popover-guide .icon-button:focus i::after,
 .main-header .header-bottom .popover-guide .icon-button:hover i::after { content: ""; width: 50px; height: 50px; top: -10px; left: -10px; border-radius: 50%; z-index: 1; display: block; position: absolute; margin: 0; background: #3665AB2E; z-index: 9; width: 30px; height: 30px; top: -4px; left: -1px; } 
.main-header .header-bottom .popover-guide .icon-button:focus i.disabled::after,
.main-header .header-bottom .popover-guide .icon-button:hover i.disabled::after { display: none; } 

.edition-bar { background: #688949; color: #fff; height: 24px; width: 100%; opacity: 0; z-index: 2; transition: all .3s; overflow: hidden; max-height: 0; position: relative; z-index: 9; top: -2px; } 
.edition-bar.show { opacity: 1; max-height: 60px; margin-bottom: -2px } 
.edition-bar p { color: #fff; line-height: 24px; font-size: .9em; text-align: center; } 

/* Co-ownership agreement */
.agreement-status { padding: 20px; width: 100%; max-width: 1063px; min-height: calc(100vh - 52px); } 
.agreement-status.sections .button-center { max-width: 375px; margin: auto; } 
.agreement-status .filter-status { margin-left: -20px; padding-left: 20px; padding-right: 15px; display: flex; width: calc(100% + 40px); overflow-x: auto; justify-content: flex-start; align-items: flex-start; padding-bottom: 5px; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; } 
.agreement-status .filter-status::-webkit-scrollbar { display: none; } 
.agreement-status .filter-status li { transition: all .2s; cursor: pointer; border: 1px solid #37465A; color: #37465A; font-size: 1em; padding: 7px 17px; border-radius: 8px; margin-right: 5px; transition: all .3s; white-space: nowrap; } 
 .agreement-status .filter-status li.selected { background: #37465A; color: #FFF; } 
 .agreement-status .filter-status li:focus,
 .agreement-status .filter-status li:hover { background: #37465A; color: #FFF; } 
.agreement-status .empty-state { background: inherit; height: calc(100% - 80px); } 
.gray-alert { background: #EEECEC; border-radius: 4px; margin: 0 5px; padding: 15px; color: #7E7E7E; font-style: italic; font-size: 1em; } 
.agreement-status-card { box-shadow: 0px 2px 10px 0px #d8d8d8; position: relative; top: 0; border-radius: 8px; margin: 15px 0; padding: 20px 10px; display: flex; transition: all .3s; cursor: pointer; background: #FFFFFF; } 
.agreement-status-card:focus,
.agreement-status-card:hover { box-shadow: 0px 2px 15px 0px #cfcfcf; top: -2px; } 
.agreement-status-card .main-icon { font-size: 30px; color: #7E7E7E; width: 55px; } 
 .agreement-status-card .main-icon.icon-check-circled-bold { color: #2AB930; opacity: 1; } 
 .agreement-status-card .main-icon.icon-alert { color: #F5BD4F; opacity: 1; } 
 .agreement-status-card .main-icon.icon-coowners { color: #D6D6D6; opacity: 1; } 
.agreement-status-card .information-container { width: calc(100% - 120px); } 
.agreement-status-card .information-container .comments-button { cursor: pointer; background: transparent; display: flex; border: 0; position: absolute; bottom: 15px; right: 20px; } 
.agreement-status-card .information-container .comments-button p { padding-top: 12px; } 
.agreement-status-card .information-container .comments-button i { font-size: 1.6em; position: relative; top: 3px; height: 30px; width: 30px; } 
.agreement-status-card .information-container .title { font-size: 1.1em; font-weight: 600; } 
.agreement-status-card.disabled * { opacity: 0.5; } 
 .status-container { display: flex; align-items: center; margin-top: 7px; } 
 .status-indicator { display: block; width: 10px; height: 10px; border-radius: 50%; background: #D6D6D6; margin-right: 5px; position: relative; top: -1px; } 
 .status-indicator.success { background: #2AB930; } 
 .status-indicator.disabled { background: #D6D6D6; } 
 .status-indicator.in-progress { background: #FFF; border: 2px solid #2AB930; } 
 .status-indicator.warning { background: #F5BD4F; } 
 .status-indicator.error { background: #D84242; } 
 .agreement-status-card.disabled .status-indicator { background: #D6D6D6; } 
.agreement-status-card .icon-arrow-right { display: flex; justify-content: center; align-items: center; margin-left: auto; font-size: 1.1em; padding-right: 5px; } 
.agreement-status-card .information-container p { font-size: 0.85em; display: flex; line-height: 10px; width: calc(100% - 15px); line-height: 1.2; } 

/* VouchedId */
.vouched-element { width: 100%; height: 100%; overflow: auto; }
#vouched-element button { border-radius: 5px!important; height: 56px!important; position: relative; } 
#vouched-element button:nth-child(2) { margin-bottom: 20px!important; color: #37465A!important; background: #FFF!important; } 
#vouched-element button:focus,
#vouched-element button:active,
#vouched-element button:hover { background: #3a547a!important; outline-width:0!important; } 
#vouched-element button span { font-weight: 400!important; font-size: 15px!important; } 
#vouchedUploadButton { display: none!important; } 
#vouchedIDDirections { padding: 0 20px 0 } 
#idScreenButtons { bottom: 0; width: 100%; padding: 0 10px 0; } 
#idScreenButtons div:nth-child(2) button { background-color: #FFFFFF; color: #37465A!important; } 
#idScreenButtons div:nth-child(2) button:focus,
#idScreenButtons div:nth-child(2) button:hover,
#idScreenButtons div:nth-child(2) button:active { background: transparent!important; border:none!important; outline-width:0!important; } 
#idScreenButtons div:nth-child(2) button:focus span,
#idScreenButtons div:nth-child(2) button:hover span,
#idScreenButtons div:nth-child(2) button:active span { color: #3665AB!important; } 
#idScreenButtons div:nth-child(2) button span { font-weight: 500!important; color: #37465A!important; } 
.navigation svg g svg { display: none; } 
.navigation text { font-family: "Inter", sans-serif!important; } 
.navigation text.vouched_navTitles { font-size: 1em!important; } 
#vouched-element .vouched .handoff-title { font-family: "Inter", sans-serif!important; font-weight: 600; font-size: 2em!important; line-height: 40px; color: #333333; margin-bottom:15px; } 
#vouched-element .vouched .vouched-description { max-width: 410px; padding: 10px 20px!important; } 
#vouched-element .vouched .vouched-description div { font-family: "Inter", sans-serif!important; font-weight: 400; font-size: 1em; line-height: 22px; color: #7E7E7E; max-width: 410px; } 
#vouched-element .vouched span { font-size: 0.8em; font-family: "Inter", sans-serif!important; } 
#vouched-element .vouched span b { font-family: "Inter", sans-serif!important; } 
.verbose-step-content .vouched_idText { font-family: "Inter", sans-serif!important; } 
.verbose-step-content #vouchedIDDirections b { font-family: "Inter", sans-serif!important; } 
.vouched_lowerFaceInstructions .vouched_faceText { font-family: "Inter", sans-serif!important; text-align: left; margin-top: 10px; } 
.vouched_lowestFaceInstructions .vouched_faceText { font-family: "Inter", sans-serif!important; list-style: circle; } 
.vouched_faceText .vouched_faceText { font-family: "Inter", sans-serif!important; } 
.verbose-footer-actions { margin-bottom: 10px; } 
#vouched-element .vouched .progress-indicator .progress-content div { font-family: "Inter", sans-serif!important; font-weight: 500; } 

/* Co-owners */
.co-owners-wrapper { width: 100%; height: 100%; } 
.co-owners { font-size: 1em; width: 100%; padding: 20px; } 
.co-owners .desktop-header { display: flex; padding-bottom: 20px; } 
.co-owners h2 { font-size: 1.5em; font-weight: 600; letter-spacing: 0.1px; } 
.co-owners ul { width: 100%; padding-bottom: 30px; } 
.co-owners ul li { min-height: 52px; display: flex; align-items: center; border-bottom: 1px solid #E5E5E5; position: relative; font-size: 1em; cursor: pointer; padding: 8px 20px 8px 5px; } 
.co-owners ul li:focus,
.co-owners ul li:hover { background: #EBECEF; } 
 .co-owners ul li:last-child { border-bottom: 0; } 
.co-owners ul li .icon-arrow-right { position: absolute; right: 0; } 
.co-owners ul li .badge { top: auto; right: 30px; margin: 0 0 0 auto; white-space: nowrap; position: static; } 
.co-owners ul li p span { color: #7E7E7E; } 
.co-owners > p, .co-owners-status > p { color: #7E7E7E; font-style: italic; font-size: 0.86em; line-height: 1.25em; } 
.co-owners-status { font-size: 1em; width: 100%; padding: 20px; } 
.co-owners-status .user { padding: 20px 0 20px 50px; position: relative; } 
.co-owners-status .user p { font-size: 0.9em; } 
.co-owners-status .user p.email { font-size: 0.9em; color: #9a9a9a; margin-top: 2px; word-break: break-word; } 
.co-owners-status .user .avatar { position: absolute; left: 0; } 
.co-owners-status .progress-item-list { padding: 0 0 15px 20px; } 
.co-owners-status .progress-item { display: flex; align-items: center; padding: 15px 0; position: relative; } 
 .co-owners-status .progress-item.success i { color: #2AB930; border: #2AB930 2px solid; } 
 .co-owners-status .progress-item.warning i { color: #F5BD4F; border: none; background: #FFF; } 
 .co-owners-status .progress-item.warning i::before { content: '\e827'; font-size: 24px; position: absolute; left: -6px; top: 1px; } 
 .co-owners-status .progress-item.warning i::after { top: 22px; left: 10px; border-left: #D6D6D6 2px dotted; } 
 .co-owners-status .progress-item.disabled i { color: #FFF; border: #D6D6D6 2px dotted; } 
 .co-owners-status .progress-item.disabled i::after { border-left: #D6D6D6 2px dotted; } 
.co-owners-status .progress-item:last-child i::after { display: none; } 
.co-owners-status .progress-item p { font-size: 1em; color: #7E7E7E; } 
.co-owners-status .progress-item i { color: #2AB930; border-radius: 50%; border: #2AB930 2px solid; width: 22px; height: 22px; font-size: 10px; display: flex; justify-content: center; align-items: center; margin-right: 15px; position: relative; } 
.co-owners-status .progress-item i::after { content: ""; display: block; height: 30px; border-left: #2AB930 1px solid; position: absolute; left: 8px; top: 20px; } 
.co-owners-status .banner .inline-button { color: #3764AB; font-weight: 500; } 

/* Review and sign your Co-ownership Agreement */
.review-and-sign { padding: 0 20px 160px 20px; width: 100%; font-size: 1em; height: 100%; overflow: auto } 
.review-and-sign.extra-padding { padding-bottom:240px } 
.review-and-sign h1 { font-size: 1.7em; text-align: center; font-weight: 600; } 
.review-and-sign .avatars-container { display: flex; justify-content: center; margin: 20px 0; } 
.review-and-sign p { font-size: 1em; color: #707070; } 
.review-and-sign .bottom-fixed::before { pointer-events: none; content: ""; width: 100%; height: 80px; background-image: linear-gradient(transparent, #FFF); display: block; margin-top: -10px; position: absolute; top:-70px; right: 0; } 
.review-and-sign .bottom-fixed { width: calc(100% - 20px); left: 10px; padding: 20px 10px; pointer-events: none; } 
.review-and-sign .bottom-fixed button { pointer-events: auto; } 

.height-limit-scroll { padding: 35px 0 0; max-width: 880px; margin: auto; } 

.bottom-fixed .button-center button { margin-top: 0; } 
.bottom-fixed .button-center button.secondary-button { margin-top: 10px; } 
.bottom-fixed .button-center:nth-child(2) button { margin-top: 10px; } 

.agreement-document { word-break: break-word; } 
.agreement-document h2 { padding-bottom: 20px; padding-top: 20px; font-weight: 600; font-size: 1.4em; line-height: 24px; color: #333333; } 
.agreement-document h3 { padding-top: 20px; padding-bottom: 15px; font-weight: 600; font-size: 1.2em; line-height: 20px; color: #333333; } 
.agreement-document p { padding-bottom: 15px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ul { margin-bottom: 15px; list-style-type: disc; padding: revert; padding-left: 20px } 
.agreement-document ol { margin-bottom: 15px; list-style-type: decimal; padding: revert; padding-left: 20px } 
.agreement-document ol.lower-alpha { list-style-type: lower-alpha; } 
.agreement-document ol.upper-alpha { list-style-type: upper-alpha; } 
.agreement-document ul li { margin-bottom: 10px; margin-left: 10px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ol li { margin-bottom: 10px; margin-left: 10px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ol li ul { padding-top: 10px; } 
.agreement-document ol li ol { padding-top: 10px; } 
.agreement-document ul li ul { padding-top: 10px; } 
.agreement-document ul li ol { padding-top: 10px; } 
.agreement-document .signature-wrapper { position: relative; } 
.agreement-document .signature-wrapper label { position: absolute; top: -8px; left: 65px; font-size: 0.65em; font-weight: 600; color: #37465A; } 
.agreement-document .signature-wrapper label span { color: #688949; } 
.agreement-document .signature-wrapper .signature { font-family: 'whisper'; font-size: 1.8em; border-bottom: 1px solid #37465A; border-top: 1px solid #37465A; border-left: 1px solid #37465A; padding: 15px; border-radius: 13px 0 0 13px; width: 60px; white-space: nowrap; margin: 20px 0; } 
.agreement-document .date { font-size: 1em; padding-bottom: 20px; } 

/* Dashboard */
.dashboard { height:100%; width: 100%; background: #F9F9F9; overflow: auto; } 
.dashboard .container-information { background: #2E405C; color: #fff; border-radius: 0 0 8px 8px; } 
.dashboard .container-information .address { font-size: 1em; text-align: center; width: 100%; display: block; padding: 25px 0 30px 0; } 
.dashboard .container-information .stat-title { display: flex; justify-content: center; } 
.dashboard .container-information .stat-title p { font-size: 1em; text-align: center; align-items: flex-end; width: 100%; display: contents; } 
.dashboard .container-information .stat-title .icon-info { color: #FFF; display: inline-flex; margin: 1px 0 0 7px; } 
.dashboard .container-information .stat-table-price { display: flex; justify-content: center; align-items: baseline; width: 100%; padding: 15px 0 30px 0; } 
.dashboard .container-information .stat-table-price .price { font-size: 2.28em; } 
.dashboard .container-information .stat-table-price .percentage { display: block; position: relative; padding-left: 20px; margin-left: 15px; } 
.dashboard .container-information .stat-table-price .percentage::after { content: '\e800'; color: #77EA5B; font-family: "cobuy"; display: block; position: absolute; left: 0; bottom: 0; -webkit-transform: rotate(90deg); transform: rotate(90deg); } 
.dashboard .container-information .stat-table-price .percentage.negative::after { content: '\e800'; color: #ff6060; font-family: "cobuy"; display: block; position: absolute; left: 0; bottom: 0; -webkit-transform: rotate(-90deg); transform: rotate(-90deg); } 
.information-table { border-top: 1px solid #FFFFFF33; margin: 0 20px; padding: 15px; position: relative; } 
.information-table span { font-size: 0.85em; font-weight: 200; } 
.information-table > span { font-weight: 400; color: #FFFFFF; opacity: 0.7 } 
.information-table .information-table-title { font-size: 1em; margin: 5px 0 15px 0; position: relative; font-weight: 600; color: #2E405C; } 
.information-table .description { position: absolute; right: 10px; top: 20px; font-weight: 400; color: #333333; opacity: 0.7 } 
.information-table .icon-arrow-down { display: block; position: absolute; right: 10px; top: 0; font-size: 12px; transition: all .4s; } 
.information-table.dropdown-table .table { overflow: hidden; opacity: 0; max-height: 0; transition: all .4s; } 
.information-table.dropdown-table.open .table { opacity: 1; max-height: 500px; } 
.information-table.dropdown-table.open .icon-arrow-down { -webkit-transform: rotate(-180deg); transform: rotate(-180deg); } 
.information-table.dropdown-table .information-table-title { cursor: pointer; color: #FFF; padding-right: 30px; } 
.information-table .table .row { display: flex; justify-content: space-between; border-bottom: 1px solid #97979733; padding: 10px 0; } 
.dashboard .container-information .information-table .table .row:last-child { border: none; } 
.information-table .table .row span { font-size: 1em; font-weight: 400; } 
.information-table .table .row span b { color: #7E7E7E; font-weight: 500; } 
.dashboard .container-information .information-table .table .row span b { color: #FFF; } 
.dashboard .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 

/* My profile */
.profile-wrapper { width: 100%; } 
.profile-wrapper .profile { width: 100%; padding: 20px; max-width: 410px; margin: auto } 
.profile .desktop-header { display: flex; padding-bottom: 20px; } 
.profile .desktop-header button { padding: 0; margin: 0 0 0 auto; } 
.profile .desktop-header button.icon-text-button { font-size: 1.15em; line-height: 24px; } 
.profile .desktop-header button.icon-text-button i { font-size: 18px; } 
.profile .banners { margin-bottom: 20px; max-width: 500px } 
.profile h2 { font-size: 1.5em; font-weight: 600; letter-spacing: 0.1px; } 
.profile form .column .text-input-container label,
.profile form .column .select-input label,
.profile form .column .date-input-container label { color: #333333; opacity: 1; } 

/* Document Vault */
.document-vault { height:100%; width: 100%; background: #F9F9F9; overflow: auto; padding: 30px 0 70px } 
.document-vault .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 
.document-vault-bottom h2 { width: 100%; padding: 20px; color: #646464; font-size: 1em; font-weight: 600; letter-spacing: 0.01em; } 
.header-document-vault { width: calc(100% - 20px); display: flex; justify-content: flex-end; } 
.container-cards-tables { padding-bottom: 30px; } 
.document-cards-container { padding: 20px 0 0 0; } 
.document-card { border-radius: 8px; box-shadow: 0px 0px 17px -3px #0000002e; margin: 0 20px 15px 20px; background: #FFF; padding: 20px; cursor: pointer; position: relative; } 
.document-card .document-type { margin: 0 10px 0 0; width: 100%; position: relative; top: -5px; } 
.document-card .header-container { position: relative; margin-right: 25px; } 
.document-card .header-container span.document-title { font-size: 1.15em; font-weight: 600; margin: 5px 10px 10px 0; padding-bottom: 10px; word-break: break-word } 
.document-card .header-container .title-badge-container { display: flex; width: 100%; justify-content: space-between; align-items: baseline; grid-gap: 5px; } 
.document-card .header-container .badge { position: static; white-space: nowrap; margin: 0; } 
.document-card .icon-dots { position: absolute; cursor: pointer; margin-right: -2px; top: 20px; right: 13px; } 
.document-card .icon-dots::before { font-size: 18px; } 
.document-card p { font-size: 0.85em; margin-top: 10px; } 
.document-card p.description { width: 100%; line-height: 16px; } 

.popover-menu { position: absolute; border-radius: 4px; box-shadow: 0px 0px 17px -3px #0000002e; background: #fff; right: 20px; width: -webkit-max-content; width: max-content; max-width: 200px; z-index: 999; top: 0; } 
.popover-menu i { position: relative; z-index: 99; cursor: pointer; display: block; font-size: 1.03em; border-bottom: #d6d6d6 solid 1px; padding: 10px 20px 10px 15px; } 
.popover-menu i::before { margin-right: 10px; } 
.popover-menu i:hover, .popover-menu i:focus { background-color: #fafafa; } 
.popover-menu i:last-child { border: none; } 
.popover-menu .overlay { position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; cursor:auto } 

/* Chat Drawer */
.chat-drawer { height: calc(100vh - 60px); width: 100%; overflow-y: auto; } 
.chat-drawer .chat-group { border-bottom: 1px solid #e5e5e5; position: relative; padding: 0 20px; height: 75px; display: flex; align-items: center; -webkit-transform: all .2s; transform: all .2s; cursor: pointer; background: #fff; } 
.chat-drawer .chat-group:hover { background: #3665AB2E; } 
.chat-drawer .chat-group.active { background: #3665AB2E; } 
.chat-drawer .chat-group .icon-cobuy,
.chat-drawer .chat-group .icon-users { width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: center; justify-content: center; } 
.chat-drawer .chat-group .icon-cobuy { background: #EAECEF; border: #37465A 1px solid; color: #37465A; font-size: 23px; } 
.chat-drawer .chat-group .icon-users { background: #F0F3ED; border: #688949 1px solid; color: #688949; font-size: 18px; } 
.chat-drawer .chat-group .name { font-size: 1.12em; font-weight: 500; margin-left: 15px; } 
.chat-drawer .chat-group .badge-number { padding-top: 1px; width: 24px; height: 24px; background: #688949; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #fff; font-size: 10px; margin: 0 30px 0 auto; } 
.chat-drawer .chat-group .icon-arrow-right { position: absolute; right: 20px; } 
.chat-drawer .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 

/* Chat */
.chat { position: absolute; left: 100%; width: 100vw; background: #FFF; transition: all .5s; height: 100%; border-left: 1px solid #e5e5e5; } 
.chat.open { left: 0; } 
.chat .empty-state { padding-bottom: 80px } 
.chat .chat-messages { padding-bottom: 80px; height: 100%; overflow: auto; display: flex; flex-direction: column-reverse; } 
.chat .chat-messages .date { font-size: 0.85em; text-align: center; width: 100%; display: block; margin: 20px auto 16px; } 
.chat .chat-messages .chat-message { padding: 0 20px; margin: 8px 0; } 
.chat .chat-messages .chat-message.first { margin: 16px 0 8px 0; } 
.chat .chat-messages .chat-message.first-of-all { margin: 8px 0; } 
.chat .chat-messages .chat-message .avatar .circle { background: #688949; font-size: 12px; border: none; margin-bottom: 10px; } 
 .chat .chat-messages .chat-message.own-message .avatar .circle { background: #3665AB; } 
.chat .chat-messages .chat-message .message-box { word-break: break-word; background: #EFF5E9; border-radius: 0px 8px 8px 8px; padding: 20px 20px 30px 20px; color: #333333; width: calc(100% - 40px); position: relative; } 
 .chat .chat-messages .chat-message.own-message .message-box { background: #E7F0FC; border-radius: 8px 0 8px 8px; margin: 0 0 0 auto; } 
.chat .chat-messages .chat-message .message-box .time { color: #333333; opacity: 0.7; font-size: 12px; position: absolute; bottom: 8px; right: 10px; } 
.chat .chat-messages .chat-message .message-box .time span { font-size: 15px; } 
.chat .input-text { position: absolute; width: calc(100% - 40px); bottom: 0; left: 15px; padding: 5px 0 20px; background: #FFF; } 
.chat .chat-messages .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.chat > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 

/* Notification */
.notifications-scroll-content { overflow-y: auto; height: 100%; background: #F9F9F9; transition: all .2s; } 
.notifications-scroll-content .empty-state { padding-bottom: 60px } 
.notifications-scroll-content > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.notifications-scroll-content .notifications-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.notification { cursor: pointer; transition: all .2s; border-bottom: 1px #DEE1E6 solid; padding: 20px; display: flex; background: #FFF; } 
.notification:hover { background: #3665AB2E!important; } 
 .notification.unread { background: #3888ff17; } 
.notification i { width: 34px; height: 34px; font-size: 22px; background: #E1E7DB; border-radius: 50%; margin-right: 10px; border: 1px solid #688949; color: #688949; display: flex; justify-content: center; align-items: center; } 
.notification i.icon-cobuy{ font-size: 28px; border: 1px solid #37465A; color: #37465A; background: #e3e8ee; } 
.notification .avatar { margin: 0 10px 0 0; } 
.notification .avatar .circle { font-size: 12px; } 
.notification .description { width: calc(100% - 100px); } 
.notification .description p { font-size: 0.9em; color: #7E7E7E; } 
.notification .description p b { color: #333333; font-weight: 600; } 
.notification .time-sent { margin-left: 10px; } 
.notification .time-sent p { font-size: 0.85em; color: #7E7E7E; text-align: right; white-space: nowrap; } 

/* Document History */
.document-history { width: 100%; height: 100%; padding: 0; background: #f9f9f9; overflow-y: auto; transition: all .2s; } 
.document-history .document-preview { margin: 20px; border: solid 1px #00000029; background: #fff; position: relative; padding: 18px 16px; cursor: pointer; } 
.document-history .document-preview .document-preview-header { display: flex; width: 100%; grid-gap: 5px; justify-content: space-between; align-items: baseline; padding-bottom: 6px; } 
.document-history .document-preview .document-preview-header .badge { position: static; white-space: nowrap; } 
.document-history .document-preview .document-preview-header h3 { font-size: 1em; font-weight: 500; word-break: break-word; } 
.document-history .document-preview .document-preview-body { display: flex; width: 100%; justify-content: space-between; align-items: baseline; flex-wrap: wrap; grid-gap: 5px; } 
.document-history .document-preview .document-preview-body p { font-size: 0.86em; } 
.document-history .document-preview .document-preview-body span { color: #7E7E7E; font-size: 0.86em; } 
.document-history .document-history-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.document-history > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.document-history .empty-state { background: inherit; padding-bottom: 110px } 

/* Vault Document */
.vault-document { height: 600px; width: 100%; background: #f9f9f9; padding: 0 20px; overflow: auto; } 
.vault-document .avatars-container { display: flex; justify-content: center; margin: 15px 0; } 
.vault-document .no-viewer { height: 70%; text-align: center; display: flex; justify-content: center; flex-direction: column; } 
.vault-document .no-viewer a { font-weight: 600; } 
.vault-document .doc-viewer { position: relative; margin-top: 20px; width: 100%; height: calc(100% - 40px); } 
.vault-document .doc-viewer.small-height { height: calc(100% - 120px); } 
.vault-document .doc-viewer .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.vault-document .img-container { display: flex; justify-content: center; padding-bottom: 20px; margin-top: 20px; } 
.vault-document .img-container.extra-padding { padding-bottom: 116px } 
.vault-document .img-container img { width: 100%; } 
.vault-document .bottom-fixed { flex-direction: row; box-shadow: 0px -4px 20px rgba(255, 255, 255, 0.7); } 
.vault-document .bottom-fixed .button-center { width: 48%; } 
.vault-document .bottom-fixed .button-center button { margin: 0; } 
#proxy-renderer { overflow: hidden; } 



















@media only screen and (min-width: 769px) {
    /* Content */
    .content { padding: 0 0 0 214px; align-items: center; } 
    .content.desktop-no-padding { padding: 0; align-items: center; } 

    .general-container { height: 100vh; position: fixed; overflow-y: hidden; } 
    .general-container-bottom { height: 100%; } 
    .general-container-bottom.content-not-centered { flex-direction: row; } 
    .general-container-bottom.content-not-centered .content { width: calc(100% - 214px); } 
    .general-container-bottom.content-not-centered .menu-drawer { position: relative; } 

    .general-container-bottom .content { height: inherit; } 
    .terms-and-conditions { width: 100%; height: calc(100% - 5px); padding: 0; } 
    .centered-box { min-width: 370px; }
    /*.centered-box { width: 100%; }*/

    /* Drawer */
    .menu-drawer { width: 214px; pointer-events: auto; z-index: 99; position: absolute; } 
    .menu-drawer .menu-drawer-background { display: none; } 
    .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 100%; }

    /* Comments Drawer */
    .right-drawer { width: 390px; border-left: 1px solid #dedede; height: 100%; position: absolute; } 
        .right-drawer.open { left: calc(100% - 390px); } 

    /* Main Header */
    .main-header { z-index: 9999; } 
    .main-header .header-top, .main-header .header-bottom { height: 78px; } 
    .main-header .logo { line-height: 78px; } 
    .main-header .logo i { font-size: 55px; } 
    .main-header .back-button { width: auto; } 
    .main-header .back-button::after { z-index: 2; content: "Back"; position: relative; display: inline; font-size: 1em; font-weight: 600; margin-left: 5px; cursor: pointer; } 

    /* Fail Step */
    .fail-step { padding-top: 0; margin-top: -60px; display: flex; justify-content: center; align-items: center; } 
    .fail-step .bottom-fixed { position: static; margin-top: 50px; width: 100%; } 

    /* Success Step */
    .vertical-centered { height: 100vh; padding: 0 } 
    .vertical-centered .bottom-fixed { position: relative; margin-top: 50px; padding: 0 20px; width: 100%; left: 0;} 
    .vertical-centered .progress-wrapper { margin: 50px 0 20px; } 
    .finalize-agreement-wrapper .success-step p { padding: 0 20px } 

    /* Sections Index */
    .sections-index-wrapper { height: 100%; justify-content: flex-start; overflow: auto; padding: 0 }
    .sections-index { margin: 30px 50px; padding: 40px 0 0; height: -webkit-fit-content; height: -moz-fit-content; height: fit-content; } 
    .sections-index .bottom-fixed { position: static; padding: 20px 0; width: 100%; }
    .sections-index .bottom-fixed .button-center{ max-width: none; }

    /* Co-ownership agreement */
    .agreement-status-card { border-radius: 16px; padding: 15px 10px; } 
    .status { margin-top: 0; position: absolute; right: 70px; } 
    .agreement-status-card .information-container { display: flex; justify-content: space-between; align-items: center; } 
    .agreement-status-card .information-container p { margin-top: 0; } 
    .agreement-status-card .information-container .title { position: relative; top: 1px; } 
    .agreement-status-card .icon-arrow-right { padding-left: 20px; } 
    .agreement-status-card .main-icon { opacity: 0.5; } 
    .agreement-status-card .information-container .comments-button p { position: static; } 
    .agreement-status-card .information-container .comments-button { bottom: 22px; } 
    
    /* Initial step */
    .content-wrapper { height: 100%; } 
    
    /* Login */
    .login-background { background-image: url(https://assets.cobuy.io/img/login-bg.jpeg); height: 100vh; width: 40vw; background-size: cover; background-position: center; position: fixed; left: 0; }
    .sign-up-background { background-image: url(https://assets.cobuy.io/img/signup-bg.jpeg); height: 100vh; width: 40vw; background-size: cover; background-position: center; position: fixed; left: 0; }
    .login-wrapper { margin: auto; position: relative; left: 20%; } 

    /* Steps */
    .step-question-wrapper { height: 100%; justify-content: flex-start; overflow: auto; padding: 0 }
    .step-question { margin: 30px 50px; padding: 0; height: -webkit-fit-content; height: -moz-fit-content; height: fit-content; max-width: 370px; } 
    .step-question h4.icon-back { font-size: 1.2em; text-align: left; letter-spacing: 0px; padding: 10px 10px 10px 0; cursor: pointer; font-weight: 500; display: inline-block;} 
    .step-question h4.icon-back::before { margin-right: 12px; }  
    .step-question h4.icon-back i { font-size: .90em; }
    .step-question p { margin-top: 20px; } 
    .step-question .icon-text-button { font-size: 1em } 

    /* Check email-phone */
    .check-email .bottom-fixed { margin-top: 0; } 

    /* Ownership Group */
    .ownership-group .small-title { padding: 50px 0 0; } 
    .ownership-group { height: auto; } 

    /* General */
    .status-container { margin-top: 0; } 

    /* Buttons */
    .button-center button:focus,
    .button-center button:hover { background: #3a547a; } 
    .button-center .secondary-button:focus,
    .button-center .secondary-button:hover { background: rgba(55, 70, 90, 0.2); } 
    .button-center .tertiary-button:focus,
    .button-center .tertiary-button:hover { color: #3665AB; background: transparent; } 
    .icon-text-button:focus i,
    .icon-text-button:hover i { color: #4a6397!important } 
    .icon-text-button:focus,
    .icon-text-button:hover { color: #4a6397; } 
    .general-header .top-content .icon-button.comments-button:focus,
    .general-header .top-content .icon-button.comments-button:hover,
    .general-header .top-content .icon-button.edit-button:focus,
    .general-header .top-content .icon-button.edit-button:hover { color: #3564a4; } 
    .simple-button:hover, 
    .simple-button:focus { color: #3665AB; } 
    button.inline-button:hover, 
    button.inline-button:focus { color: #3665AB; }

    @media all and (min-device-width: 1366px) and (max-device-height: 768px) {
        .button-center { padding-bottom: 40px !important;}
        .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 90%; }
        .chat { height: 90%;}
    }

    @media all and (min-device-width: 768px) and (max-device-height: 1366px) {
        .button-center { padding-bottom: 40px !important;}
        .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 90%; }
        .chat { height: 90%;}
    }

    /* File uploader */
    .modal-overlay .modal .file-uploader button:hover p,
    .file-uploader button:hover p,
    .modal-overlay .modal .file-uploader button:focus p,
    .file-uploader button:focus p,
    .modal-overlay .modal .file-uploader button:active p,
    .file-uploader button:active p { color: #37465A } 
    .modal-overlay .modal .file-uploader button:hover,
    .file-uploader button:hover,
    .modal-overlay .modal .file-uploader button:focus,
    .file-uploader button:focus, 
    .modal-overlay .modal .file-uploader button:active,
    .file-uploader button:active { border: 1px solid #37465A; background-color: rgba(54,101,171,0.07) } 
    .modal-overlay .modal .file-uploader button.with-file:hover,
    .file-uploader button.with-file:hover,
    .modal-overlay .modal .file-uploader button.with-file:focus,
    .file-uploader button.with-file:focus,
    .modal-overlay .modal .file-uploader button.with-file:active,
    .file-uploader button.with-file:active { background: rgba(105, 136, 76, 0.1); border: 1px solid #688949; } 

    /* General info-title content */
    .bottom-fixed.have-comment p { padding: 0; } 
    .bottom-fixed.have-comment.text-align-left { width: 100%; max-width: 410px; padding: 0 20px; } 

    /* Review and sign your Co-ownership Agreement */
    .review-and-sign { padding: 0 50px 160px 50px; } 
    .review-and-sign .bottom-fixed { padding-left: 214px; }

    /* Co-ownership agreement */
    .agreement-status { min-width: 560px; padding: 30px 50px; min-height: calc(100vh - 80px); } 
    .agreement-status .desktop-nav { margin-bottom: 20px } 
    .agreement-document h2 { font-size: 1.6em; } 
    .agreement-document h3 { font-size: 1.4em; } 
    .agreement-document p { font-size: 1.2em; } 
    .agreement-document ul { padding-left: 30px } 
    .agreement-document ol { padding-left: 30px } 
    .agreement-document ol li { font-size: 1.2em; } 
    .agreement-document ul li { font-size: 1.2em; } 
    .agreement-document ol li ul { font-size: 1em; padding-top: 15px; } 
    .agreement-document ol li ul li { font-size: 1em; } 
    .agreement-document .signature-wrapper .signature { font-size: 2.1em; padding: 20px 15px; } 

    /* Document Vault */
    .document-vault { padding: 18px 38px; } 
    .document-vault .document-vault-top { width: 100%; display: flex; } 
    .document-vault .document-vault-bottom { width: 100%; display: flex; flex-wrap: wrap; } 
    .document-vault .document-vault-top .document-card { width: calc(100%/2 - 12px ); margin: 12px; } 
    .document-vault .document-vault-bottom .document-card { width: calc(100%/2 - 24px ); margin: 0 12px 12px 12px; } 
    .document-vault .header-document-vault { padding-top: 30px; } 

    /* My profile */
    .profile-wrapper { background: #F9F9F9; height: 100%; display: flex; justify-content: center; overflow: auto; padding: 0 } 
    .profile-wrapper .profile { max-width: none; margin: 30px  50px; padding: 0; height: -webkit-fit-content; height: -moz-fit-content; height: fit-content; } 
    .profile-wrapper .profile .desktop-header { padding-bottom: 20px; } 
    .profile .buttons-profile { display: flex; justify-content: center; width: 100%; } 
    .profile .buttons-profile .button-center { width: 50%; padding: 10px; max-width: 310px;} 
    .profile .buttons-profile button { margin: 0;  } 
    .profile .buttons-profile .tertiary-button { border: 1px solid #3a547a; } 

    /* Chat */
    .chat-drawer { width: 280px; position: relative; margin: 0 auto 0 0; } 
    .chat { position: absolute; left: auto; right: 0; width: calc(100% - 494px); margin: 0 0 0 auto; padding: 0; background: #F9F9F9; } 
    .chat .empty-state { background: #F9F9F9; } 
    .chat .input-text { background: #F9F9F9; } 
    .chat .chat-messages .messages .chat-message .message-box { width: calc(100% - 20%); } 
    .chat .chat-messages .messages .chat-message { padding: 0; } 
    .chat .chat-input-container { margin: 0 3%; } 
    .chat .chat-input-container .icon-send { right: 5px; } 
    .chat .chat-messages .chat-message { padding: 0 calc(3% + 25px); } 

    /* Co-owners */
    .co-owners-wrapper { background: #F9F9F9; display: flex; justify-content: flex-start; overflow: auto; } 
    .co-owners, 
    .co-owners-status { margin: 30px 50px; padding: 0; height: -webkit-fit-content; height: -moz-fit-content; height: fit-content; } 
    .co-owners .desktop-header { padding-bottom: 20px; } 
    .step-question-wrapper.invite-co-owner-wrapper, 
    .step-question-wrapper.signature-wrapper { background: #F9F9F9; }
    .content .nice-work-wrapper, .content .finalize-agreement-wrapper { height: calc(100% - 78px); } 

    /* Dashboard */
    .dashboard { width: 100%; padding: 30px 50px; height: 100%; } 
    .dashboard .container-information { border-radius: 16px; display: flex; min-height: 227px; padding: 20px 40px; align-items: center; justify-content: space-between; } 
    .dashboard .container-information .address { display: none; } 
    .dashboard .container-information .stat-table-price .percentage { padding-left: 22px; font-size: 16px; } 
    .dashboard .container-information .stat-title { justify-content: left; } 
    .dashboard .container-information .stat-title p { text-align: left; } 
    .dashboard .container-information .stat-table-price { padding: 15px 0 0 0; } 
    .dashboard .container-information .stat-table-price .price { font-size: 1.4em; } 
    .dashboard .container-information .information-table-title { color: #fff; } 
    .tables-container { padding: 0 20px; } 
    .tables-container .information-table { width: 100%; } 
    .document-cards-container { padding: 20px 0 0 0; } 
    .document-card { margin: 0 0 30px 0; } 
    .stat { padding-right: 30px; font-size: 21px; } 
    .information-table { margin: 0; padding: 15px 0; width: 45%; } 
    .information-table.dropdown-table .information-table-title { padding-right: 0; } 
    .information-table.dropdown-table .table { opacity: 1; max-height: none; border-top: 1px solid #97979733; } 
    .information-table { border-top: none; } 
    .information-table .icon-arrow-down { display: none; } 
    .information-table .information-table-title { margin: 5px 0 10px 0; color: #2E405C; } 
    .stat-title { font-size: 16px; } 

    /* Document History */
    .document-history { padding: 30px 50px } 
    .document-history .document-history-infinite-scroll .document-preview { width: calc(100%/2 - 20px); margin: 0 20px 20px 0; } 
    .document-history .document-history-infinite-scroll { display: flex; flex-wrap: wrap; padding-top: 30px } 
    .document-history .spinner-container { height: calc(100% - 80px) } 

    /* Vault Document */
    .vault-document { padding: 30px 50px 0px } 
    .vault-document .spinner-container { height: calc( 100% - 40px); } 
    .vault-document .bottom-fixed { background: #fff; box-shadow: 0px -4px 20px rgb(0 0 0 / 10%); width: calc(100% - 214px); left: 214px; } 
    .vault-document .bottom-fixed .button-center { width: 165px; margin: 0 10px; } 
    .vault-document .doc-viewer { height: calc(100% - 65px); } 
    .vault-document .doc-viewer.small-height { height: calc(100% - 160px); } 
}
    
@media only screen and (min-width: 1024px) {
    /* Document Vault */
    .document-vault { padding: 38px 88px 18px; }

    /* Vault Document */
    .vault-document { padding: 50px 100px 0px } 

    /* Document history */
    .document-history { padding: 50px 100px 30px }

    /* Sections Index */
    .sections-index { margin: 50px 100px 30px; }
    
    /* Dashboard */
    .dashboard { padding: 50px 100px 30px; } 
    .dashboard .container-information .stat-table-price .price { font-size: 2em; } 
    .dashboard .container-information .stat-table-price .percentage { padding-left: 30px; font-size: 21px; } 
    .dashboard .container-information { padding: 20px 60px; } 
    .container-cards-tables { display: flex; justify-content: space-between; margin-top: 10px; } 
    .document-cards-container { width: 45%; } 
    .tables-container { width: 45%; padding-right: 0 50px 0 0; } 

    /* My profile */
    .profile-wrapper .profile { margin: 50px 100px 30px; } 
    .profile .desktop-header { padding-bottom: 20px; } 
    .profile form { display: flex; flex-wrap: wrap; width: calc(100% + 20px); position: relative; left: -10px; } 
    .profile form .column { width: 100%; display: flex; flex-wrap: wrap; } 
    .profile form .column:nth-child(3) { border-top: #e0e0e0 1px solid; padding: 20px 0; margin-top: 20px; } 
    .profile form .column .text-input-container,
    .profile form .column .select-input,
    .profile form .column .date-input-container { width: calc(50% - 20px); margin: 10px; } 

    /* Wrappers */
    .content .nice-work-wrapper, 
    .content .sections-index-wrapper, 
    .content .finalize-agreement-wrapper,
    .content .ownership-group-wrapper { padding-right: 214px; } 

    /* Step question */
    .step-question { margin: 50px 100px 30px; }

    /* Co-owners */
    .co-owners, 
    .co-owners-status { margin: 50px 100px 30px; max-width: 500px; } 

    /* Co-ownership agreement */
    .agreement-status { padding: 50px 100px 30px; } 

    /* Document History */
    .document-history .document-history-infinite-scroll .document-preview { width: calc(100%/3 - 20px); } 
}

@media only screen and (min-width: 1200px){
    .profile form { display: flex; flex-wrap: wrap; width: calc(100% + 40px); position: relative; left: -20px; } 
    .profile form .column { width: calc(100%/3 - 40px); margin: 20px; } 
    .profile form .column:nth-child(3) { border-top: none; padding: 0; } 
    .profile form .column { display: block; } 
    .profile form .column .text-input-container,
    .profile form .column .select-input,
    .profile form .column .date-input-container { width: 100%; margin: 0 0 25px 0; } 

    .document-vault .document-vault-bottom .document-card { width: calc(100%/3 - 24px ); } 
}

@media only screen and (max-height: 800px){
    .content-wrapper.login-wrapper { height: auto; } 
}

@media only screen and (max-width: 400px){
    .document-card .header-container { display: block; } 
}





