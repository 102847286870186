@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Whisper&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@font-face {
    font-family: 'cobuy';
    src: url('../fonts/cobuy.eot?61016037');
    src: url('../fonts/cobuy.eot?61016037#iefix') format('embedded-opentype'),
         url('../fonts/cobuy.woff2?61016037') format('woff2'),
         url('../fonts/cobuy.woff?61016037') format('woff'),
         url('../fonts/cobuy.ttf?61016037') format('truetype'),
         url('../fonts/cobuy.svg?61016037#cobuy') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'whisper';
    src: url('../fonts/whisper-Regular.ttf');
    font-weight: normal;
    font-style: normal;
  }

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "cobuy";
    font-style: normal;
    font-weight: normal;
    speak: never;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: .2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-back:before { content: '\e800'; } 
.icon-check:before { content: '\e801'; } 
.icon-check-circled:before { content: '\e802'; } 
.icon-cross:before { content: '\e803'; } 
.icon-cross-circled:before { content: '\e804'; } 
.icon-email:before { content: '\e805'; } 
.icon-email-circled:before { content: '\e806'; } 
.icon-info:before { content: '\e807'; } 
.icon-phone:before { content: '\e808'; } 
.icon-phone-circled:before { content: '\e809'; } 
.icon-users:before { content: '\e80a'; } 
.icon-users-circled:before { content: '\e80b'; } 
.icon-camera:before { content: '\e80c'; } 
.icon-camera-circled:before { content: '\e80d'; } 
.icon-card:before { content: '\e80e'; } 
.icon-card-circled:before { content: '\e80f'; } 
.icon-ok-circle:before { content: '\e810'; } 
.icon-plus:before { content: '\e811'; } 
.icon-arrow-down:before { content: '\e812'; } 
.icon-arrow-right:before { content: '\e813'; } 
.icon-policies:before { content: '\e814'; } 
.icon-joint:before { content: '\e815'; } 
.icon-send:before { content: '\e816'; } 
.icon-log-out:before { content: '\e817'; } 
.icon-menu:before { content: '\e818'; } 
.icon-coowners:before { content: '\e819'; } 
.icon-delete:before { content: '\e81a'; } 
.icon-alert:before { content: '\e81b'; } 
.icon-edit:before { content: '\e81c'; } 
.icon-chat:before { content: '\e81d'; } 
.icon-exit:before { content: '\e81e'; } 
.icon-payments:before { content: '\e81f'; } 
.icon-financial:before { content: '\e820'; } 
.icon-ownership:before { content: '\e821'; } 
.icon-basics:before { content: '\e822'; } 
.icon-agreement:before { content: '\e823'; } 
.icon-check-circled-bold:before { content: '\e824'; } 
.icon-bell:before { content: '\e825'; } 
.icon-view:before { content: '\e826'; } 
.icon-alert-bold:before { content: '\e827'; } 
.icon-document:before { content: '\e828'; } 
.icon-user:before { content: '\e829'; } 
.icon-grid:before { content: '\e82a'; } 
.icon-document-bold:before { content: '\e82b'; } 
.icon-dots:before { content: '\e82c'; } 
.icon-chat-circled:before { content: '\e82d'; } 
.icon-upload-version:before { content: '\e82f'; } 
.icon-share:before { content: '\e830'; } 
.icon-history:before { content: '\e831'; } 
.icon-download:before { content: '\e832'; } 
.icon-blockchain:before { content: '\e833'; } 
.icon-cobuy:before { content: '\e834'; } 
.icon-sent:before { content: '\e835'; } 
.icon-read:before { content: '\e836'; } 
.icon-trash:before { content: '\e837'; }
.icon-payment:before { content: '\e838'; }
.icon-cross-toast:before { content: '\e839'; }
.icon-empty-icon:before { content: '\e84d'; }
.icon-signal:before {
    font-family: 'Material Icons', serif; content: '\e202'; }