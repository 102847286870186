@media only screen and (min-width: 769px) {
    /* Content */
    .content { padding: 0 0 0 214px; align-items: center; } 
    .content.desktop-no-padding { padding: 0; align-items: center; } 

    .general-container { height: 100vh; position: fixed; overflow-y: hidden; } 
    .general-container-bottom { height: 100%; } 
    .general-container-bottom.content-not-centered { flex-direction: row; } 
    .general-container-bottom.content-not-centered .content { width: calc(100% - 214px); } 
    .general-container-bottom.content-not-centered .menu-drawer { position: relative; } 

    .general-container-bottom .content { height: inherit; } 
    .terms-and-conditions { width: 100%; height: calc(100% - 5px); padding: 0; } 
    .centered-box { min-width: 370px; }
    /*.centered-box { width: 100%; }*/

    /* Drawer */
    .menu-drawer { width: 214px; pointer-events: auto; z-index: 99; position: absolute; } 
    .menu-drawer .menu-drawer-background { display: none; } 
    .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 100%; }

    /* Comments Drawer */
    .right-drawer { width: 390px; border-left: 1px solid #dedede; height: 100%; position: absolute; } 
        .right-drawer.open { left: calc(100% - 390px); } 

    /* Main Header */
    .main-header { z-index: 9999; } 
    .main-header .header-top, .main-header .header-bottom { height: 78px; } 
    .main-header .logo { line-height: 78px; } 
    .main-header .logo i { font-size: 55px; } 
    .main-header .back-button { width: auto; } 
    .main-header .back-button::after { z-index: 2; content: "Back"; position: relative; display: inline; font-size: 1em; font-weight: 600; margin-left: 5px; cursor: pointer; } 

    /* Fail Step */
    .fail-step { padding-top: 0; margin-top: -60px; display: flex; justify-content: center; align-items: center; } 
    .fail-step .bottom-fixed { position: static; margin-top: 50px; width: 100%; } 

    /* Success Step */
    .vertical-centered { height: 100vh; padding: 0 } 
    .vertical-centered .bottom-fixed { position: relative; margin-top: 50px; padding: 0 20px; width: 100%; left: 0;} 
    .vertical-centered .progress-wrapper { margin: 50px 0 20px; } 
    .finalize-agreement-wrapper .success-step p { padding: 0 20px } 

    /* Sections Index */
    .sections-index-wrapper { height: 100%; justify-content: flex-start; overflow: auto; padding: 0 }
    .sections-index { margin: 30px 50px; padding: 40px 0 0; height: fit-content; } 
    .sections-index .bottom-fixed { position: static; padding: 20px 0; width: 100%; }
    .sections-index .bottom-fixed .button-center{ max-width: none; }

    /* Co-ownership agreement */
    .agreement-status-card { border-radius: 16px; padding: 15px 10px; } 
    .status { margin-top: 0; position: absolute; right: 70px; } 
    .agreement-status-card .information-container { display: flex; justify-content: space-between; align-items: center; } 
    .agreement-status-card .information-container p { margin-top: 0; } 
    .agreement-status-card .information-container .title { position: relative; top: 1px; } 
    .agreement-status-card .icon-arrow-right { padding-left: 20px; } 
    .agreement-status-card .main-icon { opacity: 0.5; } 
    .agreement-status-card .information-container .comments-button p { position: static; } 
    .agreement-status-card .information-container .comments-button { bottom: 22px; } 
    
    /* Initial step */
    .content-wrapper { height: 100%; } 
    
    /* Login */
    .login-background { background-image: url(https://assets.cobuy.io/img/login-bg.jpeg); height: 100vh; width: 40vw; background-size: cover; background-position: center; position: fixed; left: 0; }
    .sign-up-background { background-image: url(https://assets.cobuy.io/img/signup-bg.jpeg); height: 100vh; width: 40vw; background-size: cover; background-position: center; position: fixed; left: 0; }
    .login-wrapper { margin: auto; position: relative; left: 20%; } 

    /* Steps */
    .step-question-wrapper { height: 100%; justify-content: flex-start; overflow: auto; padding: 0 }
    .step-question { margin: 30px 50px; padding: 0; height: fit-content; max-width: 370px; } 
    .step-question h4.icon-back { font-size: 1.2em; text-align: left; letter-spacing: 0px; padding: 10px 10px 10px 0; cursor: pointer; font-weight: 500; display: inline-block;} 
    .step-question h4.icon-back::before { margin-right: 12px; }  
    .step-question h4.icon-back i { font-size: .90em; }
    .step-question p { margin-top: 20px; } 
    .step-question .icon-text-button { font-size: 1em } 

    /* Check email-phone */
    .check-email .bottom-fixed { margin-top: 0; } 

    /* Ownership Group */
    .ownership-group .small-title { padding: 50px 0 0; } 
    .ownership-group { height: auto; } 

    /* General */
    .status-container { margin-top: 0; } 

    /* Buttons */
    .button-center button:focus,
    .button-center button:hover { background: #3a547a; } 
    .button-center .secondary-button:focus,
    .button-center .secondary-button:hover { background: rgba(55, 70, 90, 0.2); } 
    .button-center .tertiary-button:focus,
    .button-center .tertiary-button:hover { color: #3665AB; background: transparent; } 
    .icon-text-button:focus i,
    .icon-text-button:hover i { color: #4a6397!important } 
    .icon-text-button:focus,
    .icon-text-button:hover { color: #4a6397; } 
    .general-header .top-content .icon-button.comments-button:focus,
    .general-header .top-content .icon-button.comments-button:hover,
    .general-header .top-content .icon-button.edit-button:focus,
    .general-header .top-content .icon-button.edit-button:hover { color: #3564a4; } 
    .simple-button:hover, 
    .simple-button:focus { color: #3665AB; } 
    button.inline-button:hover, 
    button.inline-button:focus { color: #3665AB; }

    @media all and (min-device-width: 1366px) and (max-device-height: 768px) {
        .button-center { padding-bottom: 40px !important;}
        .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 90%; }
        .chat { height: 90%;}
    }

    @media all and (min-device-width: 768px) and (max-device-height: 1366px) {
        .button-center { padding-bottom: 40px !important;}
        .menu-drawer .menu-drawer-content { left: 0; border-right: 1px solid #dedede; height: 90%; }
        .chat { height: 90%;}
    }

    /* File uploader */
    .modal-overlay .modal .file-uploader button:hover p,
    .file-uploader button:hover p,
    .modal-overlay .modal .file-uploader button:focus p,
    .file-uploader button:focus p,
    .modal-overlay .modal .file-uploader button:active p,
    .file-uploader button:active p { color: #37465A } 
    .modal-overlay .modal .file-uploader button:hover,
    .file-uploader button:hover,
    .modal-overlay .modal .file-uploader button:focus,
    .file-uploader button:focus, 
    .modal-overlay .modal .file-uploader button:active,
    .file-uploader button:active { border: 1px solid #37465A; background-color: rgba(54,101,171,0.07) } 
    .modal-overlay .modal .file-uploader button.with-file:hover,
    .file-uploader button.with-file:hover,
    .modal-overlay .modal .file-uploader button.with-file:focus,
    .file-uploader button.with-file:focus,
    .modal-overlay .modal .file-uploader button.with-file:active,
    .file-uploader button.with-file:active { background: rgba(105, 136, 76, 0.1); border: 1px solid #688949; } 

    /* General info-title content */
    .bottom-fixed.have-comment p { padding: 0; } 
    .bottom-fixed.have-comment.text-align-left { width: 100%; max-width: 410px; padding: 0 20px; } 

    /* Review and sign your Co-ownership Agreement */
    .review-and-sign { padding: 0 50px 160px 50px; } 
    .review-and-sign .bottom-fixed { padding-left: 214px; }

    /* Co-ownership agreement */
    .agreement-status { min-width: 560px; padding: 30px 50px; min-height: calc(100vh - 80px); } 
    .agreement-status .desktop-nav { margin-bottom: 20px } 
    .agreement-document h2 { font-size: 1.6em; } 
    .agreement-document h3 { font-size: 1.4em; } 
    .agreement-document p { font-size: 1.2em; } 
    .agreement-document ul { padding-left: 30px } 
    .agreement-document ol { padding-left: 30px } 
    .agreement-document ol li { font-size: 1.2em; } 
    .agreement-document ul li { font-size: 1.2em; } 
    .agreement-document ol li ul { font-size: 1em; padding-top: 15px; } 
    .agreement-document ol li ul li { font-size: 1em; } 
    .agreement-document .signature-wrapper .signature { font-size: 2.1em; padding: 20px 15px; } 

    /* Document Vault */
    .document-vault { padding: 18px 38px; } 
    .document-vault .document-vault-top { width: 100%; display: flex; } 
    .document-vault .document-vault-bottom { width: 100%; display: flex; flex-wrap: wrap; } 
    .document-vault .document-vault-top .document-card { width: calc(100%/2 - 12px ); margin: 12px; } 
    .document-vault .document-vault-bottom .document-card { width: calc(100%/2 - 24px ); margin: 0 12px 12px 12px; } 
    .document-vault .header-document-vault { padding-top: 30px; } 

    /* My profile */
    .profile-wrapper { background: #F9F9F9; height: 100%; display: flex; justify-content: center; overflow: auto; padding: 0 } 
    .profile-wrapper .profile { max-width: none; margin: 30px  50px; padding: 0; height: fit-content; } 
    .profile-wrapper .profile .desktop-header { padding-bottom: 20px; } 
    .profile .buttons-profile { display: flex; justify-content: center; width: 100%; } 
    .profile .buttons-profile .button-center { width: 50%; padding: 10px; max-width: 310px;} 
    .profile .buttons-profile button { margin: 0;  } 
    .profile .buttons-profile .tertiary-button { border: 1px solid #3a547a; } 

    /* Chat */
    .chat-drawer { width: 280px; position: relative; margin: 0 auto 0 0; } 
    .chat { position: absolute; left: auto; right: 0; width: calc(100% - 494px); margin: 0 0 0 auto; padding: 0; background: #F9F9F9; } 
    .chat .empty-state { background: #F9F9F9; } 
    .chat .input-text { background: #F9F9F9; } 
    .chat .chat-messages .messages .chat-message .message-box { width: calc(100% - 20%); } 
    .chat .chat-messages .messages .chat-message { padding: 0; } 
    .chat .chat-input-container { margin: 0 3%; } 
    .chat .chat-input-container .icon-send { right: 5px; } 
    .chat .chat-messages .chat-message { padding: 0 calc(3% + 25px); } 

    /* Co-owners */
    .co-owners-wrapper { background: #F9F9F9; display: flex; justify-content: flex-start; overflow: auto; } 
    .co-owners, 
    .co-owners-status { margin: 30px 50px; padding: 0; height: fit-content; } 
    .co-owners .desktop-header { padding-bottom: 20px; } 
    .step-question-wrapper.invite-co-owner-wrapper, 
    .step-question-wrapper.signature-wrapper { background: #F9F9F9; }
    .content .nice-work-wrapper, .content .finalize-agreement-wrapper { height: calc(100% - 78px); } 

    /* Dashboard */
    .dashboard { width: 100%; padding: 30px 50px; height: 100%; } 
    .dashboard .container-information { border-radius: 16px; display: flex; min-height: 227px; padding: 20px 40px; align-items: center; justify-content: space-between; } 
    .dashboard .container-information .address { display: none; } 
    .dashboard .container-information .stat-table-price .percentage { padding-left: 22px; font-size: 16px; } 
    .dashboard .container-information .stat-title { justify-content: left; } 
    .dashboard .container-information .stat-title p { text-align: left; } 
    .dashboard .container-information .stat-table-price { padding: 15px 0 0 0; } 
    .dashboard .container-information .stat-table-price .price { font-size: 1.4em; } 
    .dashboard .container-information .information-table-title { color: #fff; } 
    .tables-container { padding: 0 20px; } 
    .tables-container .information-table { width: 100%; } 
    .document-cards-container { padding: 20px 0 0 0; } 
    .document-card { margin: 0 0 30px 0; } 
    .stat { padding-right: 30px; font-size: 21px; } 
    .information-table { margin: 0; padding: 15px 0; width: 45%; } 
    .information-table.dropdown-table .information-table-title { padding-right: 0; } 
    .information-table.dropdown-table .table { opacity: 1; max-height: none; border-top: 1px solid #97979733; } 
    .information-table { border-top: none; } 
    .information-table .icon-arrow-down { display: none; } 
    .information-table .information-table-title { margin: 5px 0 10px 0; color: #2E405C; } 
    .stat-title { font-size: 16px; } 

    /* Document History */
    .document-history { padding: 30px 50px } 
    .document-history .document-history-infinite-scroll .document-preview { width: calc(100%/2 - 20px); margin: 0 20px 20px 0; } 
    .document-history .document-history-infinite-scroll { display: flex; flex-wrap: wrap; padding-top: 30px } 
    .document-history .spinner-container { height: calc(100% - 80px) } 

    /* Vault Document */
    .vault-document { padding: 30px 50px 0px } 
    .vault-document .spinner-container { height: calc( 100% - 40px); } 
    .vault-document .bottom-fixed { background: #fff; box-shadow: 0px -4px 20px rgb(0 0 0 / 10%); width: calc(100% - 214px); left: 214px; } 
    .vault-document .bottom-fixed .button-center { width: 165px; margin: 0 10px; } 
    .vault-document .doc-viewer { height: calc(100% - 65px); } 
    .vault-document .doc-viewer.small-height { height: calc(100% - 160px); } 
}
    
@media only screen and (min-width: 1024px) {
    /* Document Vault */
    .document-vault { padding: 38px 88px 18px; }

    /* Vault Document */
    .vault-document { padding: 50px 100px 0px } 

    /* Document history */
    .document-history { padding: 50px 100px 30px }

    /* Sections Index */
    .sections-index { margin: 50px 100px 30px; }
    
    /* Dashboard */
    .dashboard { padding: 50px 100px 30px; } 
    .dashboard .container-information .stat-table-price .price { font-size: 2em; } 
    .dashboard .container-information .stat-table-price .percentage { padding-left: 30px; font-size: 21px; } 
    .dashboard .container-information { padding: 20px 60px; } 
    .container-cards-tables { display: flex; justify-content: space-between; margin-top: 10px; } 
    .document-cards-container { width: 45%; } 
    .tables-container { width: 45%; padding-right: 0 50px 0 0; } 

    /* My profile */
    .profile-wrapper .profile { margin: 50px 100px 30px; } 
    .profile .desktop-header { padding-bottom: 20px; } 
    .profile form { display: flex; flex-wrap: wrap; width: calc(100% + 20px); position: relative; left: -10px; } 
    .profile form .column { width: 100%; display: flex; flex-wrap: wrap; } 
    .profile form .column:nth-child(3) { border-top: #e0e0e0 1px solid; padding: 20px 0; margin-top: 20px; } 
    .profile form .column .text-input-container,
    .profile form .column .select-input,
    .profile form .column .date-input-container { width: calc(50% - 20px); margin: 10px; } 

    /* Wrappers */
    .content .nice-work-wrapper, 
    .content .sections-index-wrapper, 
    .content .finalize-agreement-wrapper,
    .content .ownership-group-wrapper { padding-right: 214px; } 

    /* Step question */
    .step-question { margin: 50px 100px 30px; }

    /* Co-owners */
    .co-owners, 
    .co-owners-status { margin: 50px 100px 30px; max-width: 500px; } 

    /* Co-ownership agreement */
    .agreement-status { padding: 50px 100px 30px; } 

    /* Document History */
    .document-history .document-history-infinite-scroll .document-preview { width: calc(100%/3 - 20px); } 
}

@media only screen and (min-width: 1200px){
    .profile form { display: flex; flex-wrap: wrap; width: calc(100% + 40px); position: relative; left: -20px; } 
    .profile form .column { width: calc(100%/3 - 40px); margin: 20px; } 
    .profile form .column:nth-child(3) { border-top: none; padding: 0; } 
    .profile form .column { display: block; } 
    .profile form .column .text-input-container,
    .profile form .column .select-input,
    .profile form .column .date-input-container { width: 100%; margin: 0 0 25px 0; } 

    .document-vault .document-vault-bottom .document-card { width: calc(100%/3 - 24px ); } 
}

@media only screen and (max-height: 800px){
    .content-wrapper.login-wrapper { height: auto; } 
}

@media only screen and (max-width: 400px){
    .document-card .header-container { display: block; } 
}

