/* Primary button */
.button-center button { display: block; width: 100%; height: 56px; border: none; font-family: "Inter", sans-serif; border-radius: 5px; background: #37465A; color: #FFF; font-size: 1em; cursor: pointer; transition: all .2s; margin-top: 32px; } 
    .button-center button[disabled] { background: #EEECEC; color: #7E7E7E; pointer-events: none; } 

/* Secondary button */
.button-center .secondary-button { background: #FFF; border: 1px solid #2E405C; color: #2E405C; margin-top: 13px; font-weight: 500; } 

/* Tertiary button */
.button-center .tertiary-button { background: transparent; color: #37465A; font-weight: 600; margin-top: 10px } 
    .button-center button[disabled].tertiary-button { background: #FFF!important; } 

/* Inline button */
.inline-button { pointer-events: all; padding:0; font-weight: 600; border: none; margin-top: 0; font-family: "Inter", sans-serif; font-size: 1em; cursor: pointer; transition: all .2s; background: transparent; color: #37465A; } 

/* Google button */
.login-wrapper .google-login-button { display: block; width: 100%; border: 1px solid #D6D6D6; font-family: "Inter", sans-serif; padding: 18px; border-radius: 5px; background: transparent; color: #000; font-size: 1em; cursor: pointer; font-weight: 500; } 
    .login-wrapper button.google-login-button:focus { border: 1px solid #37465A; } 
.login-wrapper .google-login-button img { position: absolute; margin-left: -35px; margin-top: -2px; } 

/* Icon-text button */
.icon-text-button { display: block; width: auto; border: none; font-family: "Inter", sans-serif; padding: 15px 0; border-radius: 5px; background: transparent; color: #37465A; font-size: 1.15em; cursor: pointer; transition: all .3s; text-align: left; font-weight: 500; margin-bottom: 20px; white-space: nowrap; } 
.icon-text-button i { color: #37465A!important; } 
    .icon-text-button i.disabled { color:#7E7E7E!important; pointer-events: none; cursor: auto; } 
.icon-text-button span.disabled { color:#7E7E7E; pointer-events: none; cursor: auto; } 

/* Icon button */
.icon-button { background: transparent; border: none; padding: 0; width: auto; transition: all .3s; cursor: pointer; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; } 
    .icon-button:disabled,
    .icon-button[disabled] { cursor: auto; } 
.icon-button i { position: relative; z-index: 2; color: #333333 } 
    .icon-button i.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 

/* Loading button */
.loading-button { position: relative; overflow: hidden; color: #37465A !important; pointer-events: none; } 
.loading-button:before { content: ""; display: block; border: 2px solid transparent; border-radius: 100%; border-top: 2px solid #FFF; width: 30px; height: 30px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; position: absolute; top: 50%; left: 50%; margin-top: -15px; margin-left: -15px; } 
.loading-button.tertiary-button:before { content: ""; display: block; border: 2px solid transparent; border-radius: 100%; border-top: 2px solid #37465A; width: 30px; height: 30px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; position: absolute; top: 50%; left: 50%; margin-top: -15px; margin-left: -15px; } 
.loading-button.tertiary-button { position: relative; overflow: hidden; color: #FFF !important; pointer-events: none; } 

.simple-button { background: none; border: none; color: #37465A; font-size: 1em; font-weight: 500; padding: 0; cursor: pointer; } 



@-webkit-keyframes spin { 
 0% { -webkit-transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); } 
 }

@keyframes spin { 
 0% { transform: rotate(0deg); } 
 100% { transform: rotate(360deg); } 
 }
