body { font-family: "Inter", sans-serif; font-size: 14px; color: #333; line-height: 1.2; } 
#root { font-size: 14px; scrollbar-width: thin; } 
b { font-weight: bold; } 
em { font-style: italic; } 
strong { font-weight: bold; } 

*:focus { outline: none; } 

input, 
select, 
textarea { width: 100%; font-size: 1em; display: block; border: 1px solid #7E7E7E; border-radius: 8px; padding: 0 15px; height: 50px; font-family: "Inter", sans-serif; color: #333333; } 
input:active, 
select:active, 
textarea:active,
input:focus, 
select:focus, 
textarea:focus { border: 1px solid #37465A; } 
input:read-only, 
select:read-only, 
textarea:read-only { border: 1px solid #7E7E7E; cursor: default; } 
input::placeholder, 
select::placeholder, 
textarea::placeholder { color: #acacac; } 
input:disabled, 
select:disabled, 
textarea:disabled { -webkit-text-fill-color: #8b8b8b; opacity: 1; }
input:disabled::placeholder, 
select:disabled::placeholder, 
textarea:disabled::placeholder { -webkit-text-fill-color: #acacac; opacity: 1; }

textarea { resize: none; height: 100px; padding: 15px 15px 10px } 
button { font-family: "Inter", sans-serif; } 
.disabled label { opacity: 0.6; } 

a { color: #37465A; } 
 a:hover,
 a:focus { color: #3665AB } 

h1 { font-size: 2.4em; letter-spacing: -1px; } 
h2 { font-size: 2.2em; letter-spacing: -1px; } 
h3 { font-size: 2em; letter-spacing: -1px; } 
h4 { font-size: 1.7em; letter-spacing: -1px; } 
h5 { font-size: 1.6em; letter-spacing: -1px; } 
h6 { font-size: 1.4em; letter-spacing: -1px; } 

/* Loading Spinner */
.full-page-loading { display: flex; justify-content: center; position: fixed; width: 100%; height: 100%; z-index: 99999999; background: rgba(255,255,255,0.8); top: 0; left: 0; max-height: 0; visibility: hidden; opacity: 0; transition: opacity .3s; } 
.full-page-loading.show { opacity: 1; visibility: visible; max-height: 100%; } 
.full-page-loading .full-page-loading-content { display: flex; flex-direction: column; align-items: center; justify-content: center; } 
.full-page-loading .full-page-loading-content p { font-size: 1em; color: #37465A; margin-top: 30px; font-weight: 500; } 

.spinner-container { height: 100%; width: 100%; display: flex; justify-content: center; align-items: center; } 
.spinner { border: 5px solid transparent; border-radius: 100%; border-top: 5px solid #37465A; width: 60px; height: 60px; -webkit-animation: spin .8s linear infinite; animation: spin .8s linear infinite; } 

@-webkit-keyframes spin { 
 0% { -webkit-transform: rotate(0deg); } 
 100% { -webkit-transform: rotate(360deg); } 
 }

@keyframes spin { 
 0% { transform: rotate(0deg); } 
 100% { transform: rotate(360deg); } 
 }

/* Scrollbar */
::-webkit-scrollbar { z-index: 9999999; width: 8px; height: 8px; background: #eeeeee; } 
::-webkit-scrollbar-track { border-radius: 3px; } 
::-webkit-scrollbar-thumb { background: #c9c9c9; border-radius: 3px; transform: all .2s; } 
::-webkit-scrollbar-thumb:hover { background: #aeaeae; } 

/* General Container */
.general-container { width: 100vw; height: 100%; background-color: #FFFFFF; display: flex; flex-direction: column; }
.top-level-fail-wrapper { height: 100%; width: 100%; display: flex; justify-content: center; } 
.general-container-bottom { width: 100%; position: relative; height: 100%; display: flex; align-items: center; flex-direction: column; box-sizing: border-box; overflow: hidden; } 

.content { height:100%; width: 100%; margin: 0 auto; position: relative; display: flex; align-items: center; flex-direction: column; overflow: scroll; overflow-x: hidden; }
 .content.header-full { padding-top: 95px; } 
 .content.desktop-no-padding { padding: 0; } 
.content-wrapper { min-height: 500px; width: 100%; max-width: 410px; display: flex; padding: 0 20px; flex-direction: column; justify-content: center; align-items: center; } 
.content form { font-size: 1em; width: 100%; } 

/* Content wrappers */
.content .nice-work-wrapper,
.content .finalize-agreement-wrapper,
.content .ownership-group-wrapper { width: 100%; display: flex; justify-content: center; } 
.content .agreement-status-wrapper { background: #F9F9F9; width: 100%; display: flex; justify-content: center; }
.content .nice-work-wrapper, .content .finalize-agreement-wrapper { height: calc(100% - 80px); } 

.bottom-fixed { position: fixed; bottom: 0; width: calc(100% - 16px); height: auto; left: 8px; padding: 20px 12px; background: #FFF; z-index: 9; display: flex; justify-content: center; flex-direction: column; align-items: center; } 
.bottom-fixed .button-center { top: auto; transform: none; padding: 0; width: 100%; max-width: 375px; margin: 0 auto; } 
.bottom-fixed.have-comment { text-align: center; } 
.bottom-fixed.have-comment p { font-size: .9em; margin: 20px; } 
 .bottom-fixed.have-comment p.upper-text { text-align: left; font-size: 1em; font-size: .9em; top: auto; transform: none; padding: 0; width: 100%; max-width: 375px; margin: 0 auto; } 

.bottom-fixed .fail-footer-message { margin-bottom: 20px; } 
.bottom-fixed .fail-footer-message p { text-align: center; margin-bottom: 5px; } 
.bottom-fixed .fail-footer-message p a { color:#37465A; text-decoration: none; font-weight: 700; } 


.vertical-centered { padding: 0 0 80px; height: calc(100vh - 66px); display: flex; justify-content: center; align-items: center; flex-direction: column; } 

.centered-box { font-size: 1em; } 
.centered-box h3 { margin-bottom: 20px; text-align: center; } 
.centered-box h3 strong { background-image: linear-gradient(180deg, rgba(236, 217, 198, 0) 64%, #FCEBCA 0); } 
.centered-box p { color: #737373; } 
.centered-box i.icon-nice-work { background-image: url(./../img/icon-nice-work.svg); width: 100%; height: 100px; background-size: contain; background-position: center; display: block; background-repeat: no-repeat; margin-bottom: 20px; } 
.centered-box .text-align-center { margin-top: 20px; text-align: center; color: #333333; } 
.centered-box .text-align-left { margin-top: 20px; } 
.vertical-centered .progress-wrapper { position: relative; margin: 50px 0 -50px; display: flex; justify-content: center; align-items: center; flex-direction: column; } 
.vertical-centered .progress-wrapper p { margin-top: 20px; color: #7E7E7E; font-size: 1.2em; font-weight: 600; } 
.vertical-centered .progress-wrapper i { position: absolute; top: 0; font-size: 50px; line-height: 70px; color:#37465A; } 
.vertical-centered .progress-wrapper .spinner { width: 70px; height: 70px; } 

.text-center { text-align: center; margin-bottom: 30px; } 
.text-center h3 { font-weight: 600; margin-bottom: 10px; } 
.text-center p { color: #7E7E7E; } 

.scroller { padding: 60px 20px 20px; overflow-y: auto; max-height: 100vh; padding-bottom: 100px; } 

.link-bold a { font-weight: bold; } 

/* Tooltip */
.icon-info { color: #7E7E7E; cursor: pointer; } 
.tooltip { pointer-events: all; } 

/* Checkboxes */
.label-check { width:auto; position: relative; cursor: pointer; margin: 20px 0 25px; } 
.label-check input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 100%; height: 100%; } 
.label-check span { background: #FFF; border: 2px solid #333333af; display:inline-block; width: 18px; height: 18px; position: absolute; border-radius: 5px; transition: all .3s; } 
.label-check input[type="checkbox"]:checked + span:after { content:''; display:block; position:absolute; left: -2px; top: -1px; right:0; bottom:0; margin:auto; width: 18px; height: 18px; background: #37465A; border-radius: 5px; transition: all .3s; } 
.label-check input[type="checkbox"]:checked + span:before { content: "\e801"; font-family: "cobuy"; font-size: 11px!important; display: block; color: #FFF; line-height: 1; position: absolute; top: 1px; z-index: 100; left: 2px; } 
.label-check label { padding-left: 35px; color: #717171; font-size: 1em; line-height: 16px; position: relative; top: 1px; } 
.label-check.disabled input[type="checkbox"]:checked + span:after { background: #7E7E7E; } 
 .label-check.disabled label { color: #7E7E7E; opacity: 1 } 

/* Radio */
.label-radio { position: relative; cursor: pointer; height: 100%; width: 100%; display: flex; align-items: center; } 
.label-radio input[type="radio"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1; cursor: pointer; width: 100%; height: 100%; } 
.label-radio span { background: #FFF; border: 2px solid #717171; display:inline-block; width: 20px; height:20px; position: absolute; top: 0; border-radius: 100%; transition: all .3s; } 
.label-radio input[type="radio"]:checked + span { border-color: #37465A; } 
.label-radio input[type="radio"]:checked + span:after { content: ""; display: block; color: #FFF; position:absolute; left: 0; top: 0; right:0; bottom:0; margin:auto; width: 12px; height: 12px; background: #37465A; border-radius: 100%; font-size: 1em; transition: all .3s; } 
 .disabled .label-radio input[type="radio"]:checked + span { border-color: #7E7E7E; } 
 .disabled .label-radio input[type="radio"]:checked + span:after { background: #7E7E7E; } 
.label-radio label { padding-left: 35px; color: #717171; } 

/* Progress Bar */
.progress-bar-container { width: 100%; height: 5px; background: #D6D6D6; z-index: 2; position: absolute; margin-top: -2px; } 
.progress-bar-container .progress-bar { height: 5px; background: #69884C; border-radius: 0 5px 5px 0; transition: all .3s; } 

/* Checkbox / Radio */
.disabled {
 /*pointer-events: none!important; */
}
.radio-box,
.checkbox-button { border: 1px solid #7E7E7E; border-radius: 10px; margin-bottom: 15px; transition: all .3s; font-size: 0.85em; height: 43px; display: flex; align-items: center; } 
 .radio-box.checked,
 .checkbox-button.checked { border-color: #37465A; background: #EBECEF; } 
 .radio-box.checked.disabled label,
 .checkbox-button.checked.disabled label { color: #333333; font-weight: 600; } 
 .radio-box .label-check.disabled label,
 .checkbox-button .label-check.disabled label { color: #333333; opacity: .6; } 
 .radio-box.checked label,
 .checkbox-button.checked label { color: #37465A; } 
 .radio-box.checked .label-radio span,
 .checkbox-button.checked span { border-color: #37465A; } 
 .radio-box.disabled,
 .checkbox-button.disabled { background: #F9F9F9; border: 1px solid #E8E8E8; } 
 .radio-box.checked.disabled,
 .checkbox-button.checked.disabled { opacity: 1; background: #F5F5F5; border: 1px solid #7E7E7E; } 

 .label-check input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 100%; height: 100%; } 
 .label-check input[type="checkbox"]:checked + span:after { content:''; display:block; position:absolute; left: -2px; top: -1px; right:0; bottom:0; margin:auto; width: 18px; height: 18px; background: #37465A; border-radius: 5px; transition: all .3s; } 
 .label-check input[type="checkbox"]:checked + span:before { content: "\e801"; font-family: "cobuy"; font-size: 11px!important; display: block; color: #FFF; line-height: 1; position: absolute; top: 2px; z-index: 100; left: 2px; } 
 
.radio-box label { display: block; padding: 6px 15px 6px 50px; z-index: 10; position: relative; pointer-events: none; } 
.radio-box .label-radio span,
.checkbox-button .label-check span { top: 50%; left: 15px; transform: translateY(-50%); } 
.radio-box.disabled:not(.checked) .label-radio span,
.checkbox-button.disabled:not(.checked) .label-check span { opacity: .6 } 

/* Radio Button Group */
.radio-button-group { margin-bottom: 25px } 

/* Checkbox Group Container */
.checkbox-button-group .checkbox-button .label-check { width: 100%; height: 100%; position: relative; cursor: pointer; margin: 0; display: flex; align-items: center; } 
.checkbox-button-group .checkbox-button .label-check label { display: block; padding: 6px 15px 6px 50px; z-index: 10; position: relative; top: 1px; } 

/* Bullet List */
.bullet-list { font-weight: bold; list-style-type: disc; margin-left: 20px; } 
.bullet-list li { margin-bottom: 5px; } 

/* Disclaimer Text */
.disclaimer-text { padding: 20px 0; font-size: .85em; line-height: 1.4; } 
.disclaimer-text strong { font-weight: bold; } 

/* Checkbox Box */
.agreement-checkbox-box { border: 1px solid #C1C1C1; padding: 15px; border-radius: 4px; background-color: #F9F9F9; box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08); } 
.agreement-checkbox-box .label-check { display: flex; align-items: center; margin: 0; } 
.agreement-checkbox-box .label-check span { width: 24px; height: 24px; border: 2px solid #C1C1C1; } 
.agreement-checkbox-box .label-check input[type="checkbox"]:checked + span:after { width: 24px; height: 24px; } 
.agreement-checkbox-box .label-check input[type="checkbox"]:checked + span:before { top: 3px; left: 3px; font-size: 14px!important; } 
.agreement-checkbox-box label { line-height: 1.5; padding-left: 45px; } 

/* Select Input */
.select-input { position: relative; z-index: 20; margin-bottom: 25px; } 
.select-input label { position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.select-input .select-label { cursor: pointer; width: 100%; border: 1px solid #7E7E7E; border-radius: 8px; padding: 13px 15px; position: relative; padding-right: 40px; height: 50px; display: flex; align-items: center; color: #333333; background: #FFF; } 
.select-input span:focus { border: 1px solid #37465A; } 
.select-input span:active { border: 1px solid #37465A; } 
 .select-input.disabled .select-label { border: 1px solid #e8e8e8; color: #8b8b8b; background-color: #F9F9F9; } 
.select-input .select-label:before { content: '\e812'; font-family: "cobuy"; display: block; position: absolute; right: 15px; top: 17px; font-size: 12px; transition: all .3s; width: 12px; height: 12px; } 
.select-input ul.options-list { opacity: 0; max-height: 0; visibility: hidden; width: 100%; padding: 0; border-radius: 8px; transition: all .3s; box-shadow: 0 4px 10px rgb(0 0 0 / 15%); position: relative; background: #FFF; overflow: hidden; margin-top: 0; } 
.select-input ul.options-list li { width: 100%; height: 56px; position: relative; padding: 0 20px; cursor: pointer; transition: all .2s; border-radius: 4px; display: flex; align-items: center; justify-content: space-between; } 
.select-input ul.options-list li:focus,
.select-input ul.options-list li:hover { background: #EBECEF; } 
.select-input ul.options-list li .label-radio { width: 20px; height: 20px; } 
.select-input ul.options-list li p { margin: 0; color: #000; font-size: 1em; font-weight: normal; } 
.modal-overlay .modal .select-input span { text-align:left; } 

.select-input.open ul { max-height: 180px; visibility: visible; opacity: 1; overflow-y: auto; padding: 10px; margin-top: 10px; } 
.select-input.open .select-label:before { transform: rotate(180deg); } 

/* Input file uploader */
.modal-overlay .modal .file-uploader, 
.file-uploader { margin-top: 32px } 
.modal-overlay .modal .file-uploader button, 
.file-uploader button { width: 100%; display: flex; align-items: center; justify-content: center; flex-direction: column; min-height: 50px; background: #FFFFFF; border: 1px solid #D6D6D6; border-radius: 8px; padding: 10px 16px; cursor: pointer; } 
.modal-overlay .modal .file-uploader button.error, 
.file-uploader button.error { border-color: #D84242 !important; } 
.modal-overlay .modal .file-uploader button.with-file, 
.file-uploader button.with-file { align-items: flex-start; background: rgba(105, 136, 76, 0.1); border: 1px solid #688949; cursor: auto; pointer-events: none; } 
.modal-overlay .modal .file-uploader p, 
.file-uploader p { max-width: calc(100% - 30px); white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: 0px; font-weight: 400; font-size: 1em; line-height: 17px; color: #7E7E7E } 
.modal-overlay .modal .file-uploader i, 
.file-uploader i { margin-right: 5px; } 
.modal-overlay .modal .file-uploader button.with-file i, 
.file-uploader button.with-file i { margin:0; position: absolute; right: 35px; color: #333333; pointer-events: all; cursor: pointer; } 
.modal-overlay .modal .file-uploader button.with-file p, 
.file-uploader button.with-file p { color: #333333; padding-bottom: 4px; text-align: left; } 
.modal-overlay .modal .file-uploader button.with-file p.file-size, 
.file-uploader button.with-file p.file-size { font-size: 0.8em; line-height: 15px; color: #7E7E7E; } 
.modal-overlay .modal .file-uploader input , 
.file-uploader input { display: none } 
.modal-overlay .modal .file-uploader button.disabled, 
.file-uploader button.disabled { border: 1px solid #e8e8e8; background-color: #F9F9F9; } 
.modal-overlay .modal .file-uploader button.disabled .icon-cross, 
.file-uploader button.disabled .icon-cross { opacity: .5 } 
.modal-overlay .modal .file-uploader button.disabled p, 
.file-uploader button.disabled p { color: #8b8b8b; } 

/* Modal Toast */
.modal-toast-overlay { position: fixed; display: flex; justify-content: center; width: 100%; height: 100%; z-index: 99999999; background: rgba(255,255,255,0.8); top: 0; left: 0; } 
.modal-toast-overlay .modal-toast { color: #333; max-width: 500px; padding: 15px 10px; border-radius: 10px; display: flex; width: 90%; position: absolute; top: 100px; align-items: center; } 
.modal-toast-overlay .success { background: #EAF8EA; border: 1px solid #4E8B43; } 
.modal-toast-overlay .error { background: #FEDEDF; border: 1px solid #D84242; } 
.modal-toast-overlay .info { background: #def2fe; border: 1px solid #2879af; } 
.modal-toast-overlay .modal-toast i { font-size: 1.8em; margin-right: 10px; } 
.modal-toast-overlay .modal-toast.success i { color:#4E8B43 } 
.modal-toast-overlay .modal-toast.error i { color: #D84242;} 
.modal-toast-overlay .modal-toast.info i { color: #2879af;} 

/* Modals */
.modal-overlay { position: fixed; display: flex; justify-content: center; align-items: center; width: 100%; height: 100%; z-index: 9999999; background: rgba(0,0,0,0.5); top: 0; left: 0; } 
.modal-overlay .modal { background: #FFF; border-radius: 10px; width: 90%; max-width: 370px; max-height: 90%; overflow-y: auto; padding: 20px; text-align: center; position: relative; } 
.modal-overlay .modal .step-modal { display: flex; flex-direction: column; }
/*.modal-overlay .modal .modal-content { padding: 0 15px 30px 0; max-height: 330px; margin-top: 30px; position: relative; }*/
.modal-overlay .modal .modal-content { padding: 0 15px 30px 0; margin-top: 30px; position: relative; }
.modal-overlay .modal .modal-content::-webkit-scrollbar { width: 5px; background: rgb(241, 241, 241); }
.modal-overlay .modal .modal-content::-webkit-scrollbar-track { border-radius: 5px; }
.modal-overlay .modal .modal-content::-webkit-scrollbar-thumb { background: rgb(207, 207, 207); border-radius: 10px; }
.modal-overlay .modal .modal-content::-webkit-scrollbar-thumb:hover { background: #cacaca !important; }
 .modal-overlay .modal .modal-content.modal-step-small { height: 230px; padding-bottom: 20px; } 
 .modal-overlay .modal .modal-content.modal-step-big { height: 320px; padding-bottom: 20px; } 
.modal-overlay .modal .modal-title { padding: 0; line-height: 1.4; font-weight: 600; display: block; font-size: 1.45em; margin-bottom:30px } 
.modal-overlay .modal ul { list-style-type: disc; padding-left: 10px; } 
.modal-overlay .modal ol { list-style-type: decimal; padding-left: 20px; } 
.modal-overlay .modal p { font-size: 1em; font-weight: 400; line-height: 1.5; color: #5C5C5C; text-align: left; margin-bottom: 20px; } 
.modal-overlay .modal .margin-bottom-p { font-size: 0.85em; font-weight: 500; line-height: 1.5; color: #5C5C5C; text-align: left; margin-bottom: 20px; } 
.modal-overlay .modal .dots { display: flex; justify-content: center; padding: 10px 0 30px 0; } 
.modal-overlay .modal .dots span { height: 10px; width: 10px; border-radius: 50%; background-color: #D6D6D6; margin: 0 5px; } 
 .modal-overlay .modal .dots span.selected { background-color: #688949; } 
.modal-overlay .modal .close-modal { position: absolute; top: 20px; right: 15px; cursor: pointer; } 
.modal-overlay .modal .close-modal.disabled * { opacity: 0.5; } 
.modal-overlay .modal .button-center button + button { margin-bottom: 0; margin-top: 10px; } 

.modal-overlay .modal .add-user-modal h4 { margin-top: 20px; margin-bottom: 16px; font-weight: 600; } 
.modal-overlay .modal .add-user-modal .header p { padding: 0; font-size: 1em; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .add-user-modal form label { text-align: left; font-size: 0.9em; } 

.modal-overlay .modal .agreement-modal h4 { margin-top: 20px; margin-bottom: 16px; font-weight: 600; } 
.modal-overlay .modal .agreement-modal p { padding: 0; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .agreement-modal .loading-text { margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; text-align: center; color: #37465A; } 
.modal-overlay .modal .agreement-modal form label { text-align: left; } 

.modal-overlay .modal .information-modal p { font-size: 1em; color: #7E7E7E; padding: 0; text-align: left; } 
.modal-overlay .modal .information-modal p + p { margin-top: 0; } 
.modal-overlay .modal .information-modal { padding: 25px 0 0; font-size: 1em; color: #7E7E7E; text-align: left; } 
.modal-overlay .modal .information-modal i { color: #333333; } 
 
.modal-overlay .modal .confirmation-modal { padding: 25px 0 0; } 
.modal-overlay .modal .confirmation-modal .icon-alert { color: #F5BD4F; font-size: 30px; margin-bottom: 10px; display: block; } 
.modal-overlay .modal .confirmation-modal .modal-title { margin-bottom: 24px } 

.modal-overlay .modal .add-document-modal h4 { font-size: 1.1em; margin-bottom: 16px; font-weight: 600; text-align: left; letter-spacing: 0; } 
.modal-overlay .modal .add-document-modal .header p { padding: 0; font-size: 1em; color: #7E7E7E; margin-bottom: 30px; font-weight: 400; } 
.modal-overlay .modal .add-document-modal form label { text-align: left; font-size: 0.9em; }
.modal-overlay .modal .add-document-modal .loading-text { margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; text-align: center; color: #37465A; } 

/* Address Bar */
.address-bar { position: relative; } 
.address-bar .address-options { position: absolute; z-index: 99; background: #FFF; padding: 0; box-shadow: 0 5px 10px rgba(0,0,0,0.3); border-radius: 10px; border: 1px solid #DBDBDB; width: 100%; margin-top: 5px; } 
.address-bar .address-options li { padding: 10px 15px; cursor: pointer } 
.address-bar .address-options li:focus,
.address-bar .address-options li:hover { background: #EBECEF; } 

/* Email Us Alert */
.email-us-message { text-align: center !important; padding-bottom: 15px; } 
.email-us-message a { color:#37465A; text-decoration: none; font-weight: 700; } 

/* reCAPTCHA */
.recaptcha { display: flex; margin-bottom: 0px; align-items: center; justify-content: center; }
.recaptcha iframe { margin: 10px auto 30px; display: block; max-height: 78px; } 

/* Note */
.note { color: #7E7E7E !important; margin: 10px 0px 0px 0px; text-align: left!important; } 

/* Invalid Date */
.invalid-input { color: #D84242 !important; margin: 10px 0px 0px 0px; text-align: left!important; } 
input.error { border-color: #D84242 !important; } 
.error input { border-color: #D84242 !important; } 

/* Code */
.react-code-input { display: flex !important; justify-content: center; } 
.react-code-input input { padding: 0 } 

/* Drawer */
.menu-drawer { width: 100%; height: 100%; position: fixed; top: 0; left: 0; z-index: 9999; pointer-events: none; font-size: 12px; } 
 .menu-drawer.open { pointer-events: auto; } 
 .menu-drawer.open .menu-drawer-background { opacity: 1; visibility: visible; } 
 .menu-drawer.open .menu-drawer-content { left: 0; } 
.menu-drawer .menu-drawer-background { background: #00000047; width: 100%; height: 100%; position: absolute; top: 0; left: 0; opacity: 0; visibility: hidden; transition: all .4s; } 
.menu-drawer .menu-drawer-content { width: 215px; height: 100%; background: #fff; display: flex; flex-direction: column; padding-bottom: 10px; position: absolute; transition: all .4s; top: 0; left: -215px; } 
.menu-drawer .menu-drawer-content .menu-header { font-size: 1.2em; padding: 20px; border-bottom: 1px solid #dedede; } 
.menu-drawer .menu-drawer-content .menu-header .address { font-weight: 700; } 
.menu-drawer .menu-drawer-content .menu-header .city { font-size: 0.9em; line-height: 1.4; } 
.menu-drawer .menu-drawer-content .menu-options { height: inherit; display: flex; flex-direction: column; } 
.menu-drawer .menu-drawer-content .menu-options .menu-options-bottom { margin-top: auto; } 
.menu-drawer .menu-drawer-content .menu-options li { position: relative; display: flex; align-items: center; } 
.menu-drawer .menu-drawer-content .menu-options li button { background: transparent; border: none; width: 100%; height: 40px; display: flex; align-items: center; padding-left: 20px; text-align: left; color: #7E7E7E; font-size: 1em; transition: all .2s; cursor: pointer; } 
 .menu-drawer .menu-drawer-content .menu-options li.active button { background: #3665AB2E; color: #333333; font-weight: 700; } 
 .menu-drawer .menu-drawer-content .menu-options li.active::before { content: ""; display: block; height: 28px; width: 4px; background: #37465A; position: absolute; left: 0; border-radius: 0 4px 4px 0; } 
 .menu-drawer .menu-drawer-content .menu-options li button:focus,
 .menu-drawer .menu-drawer-content .menu-options li button:hover { background: #3665AB2E; color: #333333; font-weight: 700; } 
.menu-drawer .menu-drawer-content .menu-options li button i .notification-badge { top: 10px; left: 35px; } 
.menu-drawer .menu-drawer-content .menu-options li button i { font-size: 1.5em; margin-right: 5px; } 

/* Right Drawer */
.right-drawer { background: #fff; width: 100%; height: 100%; position: fixed; z-index: 999; top: 0; left: 100%; transition: all .4s; font-size: 1em; } 
 .right-drawer.open { left: 0; } 
.right-drawer .right-drawer-wrapper { width: 100%; margin: auto; height: 100%; position: relative; display: flex; flex-direction: column; } 
.right-drawer-wrapper .fixed-content .header { height: 50px; display: flex; position: relative; justify-content: center; align-items: center; border-bottom: 1px #DEE1E6 solid; } 
.right-drawer-wrapper .fixed-content .header .icon-cross { position: absolute; right: 20px; cursor: pointer; } 
.right-drawer-wrapper .fixed-content .header .title { font-weight: 600; width: 100%; text-align: center; } 

/* Comments Drawer */
.right-drawer-wrapper .fixed-content .comment-input { display: flex; justify-content: space-between; align-items: flex-start; padding: 25px 20px; } 
.right-drawer-wrapper .fixed-content .comment-input .input-text { width: calc(100% - 50px); } 
.right-drawer-wrapper .fixed-content .avatar { margin: 5px 0 0 0; width: 50px; } 
.right-drawer-wrapper .comments-scroll-content { height: 100%; overflow-y: auto; position: relative; padding: 0 0 30px; transition: all .2s; } 
.right-drawer-wrapper .comments-scroll-content > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -80px; } 
.right-drawer-wrapper .comments-scroll-content .comments-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert { width: 100%; text-align: center; position: relative; padding: 15px 20px; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert p { background: #fff; display: inline; padding: 0 15px; font-weight: 500; color: #818181; } 
.right-drawer-wrapper .comments-scroll-content .comments-alert::before { content: ""; width: calc(100% - 40px); border-top: 1px solid #D6D6D6; position: absolute; left: 20px; top: 50%; z-index: -1; } 
.right-drawer-wrapper .comments-scroll-content .comment { display: flex; padding: 15px 20px; position: relative; } 
.right-drawer-wrapper .comments-scroll-content .comment .avatar { margin: 0; width: 50px; } 
.right-drawer-wrapper .comments-scroll-content .comment .message { width: calc(100% - 50px); } 
.right-drawer-wrapper .comments-scroll-content .comment .message p.name { color: #333333; font-weight: 600; line-height: 32px; width: 100%; display: block; padding-right: 70px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; } 
.right-drawer-wrapper .comments-scroll-content .comment .message p.text { color: #707070; font-size: 1em; margin-top: 5px; line-height: 1.4; word-wrap: break-word; } 
.right-drawer-wrapper .comments-scroll-content .comment .timestamp { font-size: 0.8em; color: #7E7E7E; text-align: right; white-space: nowrap; line-height: 30px; position: absolute; right: 20px; } 
.right-drawer-wrapper .comments-scroll-content .empty-state { padding-bottom: 100px; } 

/* Avatar */
.avatar { margin: 0 5px; } 
.avatar .circle { color: #fff; background: #3665AB; height: 32px; width: 32px; line-height: 2.2; border-radius: 50%; border: 1px solid #3665AB; display: flex; align-items: center; justify-content: center; font-size: 0.75em; position: relative; } 
.avatar .circle.checked { height: 24px; width: 24px; background: #C5E7CA; border-color: #2AB930; color: #000; } 
.avatar .circle.checked::after { content: '\e801'; font-family: "cobuy"; color: #fff; font-style: normal; font-weight: normal; background: #2AB930; width: 10px; height: 10px; bottom: -2px; right: -3px; display: flex; align-items: center; justify-content: center; border-radius: 50%; position: absolute; font-size: 5px; padding-top: 1px; } 
.avatar .circle.unchecked { height: 24px; width: 24px; color: #7E7E7E; background:#fff; border-color: #7E7E7E; } 

/* Banner */
.banner { display: flex; background: #E6EAF1; border-radius: 16px; font-size: 1em; padding: 16px; line-height: 1.5; align-items: flex-start; margin-bottom: 10px; margin-top:10px } 
.banner.warning { background: #FEF4E4 } 
.banner.success { background: #EAF8EA } 
.banner img { width: 35px; margin-right: 10px; } 
.banner p { width: 100%; padding-left: 35px; position: relative; margin-left: 0; word-wrap: break-word; } 
.banner img + p { padding-left: 0; width: calc(100% - 45px); } 
.banner p.icon:before { position: absolute; left: 0; font-size: 25px; color: #37465A; margin: -1px 0 0 0; } 
.banner.success p.icon:before { color: #2AB930; } 
.banner.warning p.icon:before { color: #F5BD4F; } 

/* Button Group Container */
.button-group-container { display: flex; justify-content: center; align-items: center; font-size: 12px; padding: 0 0 20px; } 
.button-group-container.disabled button { background: #7E7E7E; pointer-events: none; } 
.button-group-container.disabled button.empty-button-group { color: #7E7E7E; border: 1px #7E7E7E solid; } 
.button-group-container button { display: block; width: 100px; height: 33px; border: none; font-family: "Inter", sans-serif; border-radius: 5px; background: #37465A; color: #FFF; font-size: 1.2em; cursor: pointer; margin: 5px; transition: all .3s; } 
.button-group-container button.empty-button-group { background: transparent; color: #7E7E7E; border: 1px #7E7E7E solid; } 

/* Text Area Container */
/* Text Input Container */
.text-area-container,
.text-input-container,
.number-input-container,
.date-input-container { margin-bottom: 25px; } 
 .text-area-container.disabled textarea,
 .text-input-container.disabled input,
 .number-input-container.disabled input,
 .date-input-container.disabled input { border: 1px solid #e8e8e8; background-color: #F9F9F9; color: #8b8b8b; } 
 .text-area-container.disabled textarea::placeholder,
 .text-input-container.disabled input::placeholder,
 .number-input-container.disabled input::placeholder,
 .date-input-container.disabled input::placeholder { opacity: .5; } 

.text-area-container label,
.text-input-container label,
.number-input-container label,
.date-input-container label { position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.error textarea { border-color: #D84242 !important; } 

/* Chat Input Container */
.chat-input-container { margin: 0; position: relative; } 
.chat-input-container .icon-send { color: #D6D6D6; font-size: 20px; display: flex; justify-content: center; align-items: center; border-left: 1px solid #D6D6D6; position: absolute; bottom: 10px; right: 0; width: 40px; height: calc(100% - 20px); padding: 0 3px 0 0; cursor: pointer; } 
.chat-input-container .icon-send:before { position: absolute; bottom: 4px; } 
.chat-input-container textarea { overflow: auto; min-height: 50px; max-height: 180px; border-color: #D6D6D6; padding: 15px 50px 15px 15px; resize: none; } 
.chat-input-container textarea::-webkit-scrollbar { width: 0; height: 0; } 
.chat-input-container textarea::-webkit-scrollbar-track { background-color: transparent; } 
.chat-input-container textarea::-webkit-scrollbar-thumb { background-color: transparent; } 
.chat-input-container textarea::-webkit-scrollbar-thumb:hover { background-color: transparent; } 
.chat-input-container textarea:focus { border-color: #000000; } 
.chat-input-container textarea:focus + .icon-send { color: #000000; border-color: #000000; } 
.chat-input-container textarea:focus + .icon-send.disabled { color: #D6D6D6; } 

/* Switch */
.switch { margin: 0 0 25px } 
 .switch.disabled .switch-options input[type="checkbox"]:checked + .switch-span .left-option,
 .switch.disabled .switch-options input[type="checkbox"] + .switch-span .right-option { background-color: #7E7E7E; color: #fff; z-index: 1; } 
 .switch.disabled .switch-options input[type="checkbox"]:checked + .switch-span .right-option,
 .switch.disabled .switch-options input[type="checkbox"] + .switch-span .left-option { background-color: #EEECEC; color: #6b6b6b; } 
 .switch.disabled .switch-label { opacity: 0.6; } 
.switch .switch-label { float: none; cursor: auto; position: relative; z-index: 20; font-size: 1em; font-weight: 500; padding-bottom: 7px; display: block; } 
.switch .switch-options { position: relative; background: #EEECEC; width: 100%; height: 35px; border-radius: 8px; } 
.switch .switch-options .switch-span .left-option,
.switch .switch-options .switch-span .right-option { pointer-events: none; position: absolute; top: 0; right: 0; height: 100%; width: 50%; transition: all .3s; border-radius: 8px; display: flex; justify-content: center; align-items: center; } 
.switch .switch-options .switch-span .left-option { left: 0; background-color: #EEECEC; color: #333333; } 
.switch .switch-options .switch-span .right-option { background-color: #37465A; color: #fff; } 
.switch .switch-options input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 50%; height: 100%; } 
.switch .switch-options input[type="checkbox"]:checked { left: 50%; } 
.switch .switch-options input[type="checkbox"]:checked + .switch-span .left-option { background-color: #37465A; color: #fff; z-index: 1; } 
.switch .switch-options input[type="checkbox"]:checked + .switch-span .right-option { background-color: #EEECEC; color: #333333; } 

/* Switch Simple */
.simple-switch { margin: 20px 0; } 
 .simple-switch.disabled { opacity: 0.7; } 
.simple-switch-options { position: relative; width: 100%; height: 35px; border-radius: 8px; } 
.simple-switch-options::before { content: "/"; position: absolute; left: calc(50% - 5px); top: 9px; } 
.simple-switch-options .simple-switch-span .left-option,
.simple-switch-options .simple-switch-span .right-option { pointer-events: none; position: absolute; top: 0; right: 0; height: 100%; width: 50%; transition: all .3s; border-radius: 8px; display: flex; justify-content: center; align-items: center; } 
.simple-switch-options .simple-switch-span .left-option { left: 0; color: #9C9C9C; } 
.simple-switch-options .simple-switch-span .right-option { color: #333333; font-weight: 600; } 
.simple-switch-options input[type="checkbox"] { opacity: 0; position: absolute; left: 0; top: 0; margin: 0; z-index: 1000; cursor: pointer; width: 50%; height: 100%; } 
.simple-switch-options input[type="checkbox"]:checked { left: 50%; } 
.simple-switch-options input[type="checkbox"]:checked + .simple-switch-span .left-option { color: #333333; z-index: 1; font-weight: 600; } 
.simple-switch-options input[type="checkbox"]:checked + .simple-switch-span .right-option { color: #9C9C9C; } 

/* Slider */
.slider { margin: 50px 0 25px 0; position: relative; } 
.slider .slider-value { font-size: 2em; width: 100%; text-align: center; font-weight: 600; padding-bottom: 20px; border: none } 
.slider.disabled .slider-value { opacity: 0.7; } 
input[type="range"] { padding: 20px 0 0 0; } 
.progress { height: 10px; width: 50px; background: #3665AB; display: block; position: absolute; bottom: 0; border-radius: 10px; pointer-events: none; padding: 0 5px; } 
.progress::after { content: ""; width: 20px; height: 20px; border-radius: 12px; background: #fff; border: #7E7E7E 1px solid; position: absolute; z-index: 99; right: -10px; top: -5px; pointer-events: none; } 
.disabled .progress { background: #D6D6D6; } 

/* Range */
input[type="range"] { -webkit-appearance: none; -webkit-tap-highlight-color: rgba(255, 255, 255, 0); width: 100%; height: 10px; margin: 0; border: none; padding: 1px 2px; border-radius: 14px; background: #EEECEC; outline: none; z-index: -1; } 
input[type="range"]::-webkit-slider-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 
input[type="range"]::-moz-range-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 
input[type="range"]::-ms-thumb { -webkit-appearance: none; width: 20px; height: 20px; border: none; border-radius: 12px; background: transparent; padding: 5px; box-sizing: inherit; position: relative; z-index: 9; cursor: pointer; } 

/* Badge */ 
.badge { position: absolute; right: 0; top: 15px; background: #F9DDA6; border-radius: 20px; padding: 4px 8px; color: #333; font-size: .9em; } 
.badge.success { background: #C3EBBC; } 
.badge.fail { background: #FFA8A8; } 
.badge.warning { background: #FADEA7; } 
.badge.error { background: #FFA8A8; } 

/* Notification badge */ 
.notification-badge { height: 8px; width: 8px; border-radius: 50%; background: #D84242; position: absolute; top: 2px; left: 18px; border: 1px solid #fff; z-index: 3; } 
.notification-badge.small-icon { left: 16px; } 

/* Empty state */ 
.empty-state { min-height: 250px; color: #7E7E7E; text-align: center; position: relative; width: 100%; height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; background: #fff; } 
.empty-state span { font-size: 1.2em; font-weight: 600; } 
.empty-state p { font-size: 1em; margin-top: 15px; max-width: 230px; } 
.empty-state i { font-size: 48px; margin-bottom: 20px; } 

/* Desktop nav */
.desktop-nav { display: flex; justify-content: space-between; align-items: center; } 
.desktop-nav h2 { font-size: 1.2em; letter-spacing: 0px; padding: 10px 10px 10px 0; cursor: pointer; font-weight: 500; } 
.desktop-nav h2.icon-back::before { margin-right: 12px; } 
.desktop-nav .icon-text-button { padding: 10px; margin: 0 } 
