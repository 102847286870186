/* Login */
.login-wrapper { height: 100%; padding: 20px; min-height: unset; } 
.login-wrapper .logo { text-align: center; margin: 30px auto; width: 70px; } 
.login-wrapper .text-center { margin-bottom: 50px; } 
.login-wrapper .or-separator { text-align: center; margin: 25px 0; color: #ABABAB; position: relative; width: 100%; } 
.login-wrapper .or-separator:before { content: ""; display: block; height: 1px; position: absolute; width: 100%; background: #E6E6E6; top: 9px; } 
.login-wrapper .or-separator span { display: inline-block; background: #FFF; z-index: 10; position: relative; padding: 0 8px; } 
.login-wrapper form label { display: block; margin-bottom: 10px; } 
.login-wrapper .dont-have-account { text-align: center; font-size: .9em; padding: 30px 0; } 
.login-wrapper .dont-have-account a { font-weight: bold; } 
.login-wrapper .legal-footer { font-size: .9em; color: #7E7E7E; text-align: center; padding: 10px 0 30px; line-height: 1.4; } 
.login-wrapper .legal-footer.login a { color: #7E7E7E; padding: 0px 10px; } 
.login-wrapper .legal-footer a { color: #7E7E7E } 
 .login-wrapper .legal-footer a:hover,
 .login-wrapper .legal-footer a:focus { color: #37465A } 
.login-wrapper .email-input { margin-bottom: 50px; } 
.login-wrapper .button-center button.hide { opacity: 0; visibility: hidden; } 
.login-wrapper .text-input-container { margin-bottom: 0px; } 
.login-wrapper .text-input-container label { padding-bottom: 0px; } 

/* Steps Questions */
.step-question-wrapper { display: flex; justify-content: center; width: 100%; } 
.step-question { padding: 0 20px 20px; width: 100%; max-width: 410px; } 
.step-question h4 { font-weight: 600; text-align: center; padding: 30px 0 0 0; } 
.step-question h4 i { font-size: .70em; color: #7E7E7E; margin-left: 5px; position: relative; top: -1px; } 
.step-question h4.icon-back.disabled { opacity: 0.6; } 
.step-question p { color: #7E7E7E; margin-top: 20px; text-align: left; } 
.step-question p.loading-text { color: #37465A; text-align: center; margin-top: 25px; margin-bottom: -20px; font-size: 0.9em; } 
.step-question p.address-warning { margin: 30px 0 25px 0; color: #333333; } 
.step-question form { margin-top: 40px; } 
.step-question form p { margin-bottom: 20px; color: #333333; } 
.step-question form label { color: #333333; } 
.step-question .checkbox-button-group.last-field .checkbox-button { margin-bottom: 25px; } 
.step-question .invalid-form { color: #D84242 !important; margin: 0px 0px 15px 0px; text-align: left!important; } 
.step-question .icon-text-button { margin-top: -15px } 

/* Take ID Photo */
.id-photo .bottom-fixed button { margin-bottom: 10px; } 

/* Invite Co-Owners step */
.step-question.invite-co-owners { margin-top: 10px; } 

/* Invite Co-Owners */
.invite-co-owners .co-owner { text-align: left; margin-top: 40px; } 
.invite-co-owners .co-owner:first-child { margin-top: 0; } 
.invite-co-owners .co-owner h5 { font-size: 1em; font-weight: 600; margin-bottom: 20px; } 
.invite-co-owners .co-owner .header { display: flex; justify-content: space-between; } 
.invite-co-owners .co-owner .header .simple-button { line-height: 1; height: 18px; } 

/* One Last Thing Page */
.confirmation-check { margin-top: 40px; } 

/* Fail Step */
.fail-step { text-align: center; height: 100%; width: 100%; max-width: 400px; padding: 80px 20px 20px; } 
.fail-step h3 { font-weight: 600; padding: 5px 0 20px; } 
.fail-step p { text-align: left; color: #7E7E7E; } 
.fail-step .icon-cross-circled { font-size: 3em; color: #D84242; padding-bottom: 10px; display: block; } 

/* Success Step */
.success-step { text-align: center; } 
.success-step.centered-box { font-size: 1em; } 
.success-step h3 { font-weight: 600; padding: 5px 0 20px; } 
.success-step p { text-align: left; color: #7E7E7E; } 
 .success-step p.center { text-align: center; } 
.success-step i { font-size: 3em; color: #37465A; } 
.success-step i.icon-cross-circled { color: #D84242; } 

/* ID Photo */
.id-photo { text-align: center; height: 100vh; } 
.id-photo h3 { font-weight: 600; padding: 5px 0 20px; } 
.id-photo p { text-align: center; color: #7E7E7E; } 
.id-photo i { font-size: 3em; color: #000; } 

/* Take Photo */
.take-photo { text-align: center; height: 100vh; } 
.take-photo h3 { font-weight: 600; padding: 5px 0 20px; } 
.take-photo p { text-align: center; color: #7E7E7E; } 
.take-photo i { font-size: 3em; color: #000; } 

/* Check Email */
.check-email { text-align: center; height: 100%; } 
.check-email h3 { font-weight: 600; padding: 5px 0 20px; } 
.check-email p { text-align: left; color: #7E7E7E; margin-bottom: 15px; line-height: 1.4; } 
.check-email .icon-email-circled,
.check-email .icon-phone-circled { font-size: 3em; color: #37465A; } 
.check-email p strong { font-weight: 600; color: #000; } 
.check-email .tertiary-button { font-weight: 500; } 
.check-email .incorrect-code { opacity: 0; color: #D84242; font-weight: 400; text-align: center; margin-bottom: 0; margin-top: 16px; position: static; width: calc(100% - 50px); } 
.check-email .incorrect-code.show { opacity: 1; } 
.resend-button { padding-top: 30px; width: 100% } 

/* Ownership Group */
.ownership-group { height: auto; padding: 40px 20px 20px; display: block; min-height: unset; } 
.ownership-group .icon-users-circled { color: #37465A; } 
.ownership-group .small-title { font-size: 1.2em; display: block; text-align: left; font-weight: 600; padding: 20px 0; } 
.ownership-group .member-item { position: relative; padding: 20px 0; border-bottom: 1px solid #E6E6E6; } 
.ownership-group .member-item .header-container { display: flex; position: relative; justify-content: space-between; align-items: baseline; position: relative; } 
.ownership-group .member-item .header-container .name { color: #333; margin-bottom: 10px; word-break: break-word; padding-right: 10px } 
.ownership-group .member-item .header-container .name span { color: #7E7E7E; } 
.ownership-group .member-item .header-container .badge { position: static; white-space: nowrap; margin: 0 0 0 auto; } 
.ownership-group .member-item .email { color: #7E7E7E; word-break: break-word; } 
.ownership-group .member-item .actions { display: flex; justify-content: space-between; margin-top: 20px; } 
.ownership-group .member-item .actions button:only-child { margin-left: auto; } 
.ownership-group .bottom-fixed { padding: 20px 0; } 
.ownership-group .icon-text-button { margin-top: 10px } 

/* Iframe Container */
.terms-and-conditions { width: 100%; height: calc(100vh - 45px); padding: 0; } 
.terms-and-conditions .top-content { height: 50px; position: relative; } 
.terms-and-conditions .top-content .logo { position: relative; width: 70px; left: 50%; margin-left: -35px; top: 5px; } 
.terms-and-conditions .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; z-index: 99; } 
.terms-and-conditions iframe { width: 100%; height: calc(100% - 5px); } 
.terms-and-conditions iframe html div { background: #FFF; } 

/* Sections Index */
.sections-index-wrapper { display: flex; justify-content: center; width: 100%; } 
.sections-index { margin-bottom: 60px; min-height: 450px; padding: 20px 25px; position: relative; width: 100%; max-width: 370px; display: flex; flex-direction: column; justify-content: center; align-items: flex-start; height: calc(100vh - 60px); } 
.sections-index .section { padding-left: 30px; position: relative; min-height: 56px; } 
 .sections-index .section.active { margin-top: 5px; } 
 .sections-index .section.active .sections-index-title i { color: #37465A; font-size: 1.7em; } 
 .sections-index .section.active .sections-index-title .title { color: #37465A; margin-top: -5px; font-weight: 600; font-size: 1.4em; position: relative; top: -3px; } 
 .sections-index .section.active p { display: block; color: #7E7E7E; font-weight: normal; font-size: 0.85em; padding: 5px 0 25px 0; } 
 .sections-index .section.active .check { border-color: #37465A; } 
 .sections-index .section.active .check::after { content: ""; display: block; color: #FFF; position: absolute; left: 0; top: 0; right: 0; bottom: 0; margin: auto; width: 12px; height: 12px; background: #37465A; border-radius: 100%; font-size: 1em; transition: all .3s; } 

.sections-index .section::before { content: ""; height: calc(100% - 30px); border-left: #d2d2d2 solid 2px; position: absolute; top: 25px; left: 9px; } 
.sections-index .section:nth-child(7)::before { display: none; } 
.sections-index .section .check { background: #FFF; margin-right: 5px; border: 2px solid #717171; display: inline-block; width: 20px; height: 20px; position: absolute; left: 0; border-radius: 100%; transition: all .3s; } 
.sections-index .section .sections-index-title { position: relative; padding-left: 40px; } 
.sections-index .section .sections-index-title i { font-size: 1.7em; color: #7E7E7E; margin-right: 3px; position: absolute; top: -3px; left: 0; } 
.sections-index .section .sections-index-title .title { font-size: 1.2em; line-height: 1.4; color: #7E7E7E; font-weight: 400; top: 0; } 
.sections-index .section p { display: none; } 

/* Main Header */
.main-header { width: 100%; z-index: 999; position: relative; padding: 0 20px; } 
 .main-header.dark { background: #2E405C; color: #fff !important; } 
 .main-header.dark i::before { color: #fff !important; } 
 .main-header.dark .exit-button { color: #fff !important; } 
 .main-header.hide-line::after { display: none; } 
.main-header::after { content: ""; width: calc(100% + 40px); border-bottom: 2px solid #e5e5e5; display: block; position: relative; z-index: 0; left: -20px; } 
.main-header.dark::after { border-bottom: 1px solid #FFFFFF; opacity: 0.1; } 
.main-header .header-information { width: calc(100% + 40px); left: -20px; position: relative; } 
.main-header .header-top { height: 50px; position: relative; } 
.main-header .header-bottom { height: 50px; position: relative; } 
.main-header .header-top .header-top-left,
.main-header .header-bottom .header-bottom-left { position: absolute; left: 0px; height: 100%; } 
.main-header .header-bottom .exit-button { font-size: 1.1em; border: none; background: transparent; color: #37465A; font-weight: 500; padding: 0; position: relative; z-index: 99; cursor: pointer; } 
.main-header .header-bottom .exit-button.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-top .header-top-left.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-top .header-top-center { display: flex; justify-content: center; height: 100%; } 
.main-header .header-top .header-top-center h1 { font-size: 1em; font-weight: 500; letter-spacing: 0px; text-align: center; position: absolute; left: 0; width: 100%; line-height: 50px; } 
.main-header .logo { height: 100%; width: 100%; display: flex; justify-content: center; align-items:center; } 
.main-header .logo i { font-size: 38px; color: #2E405C; } 
.main-header .header-bottom .header-bottom-left { position: absolute; left: 0; } 
.main-header .header-bottom .header-bottom-center h1 { font-size: 1em; font-weight: 500; letter-spacing: 0px; text-align: center; position: absolute; left: 0; width: 100%; line-height: 50px; } 
.main-header .header-bottom .header-bottom-right,
.main-header .header-top .header-top-right { position: absolute; right: 0; top: 0; display: flex; align-items: center; height: 100%; } 
.main-header button { font-size: 1.4em; background: transparent; border: none; padding: 0; transition: all .3s; cursor: pointer; width: 30px; height: 100%; display: flex; justify-content: center; align-items: center; } 
.main-header .back-button { font-size: 1.1em; } 
.main-header .header-bottom .header-bottom-right .avatars-container { display: flex; border-right: 1px solid #D6D6D6; padding-right: 10px; margin-right: 5px; } 
.main-header .header-bottom .header-bottom-right button { width: 40px; } 
.main-header .header-bottom .header-bottom-right button:last-child { width: 30px; margin-left: 5px; } 
.main-header .header-bottom .vertical-divider { height: 22px; border-right: 1px solid #00000036; margin: 0 15px 0 10px; } 
.main-header .header-bottom .popover-guide { position: relative; margin: 0 0px; } 
.main-header .header-bottom .popover-guide p { font-size: .85em; } 
 .main-header .header-bottom .popover-guide.open { z-index: 9999; pointer-events: auto; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-background { width: 100vw; height: 100vh; background-color: #00000061; position: fixed; top: 0; left: 0; z-index: 0; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-content { opacity: 1; pointer-events: auto; } 
 .main-header .header-bottom .popover-guide.open .popover-guide-content::before { content: ""; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-bottom: 10px solid #fff; position: absolute; bottom: calc(100% - 2px); right: 20px; } 
 .main-header .header-bottom .popover-guide.open .icon-button { pointer-events: none; } 
 .main-header .header-bottom .popover-guide .icon-button::before { transition: all .2s; background: transparent; display: block; } 
 .main-header .header-bottom .popover-guide.open .icon-button::before { content: ""; width: 40px; height: 40px; top: -5px; left: -5px; background: #fff; border-radius: 50%; z-index: 1; display: block; position: absolute; margin: 0; } 

.main-header .header-bottom .popover-guide .popover-guide-content { pointer-events: none; opacity: 0; transition: all .3s; background: #fff; position: absolute; right: -15px; top: calc(100% + 25px); z-index: 1; width: 288px; padding: 20px; border-radius: 8px; } 
.main-header .header-bottom .popover-guide .button-center button { font-size: 1em; background-color: #37465A; height: 40px; padding: 0; display: flex; justify-content: center; align-items: center; width: 100%; margin-top: 15px; } 
.main-header .header-bottom .popover-guide .button-center button:hover { background: #3a547a; } 
.main-header .header-bottom .popover-guide .icon-button { background: transparent; border: none; padding: 0; width: auto; transition: all .3s; cursor: pointer; width: 30px; height: 30px; display: flex; justify-content: center; align-items: center; } 
.main-header .header-bottom .popover-guide .icon-button:disabled,
.main-header .header-bottom .popover-guide .icon-button[disabled] { cursor: auto; } 
.main-header .header-bottom .popover-guide .icon-button i { position: relative; z-index: 2; color: #333333; } 
 .main-header .header-bottom .popover-guide .icon-button i.disabled { opacity: 0.2; pointer-events: none; cursor: auto; } 
.main-header .header-bottom .icon-button.comments-button { color: #333333; height: 30px; width: 30px; display: flex; justify-content: center; align-items: center; } 
.main-header .header-bottom .icon-button.edit-button { color: #D6D6D6; } 
.main-header .header-bottom .icon-button.add-document-button { font-size: 1.2em; } 
 .main-header .header-bottom .popover-guide .icon-button:focus i::after,
 .main-header .header-bottom .popover-guide .icon-button:hover i::after { content: ""; width: 50px; height: 50px; top: -10px; left: -10px; border-radius: 50%; z-index: 1; display: block; position: absolute; margin: 0; background: #3665AB2E; z-index: 9; width: 30px; height: 30px; top: -4px; left: -1px; } 
.main-header .header-bottom .popover-guide .icon-button:focus i.disabled::after,
.main-header .header-bottom .popover-guide .icon-button:hover i.disabled::after { display: none; } 

.edition-bar { background: #688949; color: #fff; height: 24px; width: 100%; opacity: 0; z-index: 2; transition: all .3s; overflow: hidden; max-height: 0; position: relative; z-index: 9; top: -2px; } 
.edition-bar.show { opacity: 1; max-height: 60px; margin-bottom: -2px } 
.edition-bar p { color: #fff; line-height: 24px; font-size: .9em; text-align: center; } 

/* Co-ownership agreement */
.agreement-status { padding: 20px; width: 100%; max-width: 1063px; min-height: calc(100vh - 52px); } 
.agreement-status.sections .button-center { max-width: 375px; margin: auto; } 
.agreement-status .filter-status { margin-left: -20px; padding-left: 20px; padding-right: 15px; display: flex; width: calc(100% + 40px); overflow-x: auto; justify-content: flex-start; align-items: flex-start; padding-bottom: 5px; -ms-overflow-style: none; scrollbar-width: none; overflow-y: scroll; } 
.agreement-status .filter-status::-webkit-scrollbar { display: none; } 
.agreement-status .filter-status li { transition: all .2s; cursor: pointer; border: 1px solid #37465A; color: #37465A; font-size: 1em; padding: 7px 17px; border-radius: 8px; margin-right: 5px; transition: all .3s; white-space: nowrap; } 
 .agreement-status .filter-status li.selected { background: #37465A; color: #FFF; } 
 .agreement-status .filter-status li:focus,
 .agreement-status .filter-status li:hover { background: #37465A; color: #FFF; } 
.agreement-status .empty-state { background: inherit; height: calc(100% - 80px); } 
.gray-alert { background: #EEECEC; border-radius: 4px; margin: 0 5px; padding: 15px; color: #7E7E7E; font-style: italic; font-size: 1em; } 
.agreement-status-card { box-shadow: 0px 2px 10px 0px #d8d8d8; position: relative; top: 0; border-radius: 8px; margin: 15px 0; padding: 20px 10px; display: flex; transition: all .3s; cursor: pointer; background: #FFFFFF; } 
.agreement-status-card:focus,
.agreement-status-card:hover { box-shadow: 0px 2px 15px 0px #cfcfcf; top: -2px; } 
.agreement-status-card .main-icon { font-size: 30px; color: #7E7E7E; width: 55px; } 
 .agreement-status-card .main-icon.icon-check-circled-bold { color: #2AB930; opacity: 1; } 
 .agreement-status-card .main-icon.icon-alert { color: #F5BD4F; opacity: 1; } 
 .agreement-status-card .main-icon.icon-coowners { color: #D6D6D6; opacity: 1; } 
.agreement-status-card .information-container { width: calc(100% - 120px); } 
.agreement-status-card .information-container .comments-button { cursor: pointer; background: transparent; display: flex; border: 0; position: absolute; bottom: 15px; right: 20px; } 
.agreement-status-card .information-container .comments-button p { padding-top: 12px; } 
.agreement-status-card .information-container .comments-button i { font-size: 1.6em; position: relative; top: 3px; height: 30px; width: 30px; } 
.agreement-status-card .information-container .title { font-size: 1.1em; font-weight: 600; } 
.agreement-status-card.disabled * { opacity: 0.5; } 
 .status-container { display: flex; align-items: center; margin-top: 7px; } 
 .status-indicator { display: block; width: 10px; height: 10px; border-radius: 50%; background: #D6D6D6; margin-right: 5px; position: relative; top: -1px; } 
 .status-indicator.success { background: #2AB930; } 
 .status-indicator.disabled { background: #D6D6D6; } 
 .status-indicator.in-progress { background: #FFF; border: 2px solid #2AB930; } 
 .status-indicator.warning { background: #F5BD4F; } 
 .status-indicator.error { background: #D84242; } 
 .agreement-status-card.disabled .status-indicator { background: #D6D6D6; } 
.agreement-status-card .icon-arrow-right { display: flex; justify-content: center; align-items: center; margin-left: auto; font-size: 1.1em; padding-right: 5px; } 
.agreement-status-card .information-container p { font-size: 0.85em; display: flex; line-height: 10px; width: calc(100% - 15px); line-height: 1.2; } 

/* VouchedId */
.vouched-element { width: 100%; height: 100%; overflow: auto; }
#vouched-element button { border-radius: 5px!important; height: 56px!important; position: relative; } 
#vouched-element button:nth-child(2) { margin-bottom: 20px!important; color: #37465A!important; background: #FFF!important; } 
#vouched-element button:focus,
#vouched-element button:active,
#vouched-element button:hover { background: #3a547a!important; outline-width:0!important; } 
#vouched-element button span { font-weight: 400!important; font-size: 15px!important; } 
#vouchedUploadButton { display: none!important; } 
#vouchedIDDirections { padding: 0 20px 0 } 
#idScreenButtons { bottom: 0; width: 100%; padding: 0 10px 0; } 
#idScreenButtons div:nth-child(2) button { background-color: #FFFFFF; color: #37465A!important; } 
#idScreenButtons div:nth-child(2) button:focus,
#idScreenButtons div:nth-child(2) button:hover,
#idScreenButtons div:nth-child(2) button:active { background: transparent!important; border:none!important; outline-width:0!important; } 
#idScreenButtons div:nth-child(2) button:focus span,
#idScreenButtons div:nth-child(2) button:hover span,
#idScreenButtons div:nth-child(2) button:active span { color: #3665AB!important; } 
#idScreenButtons div:nth-child(2) button span { font-weight: 500!important; color: #37465A!important; } 
.navigation svg g svg { display: none; } 
.navigation text { font-family: "Inter", sans-serif!important; } 
.navigation text.vouched_navTitles { font-size: 1em!important; } 
#vouched-element .vouched .handoff-title { font-family: "Inter", sans-serif!important; font-weight: 600; font-size: 2em!important; line-height: 40px; color: #333333; margin-bottom:15px; } 
#vouched-element .vouched .vouched-description { max-width: 410px; padding: 10px 20px!important; } 
#vouched-element .vouched .vouched-description div { font-family: "Inter", sans-serif!important; font-weight: 400; font-size: 1em; line-height: 22px; color: #7E7E7E; max-width: 410px; } 
#vouched-element .vouched span { font-size: 0.8em; font-family: "Inter", sans-serif!important; } 
#vouched-element .vouched span b { font-family: "Inter", sans-serif!important; } 
.verbose-step-content .vouched_idText { font-family: "Inter", sans-serif!important; } 
.verbose-step-content #vouchedIDDirections b { font-family: "Inter", sans-serif!important; } 
.vouched_lowerFaceInstructions .vouched_faceText { font-family: "Inter", sans-serif!important; text-align: left; margin-top: 10px; } 
.vouched_lowestFaceInstructions .vouched_faceText { font-family: "Inter", sans-serif!important; list-style: circle; } 
.vouched_faceText .vouched_faceText { font-family: "Inter", sans-serif!important; } 
.verbose-footer-actions { margin-bottom: 10px; } 
#vouched-element .vouched .progress-indicator .progress-content div { font-family: "Inter", sans-serif!important; font-weight: 500; } 

/* Co-owners */
.co-owners-wrapper { width: 100%; height: 100%; } 
.co-owners { font-size: 1em; width: 100%; padding: 20px; } 
.co-owners .desktop-header { display: flex; padding-bottom: 20px; } 
.co-owners h2 { font-size: 1.5em; font-weight: 600; letter-spacing: 0.1px; } 
.co-owners ul { width: 100%; padding-bottom: 30px; } 
.co-owners ul li { min-height: 52px; display: flex; align-items: center; border-bottom: 1px solid #E5E5E5; position: relative; font-size: 1em; cursor: pointer; padding: 8px 20px 8px 5px; } 
.co-owners ul li:focus,
.co-owners ul li:hover { background: #EBECEF; } 
 .co-owners ul li:last-child { border-bottom: 0; } 
.co-owners ul li .icon-arrow-right { position: absolute; right: 0; } 
.co-owners ul li .badge { top: auto; right: 30px; margin: 0 0 0 auto; white-space: nowrap; position: static; } 
.co-owners ul li p span { color: #7E7E7E; } 
.co-owners > p, .co-owners-status > p { color: #7E7E7E; font-style: italic; font-size: 0.86em; line-height: 1.25em; } 
.co-owners-status { font-size: 1em; width: 100%; padding: 20px; } 
.co-owners-status .user { padding: 20px 0 20px 50px; position: relative; } 
.co-owners-status .user p { font-size: 0.9em; } 
.co-owners-status .user p.email { font-size: 0.9em; color: #9a9a9a; margin-top: 2px; word-break: break-word; } 
.co-owners-status .user .avatar { position: absolute; left: 0; } 
.co-owners-status .progress-item-list { padding: 0 0 15px 20px; } 
.co-owners-status .progress-item { display: flex; align-items: center; padding: 15px 0; position: relative; } 
 .co-owners-status .progress-item.success i { color: #2AB930; border: #2AB930 2px solid; } 
 .co-owners-status .progress-item.warning i { color: #F5BD4F; border: none; background: #FFF; } 
 .co-owners-status .progress-item.warning i::before { content: '\e827'; font-size: 24px; position: absolute; left: -6px; top: 1px; } 
 .co-owners-status .progress-item.warning i::after { top: 22px; left: 10px; border-left: #D6D6D6 2px dotted; } 
 .co-owners-status .progress-item.disabled i { color: #FFF; border: #D6D6D6 2px dotted; } 
 .co-owners-status .progress-item.disabled i::after { border-left: #D6D6D6 2px dotted; } 
.co-owners-status .progress-item:last-child i::after { display: none; } 
.co-owners-status .progress-item p { font-size: 1em; color: #7E7E7E; } 
.co-owners-status .progress-item i { color: #2AB930; border-radius: 50%; border: #2AB930 2px solid; width: 22px; height: 22px; font-size: 10px; display: flex; justify-content: center; align-items: center; margin-right: 15px; position: relative; } 
.co-owners-status .progress-item i::after { content: ""; display: block; height: 30px; border-left: #2AB930 1px solid; position: absolute; left: 8px; top: 20px; } 
.co-owners-status .banner .inline-button { color: #3764AB; font-weight: 500; } 

/* Review and sign your Co-ownership Agreement */
.review-and-sign { padding: 0 20px 160px 20px; width: 100%; font-size: 1em; height: 100%; overflow: auto } 
.review-and-sign.extra-padding { padding-bottom:240px } 
.review-and-sign h1 { font-size: 1.7em; text-align: center; font-weight: 600; } 
.review-and-sign .avatars-container { display: flex; justify-content: center; margin: 20px 0; } 
.review-and-sign p { font-size: 1em; color: #707070; } 
.review-and-sign .bottom-fixed::before { pointer-events: none; content: ""; width: 100%; height: 80px; background-image: linear-gradient(transparent, #FFF); display: block; margin-top: -10px; position: absolute; top:-70px; right: 0; } 
.review-and-sign .bottom-fixed { width: calc(100% - 20px); left: 10px; padding: 20px 10px; pointer-events: none; } 
.review-and-sign .bottom-fixed button { pointer-events: auto; } 

.height-limit-scroll { padding: 35px 0 0; max-width: 880px; margin: auto; } 

.bottom-fixed .button-center button { margin-top: 0; } 
.bottom-fixed .button-center button.secondary-button { margin-top: 10px; } 
.bottom-fixed .button-center:nth-child(2) button { margin-top: 10px; } 

.agreement-document { word-break: break-word; } 
.agreement-document h2 { padding-bottom: 20px; padding-top: 20px; font-weight: 600; font-size: 1.4em; line-height: 24px; color: #333333; } 
.agreement-document h3 { padding-top: 20px; padding-bottom: 15px; font-weight: 600; font-size: 1.2em; line-height: 20px; color: #333333; } 
.agreement-document p { padding-bottom: 15px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ul { margin-bottom: 15px; list-style-type: disc; padding: revert; padding-left: 20px } 
.agreement-document ol { margin-bottom: 15px; list-style-type: decimal; padding: revert; padding-left: 20px } 
.agreement-document ol.lower-alpha { list-style-type: lower-alpha; } 
.agreement-document ol.upper-alpha { list-style-type: upper-alpha; } 
.agreement-document ul li { margin-bottom: 10px; margin-left: 10px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ol li { margin-bottom: 10px; margin-left: 10px; font-weight: 400; font-size: 1em; line-height: 20px; color: #707070; } 
.agreement-document ol li ul { padding-top: 10px; } 
.agreement-document ol li ol { padding-top: 10px; } 
.agreement-document ul li ul { padding-top: 10px; } 
.agreement-document ul li ol { padding-top: 10px; } 
.agreement-document .signature-wrapper { position: relative; } 
.agreement-document .signature-wrapper label { position: absolute; top: -8px; left: 65px; font-size: 0.65em; font-weight: 600; color: #37465A; } 
.agreement-document .signature-wrapper label span { color: #688949; } 
.agreement-document .signature-wrapper .signature { font-family: 'whisper'; font-size: 1.8em; border-bottom: 1px solid #37465A; border-top: 1px solid #37465A; border-left: 1px solid #37465A; padding: 15px; border-radius: 13px 0 0 13px; width: 60px; white-space: nowrap; margin: 20px 0; } 
.agreement-document .date { font-size: 1em; padding-bottom: 20px; } 

/* Dashboard */
.dashboard { height:100%; width: 100%; background: #F9F9F9; overflow: auto; } 
.dashboard .container-information { background: #2E405C; color: #fff; border-radius: 0 0 8px 8px; } 
.dashboard .container-information .address { font-size: 1em; text-align: center; width: 100%; display: block; padding: 25px 0 30px 0; } 
.dashboard .container-information .stat-title { display: flex; justify-content: center; } 
.dashboard .container-information .stat-title p { font-size: 1em; text-align: center; align-items: flex-end; width: 100%; display: contents; } 
.dashboard .container-information .stat-title .icon-info { color: #FFF; display: inline-flex; margin: 1px 0 0 7px; } 
.dashboard .container-information .stat-table-price { display: flex; justify-content: center; align-items: baseline; width: 100%; padding: 15px 0 30px 0; } 
.dashboard .container-information .stat-table-price .price { font-size: 2.28em; } 
.dashboard .container-information .stat-table-price .percentage { display: block; position: relative; padding-left: 20px; margin-left: 15px; } 
.dashboard .container-information .stat-table-price .percentage::after { content: '\e800'; color: #77EA5B; font-family: "cobuy"; display: block; position: absolute; left: 0; bottom: 0; transform: rotate(90deg); } 
.dashboard .container-information .stat-table-price .percentage.negative::after { content: '\e800'; color: #ff6060; font-family: "cobuy"; display: block; position: absolute; left: 0; bottom: 0; transform: rotate(-90deg); } 
.information-table { border-top: 1px solid #FFFFFF33; margin: 0 20px; padding: 15px; position: relative; } 
.information-table span { font-size: 0.85em; font-weight: 200; } 
.information-table > span { font-weight: 400; color: #FFFFFF; opacity: 0.7 } 
.information-table .information-table-title { font-size: 1em; margin: 5px 0 15px 0; position: relative; font-weight: 600; color: #2E405C; } 
.information-table .description { position: absolute; right: 10px; top: 20px; font-weight: 400; color: #333333; opacity: 0.7 } 
.information-table .icon-arrow-down { display: block; position: absolute; right: 10px; top: 0; font-size: 12px; transition: all .4s; } 
.information-table.dropdown-table .table { overflow: hidden; opacity: 0; max-height: 0; transition: all .4s; } 
.information-table.dropdown-table.open .table { opacity: 1; max-height: 500px; } 
.information-table.dropdown-table.open .icon-arrow-down { transform: rotate(-180deg); } 
.information-table.dropdown-table .information-table-title { cursor: pointer; color: #FFF; padding-right: 30px; } 
.information-table .table .row { display: flex; justify-content: space-between; border-bottom: 1px solid #97979733; padding: 10px 0; } 
.dashboard .container-information .information-table .table .row:last-child { border: none; } 
.information-table .table .row span { font-size: 1em; font-weight: 400; } 
.information-table .table .row span b { color: #7E7E7E; font-weight: 500; } 
.dashboard .container-information .information-table .table .row span b { color: #FFF; } 
.dashboard .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 

/* My profile */
.profile-wrapper { width: 100%; } 
.profile-wrapper .profile { width: 100%; padding: 20px; max-width: 410px; margin: auto } 
.profile .desktop-header { display: flex; padding-bottom: 20px; } 
.profile .desktop-header button { padding: 0; margin: 0 0 0 auto; } 
.profile .desktop-header button.icon-text-button { font-size: 1.15em; line-height: 24px; } 
.profile .desktop-header button.icon-text-button i { font-size: 18px; } 
.profile .banners { margin-bottom: 20px; max-width: 500px } 
.profile h2 { font-size: 1.5em; font-weight: 600; letter-spacing: 0.1px; } 
.profile form .column .text-input-container label,
.profile form .column .select-input label,
.profile form .column .date-input-container label { color: #333333; opacity: 1; } 

/* Document Vault */
.document-vault { height:100%; width: 100%; background: #F9F9F9; overflow: auto; padding: 30px 0 70px } 
.document-vault .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 
.document-vault-bottom h2 { width: 100%; padding: 20px; color: #646464; font-size: 1em; font-weight: 600; letter-spacing: 0.01em; } 
.header-document-vault { width: calc(100% - 20px); display: flex; justify-content: flex-end; } 
.container-cards-tables { padding-bottom: 30px; } 
.document-cards-container { padding: 20px 0 0 0; } 
.document-card { border-radius: 8px; box-shadow: 0px 0px 17px -3px #0000002e; margin: 0 20px 15px 20px; background: #FFF; padding: 20px; cursor: pointer; position: relative; } 
.document-card .document-type { margin: 0 10px 0 0; width: 100%; position: relative; top: -5px; } 
.document-card .header-container { position: relative; margin-right: 25px; } 
.document-card .header-container span.document-title { font-size: 1.15em; font-weight: 600; margin: 5px 10px 10px 0; padding-bottom: 10px; word-break: break-word } 
.document-card .header-container .title-badge-container { display: flex; width: 100%; justify-content: space-between; align-items: baseline; grid-gap: 5px; } 
.document-card .header-container .badge { position: static; white-space: nowrap; margin: 0; } 
.document-card .icon-dots { position: absolute; cursor: pointer; margin-right: -2px; top: 20px; right: 13px; } 
.document-card .icon-dots::before { font-size: 18px; } 
.document-card p { font-size: 0.85em; margin-top: 10px; } 
.document-card p.description { width: 100%; line-height: 16px; } 

.popover-menu { position: absolute; border-radius: 4px; box-shadow: 0px 0px 17px -3px #0000002e; background: #fff; right: 20px; width: max-content; max-width: 200px; z-index: 999; top: 0; } 
.popover-menu i { position: relative; z-index: 99; cursor: pointer; display: block; font-size: 1.03em; border-bottom: #d6d6d6 solid 1px; padding: 10px 20px 10px 15px; } 
.popover-menu i::before { margin-right: 10px; } 
.popover-menu i:hover, .popover-menu i:focus { background-color: #fafafa; } 
.popover-menu i:last-child { border: none; } 
.popover-menu .overlay { position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; cursor:auto } 

/* Chat Drawer */
.chat-drawer { height: calc(100vh - 60px); width: 100%; overflow-y: auto; } 
.chat-drawer .chat-group { border-bottom: 1px solid #e5e5e5; position: relative; padding: 0 20px; height: 75px; display: flex; align-items: center; transform: all .2s; cursor: pointer; background: #fff; } 
.chat-drawer .chat-group:hover { background: #3665AB2E; } 
.chat-drawer .chat-group.active { background: #3665AB2E; } 
.chat-drawer .chat-group .icon-cobuy,
.chat-drawer .chat-group .icon-users { width: 40px; height: 40px; border-radius: 50%; display: flex; align-items: center; justify-content: center; } 
.chat-drawer .chat-group .icon-cobuy { background: #EAECEF; border: #37465A 1px solid; color: #37465A; font-size: 23px; } 
.chat-drawer .chat-group .icon-users { background: #F0F3ED; border: #688949 1px solid; color: #688949; font-size: 18px; } 
.chat-drawer .chat-group .name { font-size: 1.12em; font-weight: 500; margin-left: 15px; } 
.chat-drawer .chat-group .badge-number { padding-top: 1px; width: 24px; height: 24px; background: #688949; border-radius: 50%; display: flex; justify-content: center; align-items: center; color: #fff; font-size: 10px; margin: 0 30px 0 auto; } 
.chat-drawer .chat-group .icon-arrow-right { position: absolute; right: 20px; } 
.chat-drawer .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 

/* Chat */
.chat { position: absolute; left: 100%; width: 100vw; background: #FFF; transition: all .5s; height: 100%; border-left: 1px solid #e5e5e5; } 
.chat.open { left: 0; } 
.chat .empty-state { padding-bottom: 80px } 
.chat .chat-messages { padding-bottom: 80px; height: 100%; overflow: auto; display: flex; flex-direction: column-reverse; } 
.chat .chat-messages .date { font-size: 0.85em; text-align: center; width: 100%; display: block; margin: 20px auto 16px; } 
.chat .chat-messages .chat-message { padding: 0 20px; margin: 8px 0; } 
.chat .chat-messages .chat-message.first { margin: 16px 0 8px 0; } 
.chat .chat-messages .chat-message.first-of-all { margin: 8px 0; } 
.chat .chat-messages .chat-message .avatar .circle { background: #688949; font-size: 12px; border: none; margin-bottom: 10px; } 
 .chat .chat-messages .chat-message.own-message .avatar .circle { background: #3665AB; } 
.chat .chat-messages .chat-message .message-box { word-break: break-word; background: #EFF5E9; border-radius: 0px 8px 8px 8px; padding: 20px 20px 30px 20px; color: #333333; width: calc(100% - 40px); position: relative; } 
 .chat .chat-messages .chat-message.own-message .message-box { background: #E7F0FC; border-radius: 8px 0 8px 8px; margin: 0 0 0 auto; } 
.chat .chat-messages .chat-message .message-box .time { color: #333333; opacity: 0.7; font-size: 12px; position: absolute; bottom: 8px; right: 10px; } 
.chat .chat-messages .chat-message .message-box .time span { font-size: 15px; } 
.chat .input-text { position: absolute; width: calc(100% - 40px); bottom: 0; left: 15px; padding: 5px 0 20px; background: #FFF; } 
.chat .chat-messages .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.chat > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -60px; } 

/* Notification */
.notifications-scroll-content { overflow-y: auto; height: 100%; background: #F9F9F9; transition: all .2s; } 
.notifications-scroll-content .empty-state { padding-bottom: 60px } 
.notifications-scroll-content > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.notifications-scroll-content .notifications-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.notification { cursor: pointer; transition: all .2s; border-bottom: 1px #DEE1E6 solid; padding: 20px; display: flex; background: #FFF; } 
.notification:hover { background: #3665AB2E!important; } 
 .notification.unread { background: #3888ff17; } 
.notification i { width: 34px; height: 34px; font-size: 22px; background: #E1E7DB; border-radius: 50%; margin-right: 10px; border: 1px solid #688949; color: #688949; display: flex; justify-content: center; align-items: center; } 
.notification i.icon-cobuy{ font-size: 28px; border: 1px solid #37465A; color: #37465A; background: #e3e8ee; } 
.notification .avatar { margin: 0 10px 0 0; } 
.notification .avatar .circle { font-size: 12px; } 
.notification .description { width: calc(100% - 100px); } 
.notification .description p { font-size: 0.9em; color: #7E7E7E; } 
.notification .description p b { color: #333333; font-weight: 600; } 
.notification .time-sent { margin-left: 10px; } 
.notification .time-sent p { font-size: 0.85em; color: #7E7E7E; text-align: right; white-space: nowrap; } 

/* Document History */
.document-history { width: 100%; height: 100%; padding: 0; background: #f9f9f9; overflow-y: auto; transition: all .2s; } 
.document-history .document-preview { margin: 20px; border: solid 1px #00000029; background: #fff; position: relative; padding: 18px 16px; cursor: pointer; } 
.document-history .document-preview .document-preview-header { display: flex; width: 100%; grid-gap: 5px; justify-content: space-between; align-items: baseline; padding-bottom: 6px; } 
.document-history .document-preview .document-preview-header .badge { position: static; white-space: nowrap; } 
.document-history .document-preview .document-preview-header h3 { font-size: 1em; font-weight: 500; word-break: break-word; } 
.document-history .document-preview .document-preview-body { display: flex; width: 100%; justify-content: space-between; align-items: baseline; flex-wrap: wrap; grid-gap: 5px; } 
.document-history .document-preview .document-preview-body p { font-size: 0.86em; } 
.document-history .document-preview .document-preview-body span { color: #7E7E7E; font-size: 0.86em; } 
.document-history .document-history-infinite-scroll .spinner { margin: 20px auto 20px; height: 35px; width: 35px; border: 3px solid transparent; border-top: 3px solid #37465A; } 
.document-history > .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.document-history .empty-state { background: inherit; padding-bottom: 110px } 

/* Vault Document */
.vault-document { height: 600px; width: 100%; background: #f9f9f9; padding: 0 20px; overflow: auto; } 
.vault-document .avatars-container { display: flex; justify-content: center; margin: 15px 0; } 
.vault-document .no-viewer { height: 70%; text-align: center; display: flex; justify-content: center; flex-direction: column; } 
.vault-document .no-viewer a { font-weight: 600; } 
.vault-document .doc-viewer { position: relative; margin-top: 20px; width: 100%; height: calc(100% - 40px); } 
.vault-document .doc-viewer.small-height { height: calc(100% - 120px); } 
.vault-document .doc-viewer .spinner { position: absolute; top: 50%; left: 50%; margin-left: -30px; margin-top: -30px; } 
.vault-document .img-container { display: flex; justify-content: center; padding-bottom: 20px; margin-top: 20px; } 
.vault-document .img-container.extra-padding { padding-bottom: 116px } 
.vault-document .img-container img { width: 100%; } 
.vault-document .bottom-fixed { flex-direction: row; box-shadow: 0px -4px 20px rgba(255, 255, 255, 0.7); } 
.vault-document .bottom-fixed .button-center { width: 48%; } 
.vault-document .bottom-fixed .button-center button { margin: 0; } 
#proxy-renderer { overflow: hidden; } 


















